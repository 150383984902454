<template>
  <div>
    <v-dialog v-model="dialogOpen" persistent max-width="500">
      <v-card tile :loading="loading">
        <v-card-title>{{ $t("plan.close_title") }}</v-card-title>
        <v-card-text>{{ $t("plan.close_confirmation") }} </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="darken-1" text @click="cancelClose">{{
            $t("global.no")
          }}</v-btn>
          <v-btn depressed color="red darken-1" text @click="confirmClose">{{
            $t("global.yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["open", "simulatorOpen"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    dialogOpen: {
      get() {
        return this.open;
      },
      set(newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  methods: {
    cancelClose() {
      this.dialogOpen = false;
    },
    confirmClose() {
      this.cancelClose();
      this.$emit("update:simulatorOpen", false);
    },
  },
};
</script>
