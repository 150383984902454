<template>
  <v-dialog v-if="dialogOpen" v-model="dialogOpen" persistent max-width="500">
    <v-card tile :loading="loading">
      <v-card-title
        ><b>{{ item.name }}</b
        >&nbsp;({{ $t("version.number") }} {{ version.number }})</v-card-title
      >
      <v-card-text>{{ $t("plan.exporting_plan") }}</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/
export default {
  props: ["item", "open", "version"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  methods: {
    exportPlan() {
      this.loading = true;
      this.$store
        .dispatch("plans/EXPORT_PLAN", { plan: this.item, version: this.version })
        .then(() => {
          this.loading = false;
          this.dialogOpen = false;
        })
        .catch(() => {
          this.loading = false;
          this.dialogOpen = false;
        });
    },
  },
  watch: {
    dialogOpen() {
      if (this.dialogOpen && this.item && this.version) this.exportPlan();
    },
  },
};
</script>
