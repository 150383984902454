<template>
  <div class="map-container">
    <v-progress-linear
      height="10"
      striped
      color="lime"
      indeterminate
      v-show="loading"
    ></v-progress-linear>

    <div ref="map" class="map" />

    <VesselCallShips
      :refMap.sync="map"
      :mooringBittsManager="mooringBittsManager"
      :zonesManager="zonesManager"
      v-if="canLoadShips"
      :records.sync="records"
      :showBuffer="false"
    />

    <MooringBitts
      :refMap="map"
      :mooringBittsManager="mooringBittsManager"
      @loaded="hasLoadedMooringBitts = true"
    />

    <Zones
      :refMap.sync="map"
      :zonesManager.sync="zonesManager"
      @loaded="hasLoadedZones = true"
      :records.sync="records"
    />
  </div>
</template>

<script>
import configs from "@/helpers/configs";

//Access vue instance to reach global constants
import * as maptalks from "maptalks";

import VesselCallShips from "@/components/Map/VesselCallShips";
import MooringBitts from "@/components/Map/MooringBitts";
import Zones from "@/components/Map/Zones";
import MooringBittsManager from "@/helpers/map/mooringbittsmanager";
import ZonesManager from "@/helpers/map/zonesmanager";

const VCSHIPS_LAYERNAME = "vc_ships";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";
const ZONES_LAYERNAME = "zones";

export default {
  props: ["refMap", "records"],
  components: {
    MooringBitts,
    Zones,
    VesselCallShips,
  },
  data() {
    return {
      map: null,
      hasLoadedZones: false,
      hasLoadedMooringBitts: false,
      mooringBittsManager: new MooringBittsManager(),
      zonesManager: new ZonesManager(),
      colors: configs.getColors(),
      loading: false,
    };
  },
  computed: {
    canLoadShips() {
      return this.map && this.hasLoadedMooringBitts && this.hasLoadedZones;
    },
    locode() {
      return configs.getLocode();
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      let center = this.locode.coordinates;
      let bearing = this.locode.bearing;
      let zoom = this.locode.zoom;
      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        seamlessZoom: true,
        maxPitch: 0,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        //fpsOnInteracting: 0,
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        seamlessZoom: true,
        attribution: false,
        baseLayer: new maptalks.GroupTileLayer("Base TileLayer", [
          new maptalks.TileLayer("baselayer_nv", {
            visible: true,
            urlTemplate: configs.getUrlRaster(),
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO",
          }),
        ]),
        layers: [
          new maptalks.VectorLayer(ZONES_LAYERNAME),
          new maptalks.VectorLayer(MOORING_ROPES_LAYERNAME),
          new maptalks.VectorLayer(MOORINGBITTS_LAYERNAME),
          new maptalks.VectorLayer(VCSHIPS_LAYERNAME),
        ],
      });
    },
  },
};
</script>

<style>
.map,
.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.map-container {
  position: relative;
}
</style>
