<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="appear"
          @click="open"
          small
          class="py-5"
          :color="show || isActive ? 'primary' : 'white'"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon :color="show || isActive ? 'white' : 'primary'"
            >mdi-layers</v-icon
          >
        </v-btn>
      </template>
      <span> {{ $t("global.layersDrawer") }} </span>
    </v-tooltip>

    <v-dialog
      v-model="show"
      width="350"
      style="height: 100%"
      transition="dialog-bottom-transition"
    >
      <v-toolbar dense flat>
        <v-toolbar-title>
          <span> <v-icon left>mdi-layers</v-icon>Camadas</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list flat dense>
        <v-list-item v-for="layer in selectedLayers" v-bind:key="layer.id">
          <template v-slot:default="{ active }">
            <v-list-item-action @click="handleLayerToggled(layer)">
              <v-checkbox
                :input-value="active"
                color="primary"
                @change="handleLayerToggled(layer)"
                v-model="layer.active"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title v-bind="attrs" v-on="on">{{
                    layer.name
                  }}</v-list-item-title>
                </template>
                <span>{{ layer.name }}</span>
              </v-tooltip>
              <v-list-item-subtitle>{{ layer.code }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small>WMS</v-chip>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>
    </v-dialog>
  </fragment>
</template>

<script>
//Access vue instance to reach global constants
import * as maptalks from "maptalks";

//LAYER_NAMES to be accessed anywhere on this component
const AISSHIPS_LAYERNAME = "ais_ships";
const VCSHIPS_LAYERNAME = "vc_ships";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";
const ZONES_LAYERNAME = "zones";
const CELLS_LAYERNAME = "cells";
const ZHS_LAYERNAME = "zhs";

export default {
  props: ["map", "appear"],
  data() {
    return {
      show: false,
      selectedLayers: [],
    };
  },
  mounted() {
    this.reloadLayers();
  },
  watch: {
    show() {
      this.selectedLayers.forEach((layer) => {
        this.handleLayerToggled(layer);
      });
    },
    isActive() {
      this.$store.dispatch(
        "screenshots/SET_LAYERS",
        this.selectedLayers.filter((layer) => layer.active)
      );
    },
  },
  computed: {
    isActive() {
      return this.selectedLayers.filter((layer) => layer.active).length > 0;
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    reloadLayers() {
      this.$store.dispatch("layers/GET_LAYERS").then((selectedLayers) => {
        this.selectedLayers = selectedLayers;
        this.selectedLayers.forEach((layer) => {
          this.handleLayerToggled(layer);
        });
      });
    },
    getLayerObject(layer) {
      if (layer) {
        let layerName = "bg_" + layer.id;
        let backgroundMapLayer = this.map.getLayer(layerName);
        let layerDefinition = JSON.parse(layer.content);
        if (!backgroundMapLayer) {
          if (layer.type == "WMS") {
            return new maptalks.WMSTileLayer(layerName, {
              urlTemplate: layer.source_url,
              crs: layerDefinition.crs,
              layers: layerDefinition.layers,
              format: layerDefinition.format,
              transparent: true,
              uppercase: false,
              forceRenderOnMoving: true,
              forceRenderOnZooming: true,
              forceRenderOnRotating: true,
            }).addTo(this.map);
          }
        } else {
          return backgroundMapLayer;
        }
      }
    },
    showLayer(layer) {
      if (!layer) return;
      let bgLayer = this.getLayerObject(layer);
      bgLayer.show();
      this.rearrangeLayers();
    },
    hideLayer(layer) {
      if (!layer) return;
      let bgLayer = this.getLayerObject(layer);
      bgLayer.hide();
      this.rearrangeLayers();
    },
    handleLayerToggled(layer) {
      if (layer.active) {
        this.showLayer(layer);
      } else {
        this.hideLayer(layer);
      }
    },
    handleLayerSelected(layer) {
      this.showLayer(layer);
    },
    handleLayerDeselected(layer) {
      this.hideLayer(layer);
    },
    rearrangeLayers() {
      var currentLayers = this.map.getLayers();
      /**
       * Ordering of layers follows this visualization rule, from top to bottom:
       *
       * SHIPS
       * MOORINGBITTS
       * MOORING_ROPES,
       * LAYERS
       * BASELAYERS
       */
      if (currentLayers) {
        let sortedList = [];
        let orderTiers = {
          MEASURES: [],
          MOORING_ROPES: [],
          MOORING_BITTS: [],
          CELLS: [],
          ZHS: [],
          ZONES: [],
          SHIPS: [],
          LAYERS: [],
        };
        currentLayers.forEach((l) => {
          switch (l._id) {
            case (l._id.match(/^distancetool_/) || {}).input:
              orderTiers.MEASURES.push(l);
              break;
            case ZONES_LAYERNAME:
              orderTiers.ZONES.push(ZONES_LAYERNAME);
              break;
            case MOORING_ROPES_LAYERNAME:
              orderTiers.MOORING_ROPES.push(MOORING_ROPES_LAYERNAME);
              break;
            case MOORINGBITTS_LAYERNAME:
              orderTiers.MOORING_BITTS.push(MOORINGBITTS_LAYERNAME);
              break;
            case CELLS_LAYERNAME:
              orderTiers.CELLS.push(l._id);
              break;
            case ZHS_LAYERNAME:
              orderTiers.ZHS.push(l._id);
              break;
            case AISSHIPS_LAYERNAME:
            case VCSHIPS_LAYERNAME:
              orderTiers.SHIPS.push(l._id);
              break;
            default:
              if (l && l.options && l.options._originalLayer) {
                orderTiers.LAYERS.push({
                  _id: l._id,
                  order_num: l.options._originalLayer.order_num,
                });
              } else {
                orderTiers.LAYERS.push({
                  _id: l._id,
                  order_num: 9,
                });
              }
              break;
          }
        });
        let orderedLayers = orderTiers.LAYERS.sort((a, b) => {
          return b.order_num - a.order_num; //Ascending
        }).map((l) => {
          return l._id;
        });
        sortedList.push(...orderedLayers);
        sortedList.push(...orderTiers.CELLS);
        sortedList.push(...orderTiers.ZONES);
        sortedList.push(...orderTiers.MOORING_ROPES);
        sortedList.push(...orderTiers.MOORING_BITTS);
        sortedList.push(...orderTiers.ZHS);
        sortedList.push(...orderTiers.SHIPS);
        sortedList.push(...orderTiers.MEASURES);
        this.map.sortLayers(sortedList);
      }
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
