<template>
  <v-data-table
    :footer-props="{
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      'items-per-page-options': [10],
      disableItemsPerPage: true,
    }"
    :loading.sync="loading"
    :page.sync="currentPage"
    :headers="headers"
    :items="items"
    :server-items-length="totalNumberOfPlans"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    @update:options="handleUpdate('options', $event)"
    item-key="id"
    dense
    fixed-header
    must-sort
    :item-class="rowBackground"
    class="plan_list"
    :disable-sort="loading"
  >
    <template v-slot:top>
      <v-text-field
        v-model="searchedTerm"
        v-on:keyup.enter="filterPlans"
        single-line
        :label="$t('global.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        :disabled="loading"
      />
    </template>
    <template v-slot:item.name="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <span
            class="ma-0 pa-0 font-weight-black blue--text text--darken-4 text-uppercase d-inline-block text-truncate"
            style="cursor: pointer; width: 200px"
            @click.stop="edit(item)"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name.trim() }}
          </span>
        </template>
        <span>Abrir plano</span>
      </v-tooltip>
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | formatDatetime }}
    </template>
    <template v-slot:item.number_of_versions="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            outlined
            small
            class="ma-1"
            color="primary"
            :disabled="!hasUserPermissionToViewOrManage('PLAN_VERSIONS')"
            @click.stop="versions(item)"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.number_of_versions }}
          </v-chip>
        </template>
        <span>{{ $t("plan.view_versions") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            dark
            color="green darken-4"
            @click.stop="xlsx(item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-microsoft-excel
          </v-icon>
        </template>
        <span>{{ $t("plan.export_to_excel") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.actions2="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="hasUserPermissionToManage('PLANS')"
            dark
            color="red"
            @click.stop="remove(item)"
            v-bind="attrs"
            v-on="on"
            >mdi-delete-outline</v-icon
          >
        </template>
        <span>{{ $t("plan.delete_plan") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.actions3="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="hasUserPermissionToManage('PLANS')"
            dark
            color="black"
            @click.stop="archive(item)"
            v-bind="attrs"
            v-on="on"
            >{{
              item.archived ? "mdi-archive-off-outline" : "mdi-archive-outline"
            }}</v-icon
          >
        </template>
        <span>{{
          item.archived ? $t("plan.unarchive_plan") : $t("plan.archive_plan")
        }}</span>
      </v-tooltip>
    </template>
    <template #footer.prepend>
      <v-spacer />
    </template>
  </v-data-table>
</template>

<script>
import { Settings, DateTime } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

export default {
  props: ["reloadPlans"],
  data() {
    return {
      activeRequests: 0, // Inicializa a contagem de chamadas ativas
      loading: false,
      headers: [
        {
          text: this.$t("plan.code"),
          value: "code",
          class: "grey--text",
        },
        {
          text: this.$t("plan.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("plan.number_of_versions"),
          value: "number_of_versions",
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t("plan.start_date"),
          value: "start_date",
        },
        {
          text: this.$t("plan.end_date"),
          value: "end_date",
        },
        {
          text: this.$t("plan.updated_at"),
          value: "updated_at",
        },
        {
          text: this.$t("plan.created_by"),
          value: "creator_name",
        },
        {
          text: this.$t("plan.updated_by"),
          value: "updater_name",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "24px",
        },
        {
          text: "",
          value: "actions2",
          align: "center",
          sortable: false,
          width: "24px",
        },
        {
          text: "",
          value: "actions3",
          align: "center",
          sortable: false,
          width: "24px",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.plans.plansList.map((plan) => {
        return {
          ...plan,
          scheduler: plan.data,
          start_date: this.formatDate(plan.start_date),
          end_date: this.formatDate(plan.end_date),
        };
      });
    },
    totalNumberOfPlans() {
      return this.$store.state.plans.totalNumberOfPlans;
    },
    itemsPerPage: {
      get() {
        return this.$store.state.plans.itemsPerPage;
      },
      set(val) {
        this.$store.dispatch("plans/SET_ITEMS_PER_PAGE", val);
      },
    },
    currentPage: {
      get() {
        return this.$store.state.plans.currentPage;
      },
      set(val) {
        this.$store.dispatch("plans/SET_CURRENT_PAGE", val);
      },
    },
    searchedTerm: {
      get() {
        return this.$store.state.plans.searchedTerm;
      },
      set(val) {
        this.$store.dispatch("plans/SET_SEARCHED_TERM", val);
      },
    },
    sortBy: {
      get() {
        return this.$store.state.plans.sortBy;
      },
      set(val) {
        this.$store.dispatch("plans/SET_SORT_BY", val);
      },
    },
    sortDesc: {
      get() {
        return this.$store.state.plans.sortDesc;
      },
      set(val) {
        this.$store.dispatch("plans/SET_SORT_DESC", val);
      },
    },
    payload: {
      get() {
        return this.$store.state.plans.payload;
      },
      set(val) {
        this.$store.dispatch("plans/SET_PAYLOAD", val);
      },
    },
    reload: {
      get() {
        return this.reloadPlans;
      },
      set(val) {
        this.$emit("update:reloadPlans", val);
      },
    },
  },
  watch: {
    reload(val) {
      if (val) this.getPlans();
    },
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    archive(item) {
      item.archived = !item.archived;
      this.loading = true;
      this.$store.dispatch("plans/ARCHIVE_PLAN", item).then(() => {
        this.loading = false;
        this.reload = false;
      });
    },
    xlsx(item) {
      this.$emit("xlsx", item);
    },
    versions(item) {
      this.$emit("versions", item);
    },
    formatDate(date) {
      return DateTime.fromISO(date).toFormat("dd/LL/yyyy", {
        locale: configs.getLocale(),
      });
    },
    getPlans() {
      // Incrementa a contagem de chamadas ativas
      this.activeRequests++;
      this.loading = true;
      this.$store.dispatch("plans/GET_PLANS").then(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.loading = false;
        }
        this.reload = false;
      });
    },
    rowBackground(item) {
      return item.archived ? "table-row-archived-background" : "";
    },

    filterPlans() {
      this.currentPage = 1;
      this.searchPlans();
    },

    searchPlans() {
      this.$store.dispatch("plans/SET_SEARCHED_TERM", this.searchedTerm);

      const newPayload = {
        search: this.searchedTerm,
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      };

      if (JSON.stringify(newPayload) !== JSON.stringify(this.payload)) {
        this.payload = newPayload;
        this.getPlans();
      }
    },

    handleUpdate(_, options) {
      let newPayload = {
        search: this.searchedTerm,
        currentPage: options.page,
        itemsPerPage: options.itemsPerPage,
        sortBy:
          options.sortBy[0] === "creator_name"
            ? "created_by"
            : options.sortBy[0] === "updater_name"
            ? "updated_by"
            : options.sortBy[0],
        sortDesc: options.sortDesc[0],
      };

      if (JSON.stringify(newPayload) === JSON.stringify(this.payload)) return;

      this.payload = newPayload;
      this.getPlans();
    },
  },
};
</script>
<style>
.table-row-archived-background {
  background-color: #fff0f0;
}
</style>
