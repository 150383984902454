<template>
  <v-row>
    <v-col cols="12" sm="12" class="pa-1 pt-3">
      <div class="blue-grey lighten-5 text-no-wrap rounded">
        <v-slider :value="sliderVal" track-color="grey" always-dirty :max="max" :min="min" hide-details
          @end="setSliderValue">
          <template v-slot:prepend>
            <v-btn depressed @click.stop="togglePlayPause" :color="isPlaying ? 'warning' : 'primary'" class="mx-2" fab dark small
              elevation="0" :title="isPlaying ? 'Parar' : 'Começar'">
              <v-icon> {{ isPlaying ? "mdi-pause" : "mdi-play" }}</v-icon>
            </v-btn>

            <v-btn depressed @click="decrease(1440)" class="mx-2" elevation="0" icon title="Recuar 24 horas">
              <v-icon> mdi-rewind </v-icon>
            </v-btn>

            <v-btn depressed @click="decrease(30)" class="mx-2" elevation="0" icon title="Recuar 30 minutos">
              <v-icon> mdi-rewind-30 </v-icon>
            </v-btn>

            <v-btn depressed @click="decrease(15)" class="mx-2" elevation="0" icon title="Recuar 15 minutos">
              <v-icon> mdi-rewind-15 </v-icon>
            </v-btn>

            <v-btn depressed @click="decrease(5)" class="mx-2" elevation="0" icon title="Recuar 5 minutos">
              <v-icon> mdi-rewind-5 </v-icon>
            </v-btn>

          </template>

          <template v-slot:append>

            <v-btn depressed @click="increase(5)" class="mx-2" elevation="0" icon title="Avançar 5 minutos">
              <v-icon> mdi-fast-forward-5 </v-icon>
            </v-btn>

            <v-btn depressed @click="increase(15)" class="mx-2" elevation="0" icon title="Avançar 15 minutos">
              <v-icon> mdi-fast-forward-15 </v-icon>
            </v-btn>

            <v-btn depressed @click="increase(30)" class="mx-2" elevation="0" icon title="Avançar 30 minutos">
              <v-icon> mdi-fast-forward-30 </v-icon>
            </v-btn>

            <v-btn depressed @click="increase(1440)" class="mx-2" elevation="0" icon title="Avançar 24 horas">
              <v-icon> mdi-fast-forward </v-icon>
            </v-btn>

          </template>
        </v-slider>
      </div>
    </v-col>
  </v-row>
</template>
<script>
const DEFAULT_INCREMENT = "minutes";
import debounce from "lodash/debounce";
import { DateTime, Interval, Settings } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();
export default {
  props: [
    "startDate",
    "endDate",
    "items",
    "duration",
    "increment",
    "value",
    "dateProperty",
    "eventIcon",
  ],
  data() {
    return {
      isPlaying: false,
      val: 0,
      min: 0,
      max: 0,
      playTimeout: null,
      events: null,
    };
  },
  computed: {
    sliderIncrement() {
      return this.increment || DEFAULT_INCREMENT;
    },
    sliderVal: {
      get() {
        return this.val;
      },
      set(val) {
        let initialDate = DateTime.fromJSDate(this.startDate);
        let instantBefore = initialDate.plus({
          [this.sliderIncrement]: val - 1,
        });
        this.val = val;
        this.selectedDate = initialDate.plus({
          [this.sliderIncrement]: val,
        });
        //Passed items between the current time and
        let passedItems = this.items.filter((item) => {
          let dt = DateTime.fromISO(item[this.dateProperty]);
          return dt >= instantBefore && dt <= this.selectedDate;
        });
        this.events = passedItems;
        this.$emit("input", this.selectedDate);
      },
    },
  },
  watch: {
    startDate() {
      this.reboot();
    },
    endDate() {
      this.reboot();
    },
  },
  mounted() {
    this.reboot();
  },
  beforeDestroy() {
    this.reboot();
  },
  methods: {
    setSliderValue(value) {
      this.sliderVal = value;
    },
    getThumbLabel(value) {
      let sd = DateTime.fromJSDate(this.startDate);
      sd = sd.plus({
        [this.sliderIncrement]: value,
      });
      return sd.toFormat("dd/LL/yyyy HH:mm");
    },
    togglePlayPause() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.play();
      } else {
        this.stop();
      }
    },
    decrease: debounce(function (val) {
      this.sliderVal = (this.sliderVal - val > this.min) ? this.sliderVal - val : this.min;
    }, 250),

    increase: debounce(function (val) {
      this.sliderVal = (this.sliderVal + val < this.max) ? this.sliderVal + val : this.max;
    }, 250),

    play() {
      clearTimeout(this.playTimeout);
      this.sliderVal++;
      this.playTimeout = setTimeout(() => {
        if (this.sliderVal >= this.max) {
          this.stop();
        } else {
          this.play();
        }
      }, this.duration || 1500);
    },
    stop() {
      clearTimeout(this.playTimeout);
      this.isPlaying = false;
    },
    getRangeMax() {
      let sd = DateTime.fromJSDate(this.startDate);
      let ed = DateTime.fromJSDate(this.endDate);
      let interval = Interval.fromDateTimes(sd, ed);
      return interval.length(this.sliderIncrement);
    },
    reboot() {
      this.val = 0;
      this.min = 0;
      this.max = this.getRangeMax();
      this.sliderVal = this.max;
      this.isPlaying = false;
      clearTimeout(this.playTimeout);
    },
  },
};
</script>
<style >
.v-slider--horizontal {
  margin-top: 10px;
}
</style>