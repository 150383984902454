<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="toggleMeasurement"
          small
          class="py-5"
          :color="isEnabled ? 'primary' : 'white'"
          :disabled="isDisabled"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon :color="isEnabled ? 'white' : 'primary'">
            mdi-ruler-square
          </v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.areas") }} </span>
    </v-tooltip>

    <v-snackbar top v-model="showAreaMessage" width="60%" :timeout="-1">
      <v-icon left> mdi-ruler-square </v-icon>{{ $t("areas.explanation") }}
      <template v-slot:action="{ attrs }">
        <v-btn small color="primary" v-bind="attrs" @click="clearMeasures">
          <v-icon small left>mdi-eraser</v-icon>{{ $t("areas.clear") }}
        </v-btn>
      </template>
    </v-snackbar>
  </fragment>
</template>

<script>
import { AreaTool, VectorLayer, Marker, Label, Geometry, Size } from "maptalks";
import { v4 as uuidv4 } from "uuid";
/**
 * Area Tool extension to support Vue.js translations based on the locale
 *
 */
class TranslatedAreaTool extends AreaTool {
  // Override @fuzhenn msOnDrawEnd method
  _msOnDrawEnd(param) {
    try {
      this._clearTailMarker();

      var prjCoord = this.getMap()._pointToPrj(param["point2d"]);

      var ms = this._measure(param["geometry"]);

      var endLabel = new Label(
        ms,
        param["coordinate"],
        this.options["labelOptions"]
      ).addTo(this._measureMarkerLayer);

      endLabel._setPrjCoordinates(prjCoord);

      var size = endLabel.getSize();

      if (!size) {
        size = new Size(10, 10);
      }

      this._addClearMarker(param["coordinate"], prjCoord, size["width"]);

      var geo = param["geometry"].copy();

      geo._setPrjCoordinates(param["geometry"]._getPrjCoordinates());

      geo.addTo(this._measureLineLayer);
      this._lastMeasure = geo.getArea();
    } catch (ex) {
      //ignore
    }
  }

  // Override @fuzhenn msOnDrawStart method
  _msOnDrawStart(param) {
    const map = this.getMap();
    const prjCoord = map._pointToPrj(param["point2d"]);
    const uid = uuidv4().replaceAll("-", "");
    const layerId = "areatool_" + uid;
    const markerLayerId = "areatool_markers_" + uid;
    if (!map.getLayer(layerId)) {
      this._measureLineLayer = new VectorLayer(layerId).addTo(map);
      this._measureMarkerLayer = new VectorLayer(markerLayerId).addTo(map);
    } else {
      this._measureLineLayer = map.getLayer(layerId);
      this._measureMarkerLayer = map.getLayer(markerLayerId);
    }
    this._measureLayers.push(this._measureLineLayer);
    this._measureLayers.push(this._measureMarkerLayer);
    //start marker
    const marker = new Marker(param["coordinate"], {
      symbol: this.options["vertexSymbol"],
    });
    //调用_setPrjCoordinates主要是为了解决repeatworld下，让它能标注在其他世界的问题
    marker._setPrjCoordinates(prjCoord);
    const content = this.translator.translate("distancetool.start");
    const startLabel = new Label(
      content,
      param["coordinate"],
      this.options["labelOptions"]
    );
    startLabel._setPrjCoordinates(prjCoord);
    this._lastVertex = startLabel;
    this._addVertexMarker(marker, startLabel);
  }

  _measure(toMeasure) {
    const map = this.getMap();
    let area;
    if (toMeasure instanceof Geometry) {
      area = map.computeGeometryArea(toMeasure);
    } else if (Array.isArray(toMeasure)) {
      area = map.getProjection().measureArea(toMeasure);
    }
    this._lastMeasure = area;
    let units;

    switch (this.options["language"]) {
      case "zh-CN":
        units = [" 平方米", " 平方公里"];
        break;
      case "es-MX":
        units = [" m²", " km²"];
        break;
      case "en-US":
      default:
        units = [" sq.m", " sq.km"];
    }

    let content =
      area < 1e6
        ? area.toFixed(0) + units[0]
        : (area / 1e6).toFixed(2) + units[1];

    return content;
  }

  clear() {
    this.endDraw();
    return super.clear();
  }
}

export default {
  props: ["map", "isAreaEnabled", "disabled"],
  data() {
    return {
      showAreaMessage: false,
      areaTool: null,
    };
  },
  watch: {
    map(val) {
      if (val) {
        this.register();
      } else {
        this.unregister();
      }
    },
    isEnabled(val) {
      if (val) {
        this.showAreaMessage = true;
        this.areaTool.enable();
      } else {
        this.showAreaMessage = false;
        this.areaTool.disable();
      }
    },
  },
  computed: {
    isEnabled: {
      get() {
        return this.isAreaEnabled;
      },
      set(val) {
        this.$emit("update:isAreaEnabled", val);
      },
    },
    isDisabled: {
      get() {
        return this.disabled;
      },
      set(val) {
        this.$emit("update:disabled", val);
      },
    },
  },

  mounted() {
    this.register();
  },
  beforeDestroy() {
    this.unregister();
  },
  methods: {
    unregister() {
      if (this.areaTool) {
        this.areaTool.remove();
      }
    },
    register() {
      this.unregister();
      if (this.map) {
        this.areaTool = new TranslatedAreaTool({
          symbol: {
            lineColor: "#34495e",
            lineWidth: 2,
            polygonFill: "#34495e",
            polygonOpacity: 0.3,
          },
          vertexSymbol: {
            markerType: "ellipse",
            markerFill: "#34495e",
            markerLineColor: "#34495e",
            markerLineWidth: 1,
            markerWidth: 5,
            markerHeight: 5,
          },
          labelOptions: {
            textSymbol: {
              textFaceName: "monospace",
              textFill: "#fff",
              textLineSpacing: 1,
              textHorizontalAlignment: "right",
              textDx: 15,
            },
            boxStyle: {
              padding: [6, 2],
              symbol: {
                markerType: "square",
                markerFill: "#34495e",
                markerFillOpacity: 0.9,
                markerLineColor: "#34495e",
              },
            },
          },
          clearButtonSymbol: [
            {
              markerType: "square",
              markerFill: "#34495e",
              markerLineColor: "#34495e",
              markerLineWidth: 2,
              markerWidth: 15,
              markerHeight: 15,
              markerDx: 22,
            },
            {
              markerType: "x",
              markerWidth: 10,
              markerHeight: 10,
              markerLineColor: "#fff",
              markerDx: 22,
            },
          ],
          language: "es-MX",
        }).addTo(this.map);
        this.areaTool.on("drawstart", function (param) {
          setTimeout(() => {
            param.drawTool._drawToolLayer.setZIndex(1000);
            if (param.drawTool._measureMarkerLayer)
              param.drawTool._measureMarkerLayer.setZIndex(1000);
          }, 100);
        });
        var map = this.map;
        this.areaTool.on("drawend", function () {
          map.getLayers().forEach((l) => {
            if (l._id.includes("areatool_")) l.setZIndex(1000);
          });
        });
        this.areaTool.disable();
      }
    },
    toggleMeasurement() {
      this.isEnabled = !this.isEnabled;
    },
    clearMeasures() {
      this.map.getLayers().forEach((l) => {
        if (l._id.includes("areatool_")) l.clear();
      });
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
