<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="toggle"
        small
        class="py-5"
        :title="$t('global.showGrids')"
        :loading="loadingSurvey || loadingMooringZH"
        :color="isEnabled ? 'primary' : 'white'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :color="
            isEnabled || loadingSurvey || loadingMooringZH ? 'white' : 'primary'
          "
          >mdi-waves</v-icon
        >
      </v-btn>
    </template>
    <span> {{ $t("global.showGrids") }} </span>
  </v-tooltip>
</template>

<script>
import configs from "@/helpers/configs";
const CELLS_LAYERNAME = "cells";
const ZHS_LAYERNAME = "zhs";

//Access vue instance to reach global constants
import * as maptalks from "maptalks";

export default {
  props: ["map"],
  data() {
    return {
      isEnabled: null,
      colors: configs.getColors(),
      surveySymbol: configs.getSurveySymbol(),
      loadingSurvey: false,
      loadingMooringZH: false,
    };
  },
  computed: {
    cells() {
      return !this.$store.state.surveys.uploading
        ? this.$store.state.surveys.cells
        : [];
    },
    mooringZhs() {
      return this.$store.state.moorings.zhs;
    },
  },
  watch: {
    cells() {
      if (this.isEnabled) {
        this.processSurvey();
      }
    },
    mooringZhs() {
      if (this.isEnabled) {
        this.processMooringZH();
      }
    },
  },
  methods: {
    toggle() {
      //if first time, get survey and zhs from server
      if (this.isEnabled == null) {
        this.loadSurvey();
        this.loadMooringZH();
        this.isEnabled = true;
      } else {
        this.isEnabled = !this.isEnabled;
      }

      if (this.isEnabled) this.showBathymetries();
      else this.hideBathymetries();
    },

    loadSurvey() {
      this.loadingSurvey = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("surveys/GET_CELLS")
          .then(() => {
            this.loadingSurvey = false;
            resolve();
          })
          .catch((err) => {
            this.loadingSurvey = false;
            reject(err);
          });
      });
    },

    loadMooringZH() {
      this.loadingMooringZH = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("moorings/GET_ALL_MOORING_ZHS")
          .then(() => {
            this.loadingMooringZH = false;
            resolve();
          })
          .catch((err) => {
            this.loadingMooringZH = false;
            reject(err);
          });
      });
    },

    processSurvey() {
      if (this.map) {
        let layer = this.map.getLayer(CELLS_LAYERNAME);
        layer.clear();
        if (this.cells && Object.keys(this.cells).length > 0) {
          this.cells.forEach((cell) => {
            let geometry = maptalks.GeoJSON.toGeometry(
              JSON.parse(cell.geojson)
            );

            geometry.setProperties({
              z: parseFloat(cell.value).toFixed(2),
            });

            geometry.setSymbol(this.surveySymbol);

            geometry.updateSymbol({
              polygonFill: this.getColorByZ(parseFloat(cell.value).toFixed(2)),
            });

            geometry.addTo(layer);
          });
        }
      }
    },

    processMooringZH() {
      if (this.map) {
        let layer = this.map.getLayer(ZHS_LAYERNAME);
        layer.clear();
        if (this.mooringZhs && Object.keys(this.mooringZhs).length > 0) {
          this.mooringZhs.forEach((bitt) => {
            let geometry = maptalks.GeoJSON.toGeometry(
              JSON.parse(bitt.geojson)
            );

            geometry.setProperties({
              zh: parseFloat(bitt.zh).toFixed(1),
            });

            geometry.updateSymbol({
              textFaceName: "Nunito",
              textName: "{zh}",
              textWeight: "bold",
              textStyle: "normal",
              textSize: 10,
              markerType: "ellipse",
              markerLineWidth: 1,
              markerWidth: 32,
              markerHeight: 32,
              markerFill: this.getColorByZ(parseFloat(bitt.zh).toFixed(1)),
              textFill: "#34495e",
              markerLineColor: "#34495e",
            });

            geometry.addTo(layer);
          });
        }
      }
    },

    getColorByZ(z) {
      return this.colors[Math.round(Math.abs(z))];
    },

    showBathymetries() {
      if (this.map) {
        if (this.map.getLayer(CELLS_LAYERNAME))
          this.map.getLayer(CELLS_LAYERNAME).show();
        if (this.map.getLayer(ZHS_LAYERNAME))
          this.map.getLayer(ZHS_LAYERNAME).show();
      }
    },
    hideBathymetries() {
      if (this.map) {
        if (this.map.getLayer(CELLS_LAYERNAME))
          this.map.getLayer(CELLS_LAYERNAME).hide();
        if (this.map.getLayer(ZHS_LAYERNAME))
          this.map.getLayer(ZHS_LAYERNAME).hide();
      }
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
