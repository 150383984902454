<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        class="py-5"
        @click="toggleLock"
        :color="draggableEnabled && !archived ? 'white' : 'primary'"
        :disabled="archived"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :color="draggableEnabled && !archived ? 'primary' : 'white'">{{
          draggableEnabled && !archived
            ? "mdi-lock-open-variant-outline"
            : "mdi-lock-outline"
        }}</v-icon>
      </v-btn>
    </template>
    <span> {{ $t("global.lock_movement") }} </span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["draggable", "archived"],
  data() {
    return {
      showLockMessage: false,
    };
  },
  computed: {
    draggableEnabled: {
      get() {
        return this.draggable;
      },
      set(val) {
        this.$emit("update:draggable", val);
      },
    },
  },
  watch: {
    draggableEnabled() {
      this.showLockMessage = !this.draggable;
    },
  },
  methods: {
    toggleLock() {
      this.draggableEnabled = !this.draggableEnabled;
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
