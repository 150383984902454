<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="snapshotEnabled = !snapshotEnabled"
        small
        class="py-5"
        :color="snapshotEnabled ? 'primary' : 'white'"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon :color="snapshotEnabled ? 'white' : 'primary'">
          mdi-abacus
        </v-icon>
      </v-btn>
    </template>
    <span> Visualizar instantâneo </span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["snapshot"],
  data() {
    return {};
  },
  computed: {
    snapshotEnabled: {
      get() {
        return this.snapshot;
      },
      set(snapshot) {
        this.$emit("update:snapshot", snapshot);
      },
    },
  },
};
</script>
