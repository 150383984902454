<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasUserPermissionToViewOrManage('PLANS')"
          small
          class="py-5"
          @click="isPlansListEnabled = true"
          :color="isPlansListEnabled ? 'primary' : 'white'"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :color="isPlansListEnabled ? 'white' : 'primary'">
            mdi-calendar-month-outline
          </v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.plans") }} </span>
    </v-tooltip>

    <v-dialog
      v-model="isPlansListEnabled"
      transition="dialog-bottom-transition"
      width="80%"
    >
      <v-card tile>
        <v-toolbar dark flat color="blue darken-4">
          <v-toolbar-title><b>Planos</b> :: {{ locode.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed icon dark @click="isPlansListEnabled = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <List
            @remove="remove"
            @edit="edit"
            @xlsx="xlsx"
            @versions="versions"
            :reloadPlans.sync="reloadPlans"
            :key="plansKey"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn
            depressed
            v-if="hasUserPermissionToViewEditOrManage('PLANS')"
            color="blue darken-4"
            text
            @click="add()"
          >
            {{ $t("plan.create_plan") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Simulation
      :open.sync="openSimulationDialog"
      :item.sync="selectedPlan"
      :version.sync="selectedVersion"
      :key="simulationKey"
    />
    <VersionsPlanDialog
      :open.sync="openVersionsPlanDialog"
      :item.sync="selectedPlan"
    />
    <DeletePlanDialog
      :open.sync="openDeletePlanDialog"
      :item.sync="selectedPlan"
    />
    <ExportPlanDialog
      :open.sync="openExportPlanDialog"
      :item.sync="selectedPlan"
      :version.sync="selectedVersion"
    />
  </fragment>
</template>
<script>
import Simulation from "@/components/Simulator/Simulation";
import List from "@/components/Plans/List";
import DeletePlanDialog from "@/components/Plans/DeletePlanDialog";
import ExportPlanDialog from "@/components/Plans/ExportPlanDialog";
import VersionsPlanDialog from "@/components/Plans/VersionsPlanDialog";
import configs from "@/helpers/configs";

export default {
  components: {
    Simulation,
    List,
    DeletePlanDialog,
    ExportPlanDialog,
    VersionsPlanDialog,
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
  },
  data() {
    return {
      plansKey: 0,
      simulationKey: 0,
      isPlansListEnabled: false,
      openSimulationDialog: false,
      openEditPlanDialog: false,
      openDeletePlanDialog: false,
      openExportPlanDialog: false,
      openVersionsPlanDialog: false,
      selectedPlan: null,
      selectedVersion: null,
      reloadPlans: false,
    };
  },
  watch: {
    isPlansListEnabled() {
      if (this.isPlansListEnabled) {
        this.plansKey++;
      } else {
        this.$emit("closed");
      }
    },
    openSimulationDialog(val) {
      this.$store.dispatch("plans/SET_ANNOTATIONS_ENABLED", false);
      if (val == false) this.forceRerender();
    },
    openEditPlanDialog(val) {
      if (val == false) this.forceRerender();
    },
    openDeletePlanDialog(val) {
      if (val == false) this.forceRerender();
    },
    openVersionsPlanDialog(val) {
      if (val == false) this.reloadPlans = true;
    },
  },
  methods: {
    add() {
      this.selectedPlan = null;
      this.selectedVersion = null;
      this.openSimulationDialog = true;
    },
    edit(plan) {
      this.selectedPlan = plan;
      this.selectedVersion = plan ? plan.last_version : null;
      this.openSimulationDialog = true;
    },
    remove(plan) {
      this.selectedPlan = plan;
      this.openDeletePlanDialog = true;
    },
    xlsx(plan) {
      this.selectedPlan = plan;
      this.selectedVersion = plan ? plan.last_version : null;
      this.openExportPlanDialog = true;
    },
    versions(plan) {
      this.selectedPlan = plan;
      this.openVersionsPlanDialog = true;
    },
    forceRerender() {
      this.plansKey += 1;
      this.simulationKey += 1;
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
