<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="toggleMeasurement"
          small
          class="py-5"
          :color="isEnabled ? 'primary' : 'white'"
          :disabled="isDisabled"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon :color="isEnabled ? 'white' : 'primary'"> mdi-ruler </v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.measures") }} </span>
    </v-tooltip>

    <v-snackbar top v-model="showDistanceMessage" width="60%" :timeout="-1">
      <v-icon left> mdi-ruler </v-icon>{{ $t("measures.explanation") }}
      <template v-slot:action="{ attrs }">
        <v-btn small color="primary" v-bind="attrs" @click="clearMeasures">
          <v-icon small left>mdi-eraser</v-icon>{{ $t("measures.clear") }}
        </v-btn>
      </template>
    </v-snackbar>
  </fragment>
</template>

<script>
import { DistanceTool, VectorLayer, Marker, Label } from "maptalks";
import { v4 as uuidv4 } from "uuid";
/**
 * Distance Tool extension to support Vue.js translations based on the locale
 *
 */
class TranslatedDistanceTool extends DistanceTool {
  // Override @fuzhenn msOnDrawStart method
  _msOnDrawStart(param) {
    const map = this.getMap();
    const prjCoord = map._pointToPrj(param["point2d"]);
    const uid = uuidv4().replaceAll("-", "");
    const layerId = "distancetool_" + uid;
    const markerLayerId = "distancetool_markers_" + uid;
    if (!map.getLayer(layerId)) {
      this._measureLineLayer = new VectorLayer(layerId).addTo(map);
      this._measureMarkerLayer = new VectorLayer(markerLayerId).addTo(map);
    } else {
      this._measureLineLayer = map.getLayer(layerId);
      this._measureMarkerLayer = map.getLayer(markerLayerId);
    }
    this._measureLayers.push(this._measureLineLayer);
    this._measureLayers.push(this._measureMarkerLayer);
    //start marker
    const marker = new Marker(param["coordinate"], {
      symbol: this.options["vertexSymbol"],
    });
    marker._setPrjCoordinates(prjCoord);
    const content = this.translator.translate("distancetool.start");
    const startLabel = new Label(
      content,
      param["coordinate"],
      this.options["labelOptions"]
    );
    startLabel._setPrjCoordinates(prjCoord);
    this._lastVertex = startLabel;
    this._addVertexMarker(marker, startLabel);
  }

  clear() {
    this.endDraw();
    return super.clear();
  }
}

export default {
  props: ["map", "isDistanceEnabled", "disabled"],
  data() {
    return {
      showDistanceMessage: false,
      distTool: null,
    };
  },
  watch: {
    map(val) {
      if (val) {
        this.register();
      } else {
        this.unregister();
      }
    },
    isEnabled(val) {
      if (val) {
        this.showDistanceMessage = true;
        this.distTool.enable();
      } else {
        this.showDistanceMessage = false;
        this.distTool.disable();
      }
    },
  },
  computed: {
    isEnabled: {
      get() {
        return this.isDistanceEnabled;
      },
      set(val) {
        this.$emit("update:isDistanceEnabled", val);
      },
    },
    isDisabled: {
      get() {
        return this.disabled;
      },
      set(val) {
        this.$emit("update:disabled", val);
      },
    },
  },
  mounted() {
    this.register();
  },
  beforeDestroy() {
    this.unregister();
  },
  methods: {
    unregister() {
      if (this.distTool) {
        this.distTool.remove();
      }
    },
    register() {
      this.unregister();
      if (this.map) {
        this.distTool = new TranslatedDistanceTool({
          symbol: {
            lineColor: "#34495e",
            lineWidth: 2,
          },
          vertexSymbol: {
            markerType: "ellipse",
            markerFill: "#34495e",
            markerLineColor: "#34495e",
            markerLineWidth: 1,
            markerWidth: 5,
            markerHeight: 5,
          },
          labelOptions: {
            textSymbol: {
              textFaceName: "monospace",
              textFill: "#fff",
              textLineSpacing: 1,
              textHorizontalAlignment: "right",
              textDx: 15,
              markerLineColor: "#34495e",
              markerFill: "#34495e",
            },
            boxStyle: {
              padding: [6, 2],
              symbol: {
                markerType: "square",
                markerFill: "#34495e",
                markerFillOpacity: 0.9,
                markerLineColor: "#34495e",
              },
            },
          },
          clearButtonSymbol: [
            {
              markerType: "square",
              markerFill: "#34495e",
              markerLineColor: "#34495e",
              markerLineWidth: 2,
              markerWidth: 15,
              markerHeight: 15,
              markerDx: 20,
            },
            {
              markerType: "x",
              markerWidth: 10,
              markerHeight: 10,
              markerLineColor: "#fff",
              markerDx: 20,
            },
          ],
          language: "es-MX",
        }).addTo(this.map);
        this.distTool.on("drawstart", function (param) {
          setTimeout(() => {
            param.drawTool._drawToolLayer.setZIndex(1000);
            if (param.drawTool._measureMarkerLayer)
              param.drawTool._measureMarkerLayer.setZIndex(1000);
          }, 100);
        });
        var map = this.map;
        this.distTool.on("drawend", function () {
          map.getLayers().forEach((l) => {
            if (l._id.includes("distancetool_")) l.setZIndex(1000);
          });
        });
        this.distTool.disable();
      }
    },
    toggleMeasurement() {
      this.isEnabled = !this.isEnabled;
    },
    clearMeasures() {
      this.map.getLayers().forEach((l) => {
        if (l._id.includes("distancetool_")) l.clear();
      });
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
