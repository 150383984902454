<template>
  <v-btn-toggle style="z-index: 100">
    <ResetView :map="this.map" />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          class="py-5"
          @click="showAll = !showAll"
          v-bind="attrs"
          v-on="on"
          :color="showAll ? 'primary' : 'white'"
        >
          <v-icon :color="showAll ? 'white' : 'primary'">mdi-tools</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.toolbar") }} </span>
    </v-tooltip>

    <LayersDrawer :map="this.map" :appear="showAll" />

    <Bathymetry :map="this.map" v-if="showAll" />

    <LockMovement
      :draggable.sync="draggableEnabled"
      :archived="archived"
      v-if="showAll"
    />

    <UndoMovements
      :performedActions.sync="performedActionsList"
      @undoAction="handleUndoAction"
      v-if="showAll"
    />

    <Annotations
      :map="this.map"
      disabled="true"
      :annotations.sync="annotationsList"
      v-if="showAll"
    />

    <PreviewSnapshot
      :snapshot.sync="snapshotEnabled"
      v-if="showAll"
    ></PreviewSnapshot>

    <MeasuringTools :map="this.map" v-if="showAll" />

    <SwitchTileLayer :map.sync="this.map" :appear="showAll" />

    <Screenshot
      v-if="showAll"
      :mapRef="this.map"
      mapClassRef=".map-simulation"
      annotations="annotations"
      scheduler="scheduler"
    />
  </v-btn-toggle>
</template>
<script>
import ResetView from "@/components/Tools/ResetView";
import LockMovement from "@/components/Tools/LockMovement";
import LayersDrawer from "@/components/Tools/LayersDrawer";
import Bathymetry from "@/components/Tools/Bathymetry";
import MeasuringTools from "@/components/Tools/MeasuringTools";
import Annotations from "@/components/Tools/Annotations";
import UndoMovements from "@/components/Tools/UndoMovements";
import PreviewSnapshot from "@/components/Tools/PreviewSnapshot";
import Screenshot from "@/components/Tools/Screenshot.vue";
import SwitchTileLayer from "@/components/Tools/SwitchTileLayer.vue";

export default {
  name: "MapToolBar",
  components: {
    ResetView,
    LockMovement,
    LayersDrawer,
    Bathymetry,
    MeasuringTools,
    Annotations,
    UndoMovements,
    PreviewSnapshot,
    Screenshot,
    SwitchTileLayer,
  },
  props: [
    "map",
    "draggable",
    "snapshot",
    "annotations",
    "performedActions",
    "scheduler",
    "archived",
  ],
  data() {
    return {
      showAll: false,
    };
  },
  methods: {
    handleUndoAction(action) {
      this.$emit("undo", action);
    },
  },
  computed: {
    draggableEnabled: {
      get() {
        return this.draggable;
      },
      set(val) {
        this.$emit("update:draggable", val);
      },
    },
    annotationsList: {
      get() {
        return this.annotations;
      },
      set(val) {
        this.$emit("update:annotations", val);
      },
    },
    performedActionsList: {
      get() {
        return this.performedActions;
      },
      set(val) {
        this.$emit("update:performedActions", val);
      },
    },
    snapshotEnabled: {
      get() {
        return this.snapshot;
      },
      set(val) {
        this.$emit("update:snapshot", val);
      },
    },
  },
};
</script>
