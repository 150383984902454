import shipUtils from "@/helpers/ships/ship_utils";
import Ship from "@/helpers/ships/ship";
const DEFAULT_SHIP_HEADING = 0;
const SHIP_ADITIONAL_HEADING_ADJUSTMENT = -90; //Ships in AIS need to turn arround full 180º

/**
 * Ship abstraction that contains several parts:
 *
 * *
 *    E
 *    /\
 *   /  \
 *  /    \
 * A------B
 * |      |
 * |      |
 * |      |
 * |      |
 * | ___  |
 * ||1|2| |
 * ||_|_| |
 * D\----/C
 *  F--Z--G
 *
 *
 *
 * - Hull (main ship part)
 * - ISPS Code (1)
 * - Dangerous Cargo (2)
 *
 *
 * In this case, this ship can be a circle (if invalid hdg is on)
 */
export default class AISShip extends Ship {
  /**
   * @param {Geometry[]} geometries - GeometryCollection's geometries
   * @param {Object} [opts=null] - options defined in [nGeometryCollection]{@link GeometryCollection#options}
   * @param {Object} [specification=null] - set of specifications used to represent this particular ship
   */
  constructor(geometries, opts, specification) {
    super(geometries, opts, specification);
  }

  setShipSpec(spec) {
    if (!spec.Heading) {
      spec.Heading = DEFAULT_SHIP_HEADING;
    }

    // Fix a bug in AIS, where ships are turned arround.
    if (spec.Heading != 511) spec.Heading = spec.Heading + SHIP_ADITIONAL_HEADING_ADJUSTMENT;

    super.setShipSpec(spec);
  }

  //Override this
  _createShape() {
    if (this.shipSpecification) {
      var shipParts = [];
      //Get the hull of the ship (this is the main part)
      var hullSkeleton = shipUtils.getShipSkeleton(this.shipSpecification, shipUtils.getAISShipStyle(this.shipSpecification));
      this.setHull(hullSkeleton);
      //Add several ship parts:
      shipParts.push(hullSkeleton);
      this.setGeometries(shipParts);
      if (this.highlighted) {
        this.addHighlight();
      } else {
        this.removeHighlight();
      }
    }
  }

  //Override this
  _updateShape() {
    if (this.shipSpecification) {
      //Update the hull of the ship (this is the main part)
      if (this.hull) {
        let hullNeedsRedraw = false;
        if (!this.shipSpecification.hasInvalidHeading) {
          //this is (now) a polygon. If it was a circle, we need to remove the circle and build a polygon
          if (this.hull._jsonType !== "Circle") {
            //this was never a circle, this is the normal polygon update
            let hullPoints = shipUtils.getPoints(this.shipSpecification);
            this.hull.setCoordinates(hullPoints);
          } else {
            //this was a circle, need to update the hull completely
            hullNeedsRedraw = true;
          }
        } else {
          //if we are talking about an invalid hdg here, we must draw a circle
          if (this.hull._jsonType !== "Circle") {
            //this was a polygon, need to update the hull completely
            hullNeedsRedraw = true;
          } else {
            this.hull.setCoordinates(this.shipSpecification.coordinates);
          }
        }
        if (hullNeedsRedraw) {
          //Remove all parts
          this.hull.remove();

          //...rebuild the hull and add them again
          this.hull = shipUtils.getShipSkeleton(this.shipSpecification, shipUtils.getAISShipStyle(this.shipSpecification));
          this.setGeometries([this.hull]);
        } else {
          if (this.hull._jsonType !== "Circle") {
            let textRotation = 0;
            let hdgAux = parseFloat(this.shipSpecification.hdg + 90) % 360;
            if (hdgAux > 180) textRotation = -parseFloat(this.shipSpecification.hdg) + 180;
            else textRotation = -parseFloat(this.shipSpecification.hdg);

            let symbol = this.hull._symbol
            symbol.textRotation = textRotation;
            this.hull.updateSymbol(symbol);
          }
        }
      }
      if (this.highlighted) {
        this.addHighlight();
      } else {
        this.removeHighlight();
      }
    }
  }

  /**
   * Removes (if any) highlight of the current ship
   *
   */
  removeHighlight() {
    if (this.hull && this.highlighted) {
      let style = shipUtils.getAISShipStyle(this.shipSpecification);
      this.hull.updateSymbol(style.symbol);
      this.highlighted = false;
    }
  }
}
