<template>
  <v-dialog v-model="isOpen" persistent width="80%" scrollable>
    <VesselCallDialog :item="item" key="componentKey" :isEdit="false" :disabledInfo="disabledHeading" :stayRange="stayRange" :availableRange="availableRangeDate" @shipSelected="shipSelected" @updateCoords="updateCoords" @close="close" @save="save" />
  </v-dialog>
</template>

<script>
  import { Settings } from "luxon";
  import configs from "@/helpers/configs";
  import VesselCallDialog from "@/components/Simulator/VesselCallDialog";
  Settings.defaultZone = configs.getTimezone();
  Settings.defaultLocale = configs.getLocale();

  const VESSEL_CALL = {
    temporary: true,
    berth_location: null,
    end_mooring_bow: null,
    end_mooring_stern: null,
    entry_bow_draught: 0,
    exit_bow_draught: 0,
    entry_stern_draught: 0,
    exit_stern_draught: 0,
    buffer_stern: 0,
    buffer_bow: 0,
    buffer_starboard: 0,
    buffer_portside: 0,
    stay: { start: new Date(), end: new Date() },
    hdg: "",
    id: "",
    imo: "",
    lat: "",
    loa: 100,
    beam: 20,
    lon: "",
    name: "",
    port_locode: configs.getLocode().code,
    process_number: "",
    status: "EXPECTED_ARRIVAL_VESSEL_CALLS",
    updated_at: new Date(),
    visible: true,
  };
  export default {
    props: ["open", "stayRange", "availableRange"],
    components: {
      VesselCallDialog,
    },
    data() {
      return {
        item: Object.assign({}, VESSEL_CALL),
        componentKey: 0,
        disabledHeading: false,
      };
    },
    computed: {
      isOpen: {
        get() {
          return this.open;
        },
        set(val) {
          this.$emit("update:open", val);
        },
      },
      locode() {
        return configs.getLocode();
      },
      availableRangeDate() {
        return this.availableRange;
      },
    },
    created() {
      this.initialize();
    },
    methods: {
      updateCoords() {
        if (this.item.end_mooring_bow || this.item.end_mooring_stern) {
          this.item.hdg = "";
          this.item.lat = "";
          this.item.lon = "";
          this.disabledHeading = true;
        } else {
          this.disabledHeading = false;
        }
      },
      initialize() {
        this.item = JSON.parse(JSON.stringify(VESSEL_CALL));
        this.item.process_number = this.getRandomProcessNumber();
        this.item.id = this.item.process_number;
      },
      getRandomProcessNumber() {
        return this.locode.code + Math.random().toString().slice(2, 10) + "T";
      },

      nameAndCodeFilter(item, queryText) {
        return (item.code && item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) || (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())) > -1;
      },

      shipSelected(val) {
        if (val) {
          this.item.name = val.name;
          this.item.imo = val.imo;
          this.item.beam = val.breadth_moulded;
          this.item.loa = val.loa;
          this.item.entry_bow_draught = val.maximum_draught;
          this.item.exit_bow_draught = val.maximum_draught;
          this.item.entry_stern_draught = val.maximum_draught;
          this.item.exit_stern_draught = val.maximum_draught;
          this.item.process_number = this.getRandomProcessNumber();
          this.item.id = this.item.process_number;
        } else {
          //DEFAULT
          this.item = JSON.parse(JSON.stringify(VESSEL_CALL));
          this.item.process_number = this.getRandomProcessNumber();
          this.item.id = this.item.process_number;
        }
        this.forceUpdate();
      },

      close() {
        this.isOpen = false;
      },
      save() {
        this.item.eta = this.item.stay.start;
        this.item.etd = this.item.stay.end;

        //reset lat / lon database to recalc position
        if (this.item.upcoming_berth_location != this.item.berth_location) {
          this.item.lat = null;
          this.item.lon = null;
          this.item.upcoming_berth_location = this.item.berth_location;
        }

        this.item.updated_at = new Date();
        let createdVesselCall = JSON.parse(JSON.stringify(this.item));

        this.$emit("created", createdVesselCall);
        this.close();
      },
      forceUpdate() {
        this.componentKey += 1;
      },
    },
  };
</script>
