<template>
  <div class="scheduler-container">
    <v-data-table v-if="!!records" class="vesselCallList" :headers="headers" :search="search" :items="vesselCallsList" v-model="selectedVesselCalls" item-key="process_number" dense hide-default-footer disable-pagination fixed-header height="calc(100% - 90px)" :group-by="groupBy" ref="card" show-select :loading="loadingPlan" show-expand single-expand must-sort>
      <template v-slot:top>
        <v-row no-gutters style="border-bottom: thin solid rgba(0, 0, 0, 0.12)" class="mt-1">
          <v-col cols="4" class="pl-1">
            <v-list-item class="ma-0 pa-0" v-if="records && range">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title class="ma-2 mt-1">
                  <b>{{ plan.name }}</b>
                </v-list-item-title>
                <v-list-item-subtitle class="ma-2 mt-0 mr-1">
                  <vc-date-picker v-model="range" mode="date" :masks="masks" is24hr is-range :popover="popoverOptions">
                    <template v-slot="{ inputValue, inputEvents }">
                      <v-chip small label color="black" v-on="inputEvents.start" outlined :disabled="!!loadingPlan || !hasUserPermissionToViewEditOrManage('PLANS') || plan.hasOwnProperty('id') != false"
                        ><!-- user only can change plan dates when create-->
                        <v-icon small left>mdi-calendar</v-icon>{{ inputValue.start }}
                      </v-chip>
                      a
                      <v-chip small label color="black" v-on="inputEvents.end" outlined :disabled="!!loadingPlan || !hasUserPermissionToViewEditOrManage('PLANS') || plan.hasOwnProperty('id') != false"
                        ><!-- user only can change plan dates when create-->
                        <v-icon small left>mdi-calendar</v-icon>{{ inputValue.end }}
                      </v-chip>
                    </template>
                  </vc-date-picker>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="8" class="pr-1 mt-2">
            <v-row>
              <v-col cols="6" class="d-flex"></v-col>
              <v-col cols="3">
                <v-text-field v-model="search" label="Procurar" prepend-inner-icon="mdi-magnify" outlined dense hide-details :disabled="!!loadingPlan" />
              </v-col>
              <v-col cols="3">
                <v-select v-model="groupBy" class="mr-1" :items="groups" outlined label="Agrupar por" dense clearable hide-details :disabled="!!loadingPlan">
                  // Don't forget your props
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-1 grey lighten-3"
          ><span class="text-center text-uppercase font-weight-bold" style="width: 100%">{{ snapshotEnabled ? "Escalas ativas na data: " + currentDate.toFormat("yyyy-MM-dd HH:mm") : "Todas as escalas do plano" }}</span></v-row
        >
      </template>

      <template v-for="h in headers" v-slot:[`header.${h.value}`]="">
        <v-tooltip bottom color="black" v-bind:key="h.value">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span>{{ h.title || h.text }}</span>
        </v-tooltip>
      </template>

      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn depressed @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>

          <span v-if="groupBy == 'status'" class="mx-5 font-weight-bold">{{ group | formatVesselCallStatus | toUpperCase }}</span>
          <span v-else-if="groupBy == 'date'" class="mx-5 font-weight-bold">{{ customFormatDate(group) }}</span>
          <span v-else class="mx-5 font-weight-bold">{{ group }}</span>
        </td>
      </template>

      <template v-slot:item.process_number="{ item }">
        <div class="ma-0 pa-0">
          <v-btn depressed :ref="item.process_number" :class="item.temporary ? 'stripped-btn' : ''" @click.stop="focusOnVesselCall(item)" color="blue" text class="pa-0">
            <b>{{ item.process_number }}</b>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.has_dangerous_good="{ item }">
        <div class="ma-0 pa-0">
          <v-icon v-if="item.has_dangerous_good" color="red" small class="pa-0"> mdi-radioactive </v-icon>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <table>
          <tr>
            <td>
              <v-icon :label="$t('global.view')" @click.stop="showVesselCall(item)" color="blue" small>mdi-information</v-icon>
            </td>
            <td>
              <v-icon :label="$t('global.edit')" v-if="hasUserPermissionToViewEditOrManage('VESSEL_CALLS') && !snapshotEnabled && !archived" @click.stop="editVesselCall(item)" color="blue" small>mdi-pencil</v-icon>
            </td>
            <td>
              <v-icon :label="$t('global.remove')" color="red" v-if="!!item.temporary && hasUserPermissionToManage('VESSEL_CALLS') && !snapshotEnabled && !archived" @click.stop="removeVesselCall(item)" small>mdi-trash-can-outline</v-icon>
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" :color="item.status | formatVesselCallStatusColor" text-color="white" label x-small class="status">
          {{ item.status | formatVesselCallStatus | toUpperCase }}
        </v-chip>
      </template>
      <template v-slot:item.eta="{ item }">
        {{ item.eta | formatDatetime }}
      </template>
      <template v-slot:item.etd="{ item }">
        {{ item.etd | formatDatetime }}
      </template>
      <template v-slot:item.validation="{ item }">
        <table>
          <tr>
            <td>
              <ShieldStatus :item.sync="item.location_status" icon="mdi-map-marker" />
            </td>
            <td>
              <ShieldStatus float :item.sync="item.dates_status" icon="mdi-calendar" />
            </td>
            <td>
              <ShieldStatus :item.sync="item.draught_status" icon="mdi-waves" />
            </td>
          </tr>
        </table>
      </template>

      <template v-slot:header.data-table-select>
        <v-checkbox v-model="selectAll" :indeterminate="indeterminate" hide-details @change="handleToggleAll(selectAll)" class="shrink mr-0 mt-0 ml-2"></v-checkbox>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <v-checkbox class="shrink mr-0 mt-0 ml-2" v-model="item.visible" hide-details @change="updateVesselCalls($event, item)"></v-checkbox>
      </template>
      <template v-slot:item.beam="{ item }">
        {{ item.beam | formatNumber }}
      </template>
      <template v-slot:item.loa="{ item }">
        {{ item.loa | formatNumber }}
      </template>
      <template v-slot:item.entryDraught="{ item }">
        <table>
          <tr>
            <td width="30px">
              {{ item.entryDraught.entrySternDraught | formatNumber }}
            </td>
            <td width="30px">
              <img src="img/ship_mooring_bitts.png" color="blue" />
            </td>
            <td width="30px">
              {{ item.entryDraught.entryBowDraught | formatNumber }}
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:item.exitDraught="{ item }">
        <table>
          <tr>
            <td width="30px">
              {{ item.exitDraught.exitSternDraught | formatNumber }}
            </td>
            <td width="30px">
              <img src="img/ship_mooring_bitts.png" color="blue" />
            </td>
            <td width="30px">
              {{ item.exitDraught.exitBowDraught | formatNumber }}
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:item.mooring_bitts="{ item }">
        <table v-if="!!item.end_mooring_stern && !!item.end_mooring_bow">
          <tr>
            <td width="30px">{{ item.end_mooring_stern.toString() }}</td>
            <td width="35px">
              <img src="img/ship_mooring_bitts.png" color="blue" />
            </td>
            <td width="30px">{{ item.end_mooring_bow.toString() }}</td>
          </tr>
        </table>
      </template>

      <!-- Expand Buttons -->
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-icon v-if="!!item.observations && !isExpanded" small @click="expand(true)">mdi-chat-processing-outline</v-icon>
        <v-icon v-if="!!item.observations && isExpanded" small color="blue" @click="expand(false)">mdi-chat-processing-outline</v-icon>
      </template>

      <template v-slot:item.hdg="{ item }">
        <span v-if="!item.end_mooring_stern && !item.end_mooring_bow && item.visible">
          {{ item.hdg | formatTrueHeading }}
        </span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="pa-2">
            <table>
              <tr v-if="!!item.observations">
                <th>Observações:</th>
                <td class="pl-5">
                  {{ item.observations | toUpperCase }}
                </td>
              </tr>
            </table>
          </div>
        </td>
      </template>
    </v-data-table>

    <DeleteVesselCallDialog :open.sync="openDeleteVesselCallDialog" :item="vesselCallToDelete" @remove="handleVesselCallRemove" />

    <EditVesselCallDialog :open.sync="openEditVesselCallDialog" :item.sync="vesselCallToEdit" @updated="handleVesselCallEdit" :availableRange="availableRange" />
  </div>
</template>

<script>
  import Vue from "vue";
  import { Settings, DateTime } from "luxon";
  import configs from "@/helpers/configs";
  Settings.defaultZone = configs.getTimezone();
  Settings.defaultLocale = configs.getLocale();

  import DeleteVesselCallDialog from "@/components/Simulator/DeleteVesselCallDialog";
  import EditVesselCallDialog from "@/components/Simulator/EditVesselCallDialog";

  /* Vessel calls socket*/
  import ShieldStatus from "./ShieldStatus.vue";

  const STATUSES = configs.getVesselCallStatuses();

  export default {
    props: ["item", "plan", "records", "detail", "stayRange", "filterRange", "snapshotEnabled", "currentDate", "archived"],
    components: {
      DeleteVesselCallDialog,
      EditVesselCallDialog,
      ShieldStatus,
    },
    data() {
      return {
        popoverOptions: {
          placement: "bottom",
        },
        masks: {
          input: "DD/MM/YYYY",
        },
        selectAll: true,
        search: "",
        groupBy: null,
        groups: [
          { value: "status", name: "Estado" },
          { value: "berth_location", name: "Local" },
          { value: "date", name: "Data (ETA)" },
        ],
        openDeleteVesselCallDialog: false,
        vesselCallToDelete: null,
        openEditVesselCallDialog: false,
        vesselCallToEdit: null,
        selectedVesselCalls: [],
        selectedStatuses: STATUSES.map((item) => item.code),
        statuses: STATUSES,
        headers: [
          {
            text: "ESCALA",
            align: "start",
            sortable: true,
            value: "process_number",
            groupable: false,
            width: "100px",
          },
          {
            text: "MP",
            title: "Mercadoria Perigosa",
            align: "start",
            sortable: true,
            value: "has_dangerous_good",
            class: "font-weight-bold",
            groupable: false,
            width: "60px",
          },
          {
            text: "NOME",
            align: "start",
            sortable: true,
            value: "name",
            class: "font-weight-bold",
            groupable: false,
            width: "150px",
          },
          {
            text: "ESTADO",
            align: "start",
            sortable: true,
            value: "status",
          },
          {
            text: "ETA",
            align: "start",
            sortable: true,
            value: "eta",
            width: "120px",
          },
          {
            text: "ETD",
            align: "start",
            sortable: true,
            value: "etd",
            width: "120px",
          },
          {
            text: "LOCAL",
            align: "start",
            sortable: true,
            value: "berth_location",
          },
          {
            text: "CABEÇOS",
            align: "center",
            sortable: false,
            value: "mooring_bitts",
            groupable: false,
            width: "120px",
          },
          {
            text: "LOA (CFF)",
            align: "start",
            sortable: false,
            value: "loa",
            groupable: false,
          },
          {
            text: "BEAM",
            align: "start",
            sortable: false,
            value: "beam",
            groupable: false,
          },
          {
            text: "CALADO ENTRADA",
            align: "center",
            sortable: false,
            value: "entryDraught",
            groupable: false,
            width: "120px",
          },
          {
            text: "CALADO SAÍDA",
            align: "center",
            sortable: false,
            value: "exitDraught",
            groupable: false,
            width: "120px",
          },
          {
            text: "VALIDAÇÃO",
            align: "center",
            sortable: false,
            value: "validation",
            groupable: false,
            width: "90px",
          },
          {
            text: "OBSERVAÇÕES",
            value: "data-table-expand",
            align: "center",
            groupable: false,
            sortable: false,
          },
          {
            text: "AÇÕES",
            value: "actions",
            sortable: false,
            align: "center",
            width: "30px",
            groupable: false,
          },
          {
            text: "",
            value: "data_search",
            align: " d-none",
          },
        ],
      };
    },
    watch: {
      vesselCall() {
        if (this.vesselCall) {
          this.selectedVesselCalls = [this.vesselCall];
          this.$nextTick(() => {
            let elem = document.querySelector(".v-data-table__selected");
            if (elem) elem.scrollIntoView({ block: "end", behavior: "smooth" });
          });
        } else {
          this.selectedVesselCalls = [];
        }
      },
      loadingPlan() {
        this.search = "";
        this.groupBy = null;
        this.selectedVesselCalls = [];
      },
    },
    computed: {
      loadingPlan() {
        return this.$store.state.plans.loading;
      },
      vesselCalls: {
        get() {
          return this.cleanDuplicatesByProcessNumber(this.records);
        },
        set(val) {
          this.$emit("update:records", val);
        },
      },
      vesselCallsList: {
        get() {
          const filters = this.$options.filters;
          return this.vesselCalls.map((vscall) => {
            return {
              ...vscall,
              date: DateTime.fromISO(vscall.eta).toFormat("yyyy/LL/dd"),
              entryDraught: {
                entryBowDraught: vscall?.entry_bow_draught,
                entrySternDraught: vscall?.entry_stern_draught,
              },
              exitDraught: {
                exitBowDraught: vscall?.exit_bow_draught,
                exitSternDraught: vscall?.exit_stern_draught,
              },
              mooring_biits: {
                ...vscall.end_mooring_bow,
                ...vscall.end_mooring_stern,
              },
              data_search: JSON.stringify({
                process_number: vscall.process_number,
                name: vscall.name,
                status: vscall.status ? filters.formatVesselCallStatus(vscall.status).toLocaleLowerCase() : "",
                eta: vscall.etd ? filters.formatDatetime(vscall.eta).toLocaleLowerCase() : "",
                etd: vscall.etd ? filters.formatDatetime(vscall.etd).toLocaleLowerCase() : "",
                beam: vscall.beam ? filters.formatNumber(vscall.beam).toLocaleLowerCase() : "",
                loa: vscall.loa ? filters.formatNumber(vscall.loa).toLocaleLowerCase() : "",
                entryDraught: vscall.entry_bow_draught ? filters.formatNumber(vscall.entry_bow_draught) : "",
                entrySternDraught: vscall.entry_stern_draught ? filters.formatNumber(vscall.entry_stern_draught) : "",
                exitBowDraught: vscall.exit_bow_draught ? filters.formatNumber(vscall.exit_bow_draught) : "",
                exitSternDraught: vscall.exit_stern_draught ? filters.formatNumber(vscall.exit_stern_draught) : "",
                end_mooring_stern: vscall.end_mooring_stern ? vscall.end_mooring_stern.toLocaleLowerCase() : "",
                end_mooring_bow: vscall.end_mooring_bow ? vscall.end_mooring_bow.toLocaleLowerCase() : "",
              }),
            };
          });
        },
      },
      vesselCall: {
        get() {
          return this.item;
        },
        set(val) {
          this.$emit("update:item", val);
        },
      },
      range: {
        get() {
          return this.stayRange;
        },
        set(val) {
          this.$emit("update:stayRange", val);
        },
      },
      availableRange() {
        if (this.filterRange) {
          return [
            {
              start: new Date(this.filterRange.start),
              end: new Date(this.filterRange.end),
            },
            {
              start: new Date(this.filterRange.start),
              end: null,
            },
          ];
        } else {
          return [];
        }
      },
      detailVesselCall: {
        get() {
          return this.detail;
        },
        set(val) {
          this.$emit("update:detail", val);
        },
      },
      indeterminate() {
        return this.vesselCallsList.some((vscall) => vscall.visible) && this.vesselCallsList.some((vscall) => !vscall.visible);
      },
    },
    filters: {
      formatVesselCallStatus: function (code) {
        if (!code) return "";
        return STATUSES.find(function (data) {
          return data.code == code;
        }).text;
      },
      formatVesselCallStatusColor: function (code) {
        if (!code) return "";
        return STATUSES.find(function (data) {
          return data.code == code;
        }).color;
      },
      formatVesselCallETAColor: function (code) {
        if (!code) return "";
        return STATUSES.find(function (data) {
          return data.code == code;
        }).color;
      },
      toUpperCase: function (value) {
        return value.toUpperCase();
      },
    },

    methods: {
      customFormatDate(date) {
        return DateTime.fromFormat(date, "yyyy/LL/dd").toFormat("dd/LL/yyyy");
      },
      focusOnVesselCall(item) {
        this.vesselCall = item;
      },
      handleVesselCallRemove(vesselCall) {
        let idx = this.vesselCalls.findIndex((vc) => vc.process_number == vesselCall.process_number);
        if (idx != -1) {
          this.vesselCalls.splice(idx, 1);
        }
        this.vesselCalls = JSON.parse(JSON.stringify(this.vesselCalls));
      },
      handleVesselCallEdit(vesselCall) {
        let idx = this.vesselCalls.findIndex((vc) => vc.process_number == vesselCall.process_number);
        if (idx != -1) {
          this.vesselCalls.splice(idx, 1, vesselCall);
        }
        this.vesselCalls = JSON.parse(JSON.stringify(this.vesselCalls));
        this.detailVesselCall = null;
      },
      removeVesselCall(item) {
        this.vesselCallToDelete = item;
        this.openDeleteVesselCallDialog = true;
      },
      editVesselCall(item) {
        this.vesselCallToEdit = item;
        this.openEditVesselCallDialog = true;
      },
      showVesselCall(item) {
        this.detailVesselCall = item;
      },
      formatVesselCallStatus: function (code) {
        if (!code) return "";
        return STATUSES.find(function (data) {
          return data.code == code;
        }).text;
      },
      formatVesselCallStatusColor: function (code) {
        if (!code) return "";
        return STATUSES.find(function (data) {
          return data.code == code;
        }).color;
      },
      formatVesselCallETAColor: function (code) {
        if (!code) return "";
        return (
          STATUSES.find(function (data) {
            return data.code == code;
          }).color + "--text text-right"
        );
      },
      toUpperCase: function (value) {
        return value.toUpperCase();
      },
      updateVesselCalls(e, item) {
        let index = this.vesselCalls.findIndex((vc) => vc.process_number == item.process_number);
        Vue.set(this.vesselCalls, index, item);
        this.vesselCalls = JSON.parse(JSON.stringify(this.vesselCalls));
      },
      handleToggleAll(state) {
        this.vesselCalls = this.vesselCalls.map((vcall) => {
          vcall.visible = state;
          return vcall;
        });
      },
      cleanDuplicatesByProcessNumber(vesselCalls) {
        let seenProcessNumbers = {};
        let uniqueVesselCalls = [];

        for (let vesselCall of vesselCalls) {
          if (!seenProcessNumbers[vesselCall.process_number]) {
            seenProcessNumbers[vesselCall.process_number] = true;
            uniqueVesselCalls.push(vesselCall);
          }
        }

        return uniqueVesselCalls;
      },
    },
  };
</script>
<style scoped>
  .scheduler-container {
    height: 100%;
  }

  .stripped-btn {
    background: repeating-linear-gradient(-45deg, rgba(255, 142, 142, 0.5), rgba(255, 142, 142, 0.5) 2px, white 2px, white 10px);
  }
</style>
<style>
  tr.v-data-table__selected {
    background: yellow !important;
  }

  .v-data-footer {
    background-color: rgb(250, 250, 250);
  }

  .theme--light.v-data-table thead tr th {
    color: white;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper {
    height: 100%;
  }

  .vesselCallList {
    display: inline;
  }

  .vesselCallList .v-data-table__wrapper > table > tbody > tr > th,
  .vesselCallList .v-data-table__wrapper > table > tbody > tr > th,
  .vesselCallList .v-data-table__wrapper > table > thead > tr > th,
  .vesselCallList .v-data-table__wrapper > table > tfoot > tr > td,
  .vesselCallList .v-data-table__wrapper > table > thead > tr > td,
  .vesselCallList .v-data-table__wrapper > table > thead > tr > td,
  .vesselCallList tbody tr td,
  .vesselCallList .v-chip__content {
    font-size: 11px !important;
    padding-left: 2px !important;
  }

  .vesselCallList tr.v-data-table__expanded__content {
    box-shadow: none !important;
    background-color: rgb(245, 242, 223);
  }

  .vesselCallList .v-text-field input,
  .vesselCallList .v-text-field .v-select__selections {
    font-size: 14px;
  }
</style>
