<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="undo"
        :disabled="disabled"
        small
        class="py-5"
        color="white"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon color="primary">mdi-undo</v-icon>
      </v-btn>
    </template>
    <span> {{ $t("global.undo") }} </span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["performedActions"],
  data() {
    return {};
  },
  computed: {
    disabled() {
      return !this.actions || this.actions.length == 0;
    },
    actions: {
      get() {
        return this.performedActions;
      },
      set(actions) {
        this.$emit("update:performedActions", actions);
      },
    },
  },
  methods: {
    undo() {
      let action = this.actions.pop();
      this.$emit("undoAction", action);
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>

