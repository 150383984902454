<template>
  <v-container fluid fill-height class="pa-0">
    <Map
      :refMap.sync="map"
      :records.sync="records"
    />
  </v-container>
</template>

<script>
import Map from "@/components/Main/Map";
import configs from "@/helpers/configs";
import SocketServiceHelper from "@/helpers/realtime/socketservicehelper";

export default {
  components: {
    Map,
  },
  computed: {
    vesselCalls() {
      return this.$store.state.vessel_calls.all;
    },
    aisShipListOpen: {
      get() {
        return this.menuMobile == 2 && this.$vuetify.breakpoint.xs;
      },
      set(val) {
        return val;
      },
    },
    locode() {
      return configs.getLocode();
    },
  },
  data() {
    return {
      map: null,
      records: [],
      loading: true,
    };
  },
  mounted() {
    SocketServiceHelper.initialize();
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
  beforeDestroy() {
    SocketServiceHelper.deInitialize();
  },
  watch: {
    vesselCalls() {
      this.records = this.vesselCalls.map((vcall) => {
        return {
          ...vcall,
          visible:
            vcall.end_mooring_bow && vcall.end_mooring_stern ? true : false,
        };
      });
    },
  },
};
</script>