<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="appear"
        small
        class="py-5"
        @click="changeLayer"
        :color="toggled ? 'primary' : 'white'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :color="toggled ? 'white' : 'primary'">mdi-map-check</v-icon>
      </v-btn>
    </template>
    <span> {{ $t("global.switch_tile_layer") }} </span>
  </v-tooltip>
</template>

<script>
import * as maptalks from "maptalks";
import configs from "@/helpers/configs";
const DEFAULT_LAYER = configs.getDefaultRasterCode();
const DEFAULT_LAYER_NV = configs.getColoredRasterCode();
const DEFAULT_LAYER_URL = configs.getDefaultRasterUrl();
const DEFAULT_LAYER_NV_URL = configs.getColoredRasterUrl();

export default {
  props: ["map", "appear"],
  data() {
    return {
      toggled: false,
    };
  },
  computed: {
    refMap: {
      get() {
        return this.map;
      },
      set(val) {
        this.$emit("update:map", val);
      },
    },
  },
  methods: {
    changeLayer() {
      this.toggled = !this.toggled;

      let currentLayer = null,
        urlLayer = null,
        nameLayer = null;

      if (this.toggled) {
        currentLayer = DEFAULT_LAYER;
        urlLayer = DEFAULT_LAYER_NV_URL;
        nameLayer = DEFAULT_LAYER_NV;
      } else {
        currentLayer = DEFAULT_LAYER_NV;
        urlLayer = DEFAULT_LAYER_URL;
        nameLayer = DEFAULT_LAYER;
      }

      let current = this.refMap.getLayers().find((l) => l._id === currentLayer);
      if (current) this.refMap.removeLayer(current);
      let baseLayer = this.createBaseLayer(urlLayer, nameLayer);

      baseLayer.addTo(this.refMap);
      baseLayer.bringToBack();

      if (this.appear) {
        this.$store.dispatch("screenshots/SET_BASE_LAYER", nameLayer);
      }
    },
    createBaseLayer(urlTemplate, layerName) {
      return new maptalks.TileLayer(layerName, {
        urlTemplate: urlTemplate,
        subdomains: ["a", "b", "c", "d"],
      });
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
