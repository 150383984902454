<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small class="py-5" color="white" v-bind="attrs" v-on="on" @click="saveScreenshot()" :loading="screenshotLoading">
          <v-icon color="primary">mdi-camera</v-icon>
        </v-btn>
      </template>
      <span> Capturar </span>
    </v-tooltip>

    <v-dialog v-model="screenshotEnabled" persistent width="1200px">
      <v-card tile style="overflow: hidden">
        <v-toolbar dark flat dense color="blue darken-4">
          <v-toolbar-title>
            <span> <v-icon left>mdi-camera</v-icon>Captura</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn depressed icon dark @click="screenshotEnabled = false" :disabled="screenshotUpdating || screenshotLoading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <div style="position: relative; height: 400px" v-if="screenshotLoading">
            <Loading :value="screenshotLoading"></Loading>
          </div>
          <div class="ma-3" v-show="screenshotFinished && !screenshotLoading">
            <v-alert type="info" dark>
              <v-row>
                <v-col>
                  <span> Preencha o formulário com mais dados, partilhe o link ou descarregue o PDF </span>
                </v-col>
              </v-row>
            </v-alert>
            <v-row>
              <v-col cols="auto" class="pa-1">
                <v-img :src="screenshotImage" height="auto" width="160mm" ratio="185/297" class="ma-2">
                  <v-icon @click="copyImageToClipboard" color="primary" title="Copiar" :loading="screenshotImageCopying" :disabled="screenshotImageCopying" style="position: absolute; top: 10px; right: 10px">mdi-content-copy</v-icon>
                </v-img>
              </v-col>
              <v-col>
                <v-text-field label="Título" outlined v-model="screenshotTitle" counter="100" maxlength="100" :placeholder="this.$t('screenshot.title')"> </v-text-field>

                <v-textarea rows="8" label="Observações" outlined v-model="screenshotDescription" counter="512" maxlength="512" :placeholder="this.$t('screenshot.description')"></v-textarea>

                <v-text-field :value="screenshotUrl" outlined readonly v-if="!!screenshotUrl">
                  <template v-slot:append>
                    <v-icon @click="copyLinkToClipboard" color="primary" title="Copiar" dark :loading="screenshotUrlCopying">mdi-content-copy</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <span class="font-weight-bold" v-if="!!screenshotUrl"> O registo do planeamento de navios foi capturado com sucesso! Pode partilhar o link público. </span>

          <v-spacer></v-spacer>

          <v-btn @click="generatePDF" color="primary" title="Gerar PDF" outlined :disabled="screenshotUpdating || screenshotLoading"
            ><v-icon left> mdi-file-pdf-box</v-icon>
            Descarregar PDF
          </v-btn>

          <v-btn @click="updateScreenShot" color="primary" title="Publicar" outlined :disabled="screenshotUpdating || screenshotLoading"
            ><v-icon left>mdi-share-variant</v-icon>
            Publicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
  import { jsPDF } from "jspdf";
  import * as maptalks from "maptalks";
  import Loading from "@/components/Main/Loading";
  import { font } from "@/plugins/font";
  import configs from "@/helpers/configs";
  import { sharedHelpers } from "@/api_services/utils/shared-helpers";

  export default {
    components: {
      Loading,
    },
    props: ["mapClassRef", "mapRef"],
    data: () => ({
      screenshotEnabled: false,
      screenshotLoading: false,
      screenshotFinished: false,
      screenshotUrl: null,
      screenshotTitle: null,
      screenshotDescription: null,
      screenshotId: null,
      screenshotCode: null,
      screenshotUpdating: false,
      screenshotUrlCopying: false,
      screenshotImage: null,
      screenshotImageCopying: false,
      screenshotAspectRatio: 0,
      currentDate: new Date(),
      canvasMap: null,
      linkTemporary: null,
    }),
    computed: {
      scheduler() {
        return this.$store.state.screenshots.vesselCalls;
      },
      annotations() {
        return this.$store.state.screenshots.annotations;
      },
      layersId() {
        return this.$store.state.screenshots.layers.map((layer) => layer.id);
      },
      locode() {
        return configs.getLocode();
      },
      baseLayer() {
        return this.$store.state.screenshots.baseLayer;
      },
    },
    methods: {
      async saveScreenshot() {
        this.screenshotEnabled = true;
        this.screenshotLoading = true;
        this.screenshotFinished = false;

        const mapPrint = this.createMapForPrinting();

        const extent = this.mapRef.getExtent();
        const bbox = new maptalks.Polygon(
          [
            [extent.xmin, extent.ymin],
            [extent.xmin, extent.ymax],
            [extent.xmax, extent.ymax],
            [extent.xmax, extent.ymin],
            [extent.xmin, extent.ymin],
          ],
          {
            symbol: {
              polygonFill: "#fff",
              polygonOpacity: 0.5,
            },
          }
        );

        this.mapRef.getLayers().forEach((layer) => {
          try {
            mapPrint.addLayer(maptalks.Layer.fromJSON(layer.toJSON()));
          } catch (error) {
            console.error("Error adding layer:", error, layer);
          }
        });

        mapPrint.fitExtent(bbox.getExtent(), 0, { isFraction: true });

        await this.waitForCanvasReady();
        this.captureScreenshotImage();
        mapPrint.remove();

        this.resetSchedulerObjects();
        await this.saveScreenshotData();
      },

      async saveScreenshotData() {
        const screenshot = {
          title: this.screenshotTitle,
          description: this.screenshotDescription,
          date: this.currentDate,
          active_layer_ids: this.layersId.join(","),
          scheduler: JSON.stringify(this.scheduler),
          annotations: JSON.stringify(this.annotations),
          base_layer: this.baseLayer,
          properties: JSON.stringify({
            center: [this.mapRef.getCenter().x, this.mapRef.getCenter().y],
            bearing: this.mapRef.getBearing(),
            zoom: this.mapRef.getZoom(),
          }),
        };

        this.$store
          .dispatch("screenshots/CREATE_SCREENSHOT", screenshot)
          .then((data) => {
            this.screenshotFinished = true;
            this.screenshotLoading = false;
            this.screenshotTitle = data.title;
            this.screenshotDescription = data.description;
            this.screenshotId = data.id;
            this.screenshotCode = data.code;
            this.linkTemporary = window.location.origin + "/#/snapshots/" + data.code;
          })
          .catch(() => {
            this.screenshotEnabled = false;
            this.screenshotLoading = false;
            this.screenshotFinished = false;
          });
      },

      async updateScreenShot() {
        this.screenshotUpdating = true;
        await this.updateScreenshotData();
        this.screenshotUrl = this.linkTemporary;
        this.screenshotUpdated = true;
        this.screenshotUpdating = false;
      },

      async updateScreenshotData() {
        const data = {
          id: this.screenshotId,
          title: this.screenshotTitle,
          description: this.screenshotDescription,
          date: this.currentDate,
          scheduler: JSON.stringify(this.scheduler),
          annotations: JSON.stringify(this.annotations),
          active_layer_ids: this.layersId.join(","),
          public: true,
          base_layer: this.baseLayer,
          properties: JSON.stringify({
            center: [this.mapRef.getCenter().x, this.mapRef.getCenter().y],
            bearing: this.mapRef.getBearing(),
            zoom: this.mapRef.getZoom(),
          }),
        };

        await this.$store.dispatch("screenshots/UPDATE_SCREENSHOT", data);
      },

      async generatePDF() {
        const account = JSON.parse(sharedHelpers.getStorageItemWithExpiry("account"));

        const pdf = new jsPDF("l", "mm", "a4");
        const imgWidth = 297;
        const imgHeight = 185;
        const margin = 5;
        const headerHeight = 15;
        const footerHeight = 20;
        const currentDate = new Date().toLocaleString().split(",")[0];
        const currentTime = new Date().toLocaleString().split(",")[1];

        const availableWidth = imgWidth - 2 * margin;
        const availableHeight = imgHeight - 2 * margin;

        let width, height;
        if (this.screenshotAspectRatio > 1) {
          width = availableWidth;
          height = availableWidth / this.screenshotAspectRatio;
        } else {
          height = availableHeight;
          width = availableHeight * this.screenshotAspectRatio;
        }

        pdf.addImage(this.screenshotImage, "PNG", margin, margin + headerHeight, width, height);

        pdf.addFileToVFS("Nunito-Bold-bold.ttf", font);
        pdf.addFont("Nunito-Bold-bold.ttf", "Nunito-Bold", "normal");
        pdf.setFont("Nunito-Bold");
        pdf.setFontSize(16);

        pdf.setFillColor(255, 255, 255);
        pdf.rect(margin - 1, margin - 1, imgWidth - 2 * (margin - 1), headerHeight, "F");
        pdf.setTextColor(13, 71, 161);
        pdf.text(this.locode.name, margin, margin + 7);

        pdf.setFontSize(10);

        pdf.text(this.screenshotTitle || "Planeamento Portuário", margin, margin + 12);

        pdf.text(currentDate + " " + currentTime, imgWidth - margin, margin + 7, {
          align: "right",
        });

        pdf.text("Criado por: " + account.user.name, imgWidth - margin, margin + 12, {
          align: "right",
        });

        pdf.setFontSize(10);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(margin - 1, availableHeight + headerHeight - margin - 2, imgWidth - 2 * (margin - 1), footerHeight, "F");

        pdf.text(this.screenshotDescription || "", margin + 5, availableHeight + headerHeight - margin + 3, { maxWidth: imgWidth - 2 * margin - 10 });

        pdf.save("3NAVPLANNING_PLAN_" + this.screenshotCode + ".pdf");
      },

      copyLinkToClipboard() {
        this.screenshotUrlCopying = true;
        navigator.clipboard.writeText(this.screenshotUrl);
        this.screenshotUrlCopying = false;
      },

      async copyImageToClipboard() {
        this.screenshotImageCopying = true;
        const blob = await fetch(this.screenshotImage).then((r) => r.blob());
        navigator.clipboard.write([new window.ClipboardItem({ "image/png": blob })]);
        this.screenshotImageCopying = false;
      },

      createMapForPrinting() {
        const mapPrint = new maptalks.Map("map-print", {
          zoom: this.mapRef.getZoom(),
          center: this.mapRef.getCenter(),
          bearing: this.mapRef.getBearing(),
        });

        return mapPrint;
      },

      waitForCanvasReady() {
        return new Promise((resolve) => setTimeout(resolve, 5000));
      },

      captureScreenshotImage() {
        const canvas = document.querySelector(".map-print .maptalks-canvas-layer").children[0];
        this.screenshotImage = canvas.toDataURL();
        this.screenshotAspectRatio = canvas.offsetWidth / canvas.offsetHeight;
      },

      resetSchedulerObjects() {
        if (this.scheduler) {
          this.scheduler.forEach((obj) => {
            delete obj._drawnObject;
          });
        }
      },
    },
    watch: {
      screenshotEnabled(val) {
        if (!val) {
          this.screenshotLoading = false;
          this.screenshotFinished = false;
          this.screenshotUrl = null;
          this.screenshotTitle = null;
          this.screenshotDescription = null;
          this.screenshotId = null;
          this.screenshotCode = null;
          this.screenshotUpdating = false;
          this.screenshotUrlCopying = false;
          this.screenshotImage = null;
          this.screenshotImageCopying = false;
          this.screenshotAspectRatio = 0;
          this.currentDate = new Date();
          this.canvasMap = null;
          this.linkTemporary = null;
        }
      },
    },
  };
</script>

<style scoped>
  .v-btn--active::before {
    opacity: 0;
  }

  .v-dialog--fullscreen {
    overflow: hidden;
  }
</style>
