<template>
  <div>
    <v-icon
      class="outlined"
      v-if="!alert || alert.status == null"
      color="#E0E0E0"
      v-tooltip.top-center="messageDefault"
      x-small
    >
      {{ this.icon }}
    </v-icon>
    <v-icon
      class="outlined"
      v-else-if="alert.status == 'LEVEL0'"
      color="#E0E0E0"
      v-tooltip.top-center="messageDefault"
      x-small
    >
      {{ this.icon }}
    </v-icon>
    <v-icon
      class="outlined"
      v-else-if="alert.status == 'LEVEL1'"
      color="#2E7D32"
      v-tooltip.top-center="messageOk"
      x-small
    >
      {{ this.icon }}
    </v-icon>
    <v-icon
      class="outlined"
      v-else-if="alert.status == 'LEVEL2'"
      color="#FDD835"
      v-tooltip.top-center="alert.message"
      x-small
    >
      {{ this.icon }}
    </v-icon>
    <v-icon
      class="outlined"
      v-else-if="alert.status == 'LEVEL3'"
      color="#F4511E"
      v-tooltip.top-center="alert.message"
      x-small
    >
      {{ this.icon }}
    </v-icon>
    <v-icon
      class="outlined"
      v-else-if="alert.status == 'LEVEL4'"
      color="#B71C1C"
      v-tooltip.top-center="alert.message"
      x-small
    >
      {{ this.icon }}
    </v-icon>
  </div>
</template>
<script>
export default {
  props: ["item", "icon"],
  components: {},
  computed: {
    alert: {
      get() {
        return {
          status: this.item ? this.item.status : null,
          message: this.item
            ? this.formatMessages(this.item.status, this.item.messages)
            : null,
        };
      },
    },
  },
  data() {
    return {
      messageDefault: "Não validado",
      messageOk: "Ok",
    };
  },
  watch: {},
  methods: {
    formatMessages(status, messages) {
      if (status == "LEVEL0") {
        return this.messageDefault;
      } else if (status == "LEVEL1") {
        return this.messageOk;
      } else {
        var msg_html = "<ul>";
        messages.forEach((message) => {
          msg_html +=
            "<li class='alert-item'>" +
            this.getMessageByCode(message) +
            "</li>";
        });
        msg_html += "</ul>";
        return msg_html;
      }
    },
    getMessageByCode(message) {
      switch (message.type) {
        case "UNKNOWN":
          return this.$t("simulator.UNKNOWN");
        case "BERTH_LOCATION_UNDEFINED":
          return this.$t("simulator.BERTH_LOCATION_UNDEFINED");
        case "BERTH_LOCATION_NOT_FOUND":
          return this.$t("simulator.BERTH_LOCATION_NOT_FOUND");
        case "MOORING_BOW_NOT_FOUND":
          return this.$t("simulator.MOORING_BOW_NOT_FOUND");
        case "MOORING_STERN_NOT_FOUND":
          return this.$t("simulator.MOORING_STERN_NOT_FOUND");
        case "MOORING_BOW_UNAVAILABLE":
          return this.$t("simulator.MOORING_BOW_UNAVAILABLE");
        case "MOORING_STERN_UNAVAILABLE":
          return this.$t("simulator.MOORING_STERN_UNAVAILABLE");
        case "MOORING_BIITS_NOT_COLINEARS":
          return this.$t("simulator.MOORING_BIITS_NOT_COLINEARS");
        case "MOORING_BIITS_DISTANCE_INVALID":
          return this.$t("simulator.MOORING_BIITS_DISTANCE_INVALID");
        case "ANGLE_BOW_INVALID":
          return this.$t("simulator.ANGLE_BOW_INVALID");
        case "ANGLE_STERN_INVALID":
          return this.$t("simulator.ANGLE_STERN_INVALID");
        case "ETA_UNDEFINED":
          return this.$t("simulator.ETA_UNDEFINED");
        case "ETD_UNDEFINED":
          return this.$t("simulator.ETD_UNDEFINED");
        case "ETA_GREATER_THAN_OR_EQUAL_TO_ETD":
          return this.$t("simulator.ETA_GREATER_THAN_OR_EQUAL_TO_ETD");
        case "VESSEL_CALL_OVERLAP_DATES_MB":
          return (
            this.$t("simulator.VESSEL_CALL_OVERLAP_DATES_MB") +
            message.process_number
          );
        case "VESSEL_CALL_OVERLAP_DATES_BL":
          return (
            this.$t("simulator.VESSEL_CALL_OVERLAP_DATES_BL") +
            message.process_number
          );
        case "WORSEZ_UNDEFINED":
          return this.$t("simulator.WORSEZ_UNDEFINED");
        case "ENTRY_BOW_DRAUGHT_UNKNOWN":
          return this.$t("simulator.ENTRY_BOW_DRAUGHT_UNKNOWN");
        case "ENTRY_BOW_DRAUGHT_INVALID":
          return (
            this.$t("simulator.ENTRY_BOW_DRAUGHT_INVALID") + message.ukc + "m)"
          );
        case "EXIT_BOW_DRAUGHT_UNKNOWN":
          return this.$t("simulator.EXIT_BOW_DRAUGHT_UNKNOWN");
        case "EXIT_BOW_DRAUGHT_INVALID":
          return (
            this.$t("simulator.EXIT_BOW_DRAUGHT_INVALID") + message.ukc + "m)"
          );
        case "ENTRY_STERN_DRAUGHT_UNKNOWN":
          return this.$t("simulator.ENTRY_STERN_DRAUGHT_UNKNOWN");
        case "ENTRY_STERN_DRAUGHT_INVALID":
          return (
            this.$t("simulator.ENTRY_STERN_DRAUGHT_INVALID") +
            message.ukc +
            "m)"
          );
        case "EXIT_STERN_DRAUGHT_UNKNOWN":
          return this.$t("simulator.EXIT_STERN_DRAUGHT_UNKNOWN");
        case "EXIT_STERN_DRAUGHT_INVALID":
          return (
            this.$t("simulator.EXIT_STERN_DRAUGHT_INVALID") + message.ukc + "m)"
          );
        default:
          break;
      }
    },
  },
};
</script>
<style>
.tooltip {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 3px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.alert-item {
  margin-top: 2px;
  margin-bottom: 2px;
}

.v-icon.outlined {
  border: 1px solid currentColor;
  color: currentColor;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
</style>
