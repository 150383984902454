<template>
  <div class="map-container">
    <div ref="map" class="map map-main" />

    <div
      style="position: absolute; bottom: 20px; left: 20px"
      v-if="!!locode.ais"
    >
      <AISShips :refMap.sync="map" :ship.sync="aisShip" v-if="map" />
    </div>

    <div style="position: absolute; top: 20px; left: 20px">
      <MapToolBar :map="this.map"></MapToolBar>
    </div>

    <VesselCallShips
      :refMap.sync="map"
      :mooringBittsManager.sync="mooringBittsManager"
      :zonesManager="zonesManager"
      :item.sync="vesselCall"
      v-if="canLoadShips"
      :records.sync="records"
      :showBuffer="false"
      :key="vesselCallsKey"
    />

    <MooringBitts
      :refMap.sync="map"
      :mooringBittsManager.sync="mooringBittsManager"
      v-if="map"
      @loaded="hasLoadedMooringBitts = true"
    />

    <Zones
      :refMap.sync="map"
      v-if="canLoadZones"
      :zonesManager.sync="zonesManager"
      @loaded="hasLoadedZones = true"
      :records.sync="records"
    />
  </div>
</template>

<script>
import configs from "@/helpers/configs";

//Access vue instance to reach global constants
import * as maptalks from "maptalks";

import MapToolBar from "@/components/Main/MapToolBar";

//Other components used by this component
import AISShips from "@/components/Map/AISShips";
import VesselCallShips from "@/components/Map/VesselCallShips";
import MooringBitts from "@/components/Map/MooringBitts";

import Zones from "@/components/Map/Zones";
import MooringBittsManager from "@/helpers/map/mooringbittsmanager";
import ZonesManager from "@/helpers/map/zonesmanager";

const AISSHIPS_LAYERNAME = "ais_ships";
const VCSHIPS_LAYERNAME = "vc_ships";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";
const ZONES_LAYERNAME = "zones";
const CELLS_LAYERNAME = "cells";
const ZHS_LAYERNAME = "zhs";

export default {
  name: "MapMain",
  props: ["refMap", "records"],
  components: {
    MapToolBar,
    VesselCallShips,
    MooringBitts,
    Zones,
    AISShips,
  },
  data() {
    return {
      vesselCallsKey: 0,
      hasLoadedZones: false,
      hasLoadedMooringBitts: false,
      hasLoadedShipTypes: true,
      mooringBittsManager: new MooringBittsManager(),
      zonesManager: new ZonesManager(),
      draggable: false,
    };
  },
  computed: {
    canLoadZones() {
      return this.map;
    },
    canLoadShips() {
      return (
        this.map &&
        this.hasLoadedMooringBitts &&
        this.hasLoadedZones &&
        this.hasLoadedShipTypes
      );
    },
    locode() {
      return configs.getLocode();
    },
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    vesselCall: {
      get() {
        return this.$store.state.vessel_calls.selected;
      },
      set(val) {
        this.$store.commit("vessel_calls/setSelected", val);
      },
    },
    aisShip: {
      get() {
        return this.$store.state.ais_ships.selected;
      },
      set(val) {
        this.$store.commit("ais_ships/setSelected", val);
      },
    },
  },
  mounted() {
    this.aisShip = null;
    this.vesselCall = null;

    this.initMap();
  },

  destroyed() {
    this.aisShip = null;
    this.vesselCall = null;
  },
  methods: {
    initMap() {
      let center = this.locode.coordinates;
      let bearing = this.locode.bearing;
      let zoom = this.locode.zoom;

      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        seamlessZoom: true,
        maxPitch: 0,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        //fpsOnInteracting: 0,
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        seamlessZoom: true,
        attribution: false,
        layers: [
          new maptalks.TileLayer(configs.getDefaultRasterCode(), {
            urlTemplate: configs.getDefaultRasterUrl(),
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO",
          }),
          new maptalks.VectorLayer(ZONES_LAYERNAME),
          new maptalks.VectorLayer(MOORING_ROPES_LAYERNAME),
          new maptalks.VectorLayer(MOORINGBITTS_LAYERNAME),
          new maptalks.VectorLayer(CELLS_LAYERNAME),
          new maptalks.VectorLayer(ZHS_LAYERNAME),
          new maptalks.VectorLayer(AISSHIPS_LAYERNAME),
          new maptalks.VectorLayer(VCSHIPS_LAYERNAME),
        ],
      }).on("click", () => {
        this.aisShip = null;
        this.vesselCall = null;
      });
      this.loadShipTypes();
    },
    loadShipTypes() {
      this.hasLoadedShipTypes = false;
      this.$store.dispatch("shiptypes/GET_SHIP_TYPES").then(() => {
        this.hasLoadedShipTypes = true;
      });
    },
  },
};
</script>

<style>
.map,
.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.map-container {
  position: relative;
}
</style>
