<template>
  <v-dialog v-model="isOpen" persistent max-width="500" v-if="!!item">
    <v-card>
      <v-card-title>{{$t('vessel_call.eliminate_vessel_call')}}</v-card-title>
      <v-card-text v-html='$t("vessel_call.delete_confirmation", {
            process_number: item.process_number,
          })'>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed text @click="remove"> {{ $t("global.yes") }} </v-btn>
        <v-btn depressed text @click="cancel"> {{ $t("global.no") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open", "item"],
  data() {
    return {};
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  methods: {
    cancel() {
      this.isOpen = false;
    },
    remove() {
      this.$emit('remove', this.item);
      this.isOpen = false;
    },
  },
};
</script>
