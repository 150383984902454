<template>
  <v-btn-toggle>
    <ResetView :map="this.map" />

    <Plans />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          class="py-5"
          @click="showAll = !showAll"
          v-bind="attrs"
          v-on="on"
          :color="showAll ? 'primary' : 'white'"
        >
          <v-icon :color="showAll ? 'white' : 'primary'">mdi-tools</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.toolbar") }} </span>
    </v-tooltip>

    <LayersDrawer :map="this.map" v-show="showAll" :appear="showAll" />

    <Timeline v-if="showAll" />

    <GanttView v-if="showAll" />

    <Bathymetry :map="this.map" v-if="showAll" />

    <MeasuringTools :map="this.map" v-if="showAll" />

    <SwitchTileLayer :map.sync="this.map" v-if="showAll" />
  </v-btn-toggle>
</template>
<script>
import ResetView from "@/components/Tools/ResetView";
import LayersDrawer from "@/components/Tools/LayersDrawer";
import Plans from "@/components/Tools/Plans";
import Timeline from "@/components/Tools/Timeline";
import Bathymetry from "@/components/Tools/Bathymetry";
import GanttView from "@/components/Tools/GanttView";
import MeasuringTools from "@/components/Tools/MeasuringTools";
import SwitchTileLayer from "@/components/Tools/SwitchTileLayer";

export default {
  name: "MapToolBar",
  components: {
    ResetView,
    LayersDrawer,
    Plans,
    Timeline,
    Bathymetry,
    GanttView,
    MeasuringTools,
    SwitchTileLayer,
  },
  props: ["map"],
  data() {
    return {
      showAll: false,
    };
  },
};
</script>
