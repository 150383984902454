<template>
  <div class="map-container">
    <div ref="map" class="map map-simulation" />

    <div style="position: absolute; top: 20px; left: 20px">
      <MapToolBar
        v-if="canLoadShips"
        :map="this.map"
        :draggable.sync="draggable"
        :annotations.sync="annotationsList"
        :records.sync="records"
        :performedActions.sync="performedActions"
        :snapshot.sync="snapshotEnabled"
        @undo="handleUndoAction"
        :archived="archived"
      ></MapToolBar>
    </div>

    <VesselCallShips
      :refMap.sync="map"
      :mooringBittsManager.sync="mooringBittsManager"
      :zonesManager="zonesManager"
      :item.sync="vesselCall"
      v-if="canLoadShips"
      :draggable="
        draggable && hasUserPermissionToViewEditOrManage('VESSEL_CALLS')
      "
      :records.sync="records"
      :performedActions.sync="performedActions"
      :showBuffer="true"
      :archived="archived"
    />

    <MooringBitts
      :refMap.sync="map"
      v-if="map"
      :mooringBittsManager.sync="mooringBittsManager"
      @loaded="hasLoadedMooringBitts = true"
    />

    <Zones
      :refMap.sync="map"
      v-if="map"
      :zonesManager.sync="zonesManager"
      @loaded="hasLoadedZones = true"
      :records.sync="records"
    />
  </div>
</template>

<script>
import configs from "@/helpers/configs";

//Access vue instance to reach global constants
import * as maptalks from "maptalks";

import MapToolBar from "@/components/Simulator/MapToolBar";

import VesselCallShips from "@/components/Map/VesselCallShips";
import MooringBitts from "@/components/Map/MooringBitts";
import Zones from "@/components/Map/Zones";
import MooringBittsManager from "@/helpers/map/mooringbittsmanager";
import ZonesManager from "@/helpers/map/zonesmanager";

const AISSHIPS_LAYERNAME = "ais_ships";
const VCSHIPS_LAYERNAME = "vc_ships";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";
const ZONES_LAYERNAME = "zones";
const CELLS_LAYERNAME = "cells";
const ZHS_LAYERNAME = "zhs";

export default {
  name: "MapSimulator",
  props: [
    "refMap",
    "disableEditor",
    "item",
    "records",
    "annotations",
    "snapshot",
    "name",
    "archived",
  ],
  components: {
    MapToolBar,
    VesselCallShips,
    MooringBitts,
    Zones,
  },
  data() {
    return {
      formatting: [],
      map: null,
      crop: null,
      hasLoadedZones: false,
      hasLoadedMooringBitts: false,
      mooringBittsManager: new MooringBittsManager(),
      zonesManager: new ZonesManager(),
      draggable: true,
      performedActions: [],
      componentAnnotationsKey: 0,
    };
  },
  computed: {
    snapshotEnabled: {
      get: function () {
        return this.snapshot;
      },
      set: function (newValue) {
        this.$emit("update:snapshot", newValue);
      },
    },
    loadingPlan() {
      return this.$store.state.plans.loading;
    },
    canLoadShips() {
      return this.map && this.hasLoadedMooringBitts && this.hasLoadedZones;
    },
    locode() {
      return configs.getLocode();
    },
    vesselCall: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    annotationsList: {
      get() {
        return this.annotations;
      },
      set(val) {
        this.$emit("update:annotations", val);
      },
    },
  },
  mounted() {
    if (this.archived) this.draggable = false;
    this.initMap();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this._keyListener);
  },
  methods: {
    initMap() {
      let center = this.locode.coordinates;
      let bearing = this.locode.bearing;
      let zoom = this.locode.zoom;

      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        seamlessZoom: true,
        maxPitch: 0,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        //fpsOnInteracting: 0,
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        seamlessZoom: true,
        attribution: false,
        layers: [
          new maptalks.TileLayer(configs.getDefaultRasterCode(), {
            urlTemplate: configs.getDefaultRasterUrl(),
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO",
          }),
          new maptalks.VectorLayer(ZONES_LAYERNAME),
          new maptalks.VectorLayer(MOORING_ROPES_LAYERNAME),
          new maptalks.VectorLayer(CELLS_LAYERNAME),
          new maptalks.VectorLayer(ZHS_LAYERNAME),
          new maptalks.VectorLayer(MOORINGBITTS_LAYERNAME),
          new maptalks.VectorLayer(AISSHIPS_LAYERNAME),
          new maptalks.VectorLayer(VCSHIPS_LAYERNAME),
        ],
      });

      this.map.on("click", this.handleMapClick);
      this.map.on("contextmenu", this.handleMapContextClick);
    },
    handleMapClick(e) {
      //identify mooring bitts so that we can change
      this.map.identify(
        {
          coordinate: e.coordinate,
          layers: [this.map.getLayer(MOORINGBITTS_LAYERNAME)],
        },
        (geos) => {
          if (geos.toString().length === 0) {
            //When the user clicks out of a ship, deselect the vessel call (if any)
            this.vesselCall = null;
            return;
          }

          if (
            this.hasUserPermissionToViewEditOrManage("PLANS") &&
            this.draggable
          ) {
            if (!!this.vesselCall?._drawnObject) {
              this.vesselCall._drawnObject.previousSpecification = JSON.parse(
                JSON.stringify(this.vesselCall._drawnObject.shipSpecification)
              );

              this.vesselCall._drawnObject.fire("position_updating", {
                target: this.vesselCall._drawnObject,
              });
            }

            this.setVesselCallBowMooringBitt(geos.pop());
          }
        }
      );
    },
    handleMapContextClick(e) {
      //identify mooring bitts so that we can change
      this.map.identify(
        {
          coordinate: e.coordinate,
          layers: [this.map.getLayer(MOORINGBITTS_LAYERNAME)],
        },
        (geos) => {
          if (geos.toString().length === 0) {
            //When the user clicks out of a ship, deselect the vessel call (if any)
            this.vesselCall = null;
            return;
          }

          if (
            this.hasUserPermissionToViewEditOrManage("PLANS") &&
            this.draggable
          ) {
            if (!!this.vesselCall?._drawnObject) {
              this.vesselCall._drawnObject.previousSpecification = JSON.parse(
                JSON.stringify(this.vesselCall._drawnObject.shipSpecification)
              );

              this.vesselCall._drawnObject.fire("position_updating", {
                target: this.vesselCall._drawnObject,
              });
            }

            this.setVesselCallSternMooringBitt(geos.pop());
          }
        }
      );
    },

    handleUndoAction(action) {
      if (this.records) {
        let record = this.records.find((r) => r.id == action.id);
        if (record && record._drawnObject) {
          //restore previous specification
          record._drawnObject.restoreFromShipSpec(action.shipSpecification);
        }
      }
    },

    setVesselCallBowMooringBitt(bitt) {
      if (this.vesselCall && bitt) {
        this.vesselCall.end_mooring_bow = bitt.properties.code;
        this.$emit("updateMooringBitts", this.vesselCall);
      }
    },

    setVesselCallSternMooringBitt(bitt) {
      if (this.vesselCall && bitt) {
        this.vesselCall.end_mooring_stern = bitt.properties.code;
        this.$emit("updateMooringBitts", this.vesselCall);
      }
    },
  },
};
</script>

<style>
.map,
.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.map-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
