<template>
  <v-data-table
    :loading="loading"
    :page.sync="page"
    :headers="headers"
    :items="items"
    :items-per-page="10"
    item-key="id"
    dense
    fixed-header
    sort-by="number"
    sort-desc
    must-sort
    class="version_list"
    show-expand
    single-expand
  >
    <template v-slot:item.number="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <span
            class="ma-0 pa-0 font-weight-black blue--text text-uppercase text--darken-4"
            style="cursor: pointer"
            @click.stop="edit_version(item)"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.number }}
          </span>
        </template>
        <span>{{ $t("plan.open_version") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | formatDatetime }}
    </template>
    <!-- Expand Buttons -->
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <v-icon
        v-if="!!item.notes && !isExpanded"
        color="black"
        @click="expand(true)"
        >mdi-chat-processing-outline</v-icon
      >
      <v-icon
        v-if="!!item.notes && isExpanded"
        color="primary"
        @click="expand(false)"
        >mdi-chat-processing-outline</v-icon
      >
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="pt-1 pb-1">
          <table>
            <tr v-if="item.notes">
              <th class="text-left font-weight-black">
                {{ $t("plan.notes") }}:
              </th>
              <td class="pl-5">{{ item.notes | toUpperCase }}</td>
            </tr>
          </table>
        </div>
      </td>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            dark
            color="green darken-4"
            @click.stop="xlsx_version(item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-microsoft-excel
          </v-icon>
        </template>

        <span>{{ $t("plan.export_to_excel") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.actions2="{ item }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="hasUserPermissionToManage('PLAN_VERSIONS')"
            dark
            color="red"
            @click.stop="remove_version(item)"
            v-bind="attrs"
            v-on="on"
            :disabled="item.id == plan.last_version.id || archived"
            >mdi-delete-outline</v-icon
          >
        </template>
        <span>{{ $t("plan.delete_version") }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { Settings, DateTime } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

export default {
  props: ["item", "open"],
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat("dd/LL/yyyy", {
        locale: configs.getLocale(),
      });
    },
    getVersions() {
      this.loading = true;
      this.$store
        .dispatch("plans/GET_VERSIONS", this.plan.id)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.dialogOpen = false;
        });
    },
    edit_version(item) {
      this.$emit("edit_version", item);
    },
    remove_version(item) {
      this.$emit("remove_version", item);
    },
    xlsx_version(item) {
      this.$emit("xlsx_version", item);
    },
  },
  filters: {
    toUpperCase: function (value) {
      return value.toUpperCase();
    },
  },
  data() {
    return {
      page: 1,
      loading: false,
      headers: [
        {
          text: this.$t("version.number"),
          value: "number",
        },
        {
          text: this.$t("version.start_date"),
          value: "start_date",
        },
        {
          text: this.$t("version.end_date"),
          value: "end_date",
        },
        {
          text: this.$t("version.updated_at"),
          value: "updated_at",
        },
        {
          text: this.$t("version.created_by"),
          value: "creator_name",
        },
        {
          text: "",
          value: "data-table-expand",
          align: "center",
          groupable: false,
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "actions",
          groupable: false,
          sortable: false,
          width: "24px",
          class: "aqui",
        },
        {
          text: "",
          align: "center",
          value: "actions2",
          groupable: false,
          sortable: false,
          width: "24px",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.loading
        ? []
        : this.$store.state.plans.versionsList.map((version) => {
            return {
              ...version,
              scheduler: version.data,
              start_date: this.formatDate(version.start_date),
              end_date: this.formatDate(version.end_date),
            };
          });
    },
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
    plan: {
      // getter
      get: function () {
        return this.item;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:item", newValue);
      },
    },
    archived() {
      return this.plan.archived;
    },
  },
  mounted() {
    this.getVersions();
  },
  watch: {
    plan(item) {
      if (item) {
        this.getVersions();
      }
    },
  },
};
</script>
<style>
.version_list tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: rgb(245, 242, 223);
}
</style>
