<template>
  <v-dialog v-model="isOpen" v-if="!!vesselCall" persistent width="80%" scrollable>
    <VesselCallDialog :item="vesselCall" key="componentKey" :isEdit="true" :disabledInfo="disabledCoords" :stayRange="stayRange" :availableRange="availableRangeDate" @shipSelected="shipSelected" @updateCoords="updateCoords" @close="close" @save="save" />
  </v-dialog>
</template>

<script>
  import { Settings, DateTime } from "luxon";
  import configs from "@/helpers/configs";
  import VesselCallDialog from "@/components/Simulator/VesselCallDialog";
  Settings.defaultZone = configs.getTimezone();
  Settings.defaultLocale = configs.getLocale();

  export default {
    props: ["open", "item", "availableRange"],
    data() {
      return {
        vesselCall: null,
        componentKey: 0,
        disabledCoords: false,
      };
    },
    components: {
      VesselCallDialog,
    },
    computed: {
      isOpen: {
        get() {
          return this.open;
        },
        set(val) {
          this.$emit("update:open", val);
        },
      },
      stayRange() {
        return {
          start: DateTime.fromISO(this.item.eta).toJSDate().toISOString(),
          end: DateTime.fromISO(this.item.etd).toJSDate().toISOString(),
        };
      },
      availableRangeDate() {
        return this.availableRange;
      },
    },
    watch: {
      open() {
        if (this.isOpen) {
          if (this.item.end_mooring_bow || this.item.end_mooring_stern) {
            this.item.hdg = "";
            this.item.lat = "";
            this.item.lon = "";
            this.disabledCoords = true;
          } else {
            this.item.hdg = typeof this.item.hdg === "number" && !Number.isNaN(this.item.hdg) ? parseFloat(this.item.hdg).toFixed(2) : this.item.hdg;

            this.item.lat = this.item.lat ? parseFloat(this.item.lat).toFixed(7) : this.item.lat;

            this.item.lon = this.item.lon ? parseFloat(this.item.lon).toFixed(7) : this.item.lon;

            this.disabledCoords = false;
          }

          this.vesselCall = {
            ...this.item,
            buffer_stern: this.item.buffer_stern || 0,
            buffer_bow: this.item.buffer_bow || 0,
            buffer_starboard: this.item.buffer_starboard || 0,
            buffer_portside: this.item.buffer_portside || 0,
            stay: {
              start: DateTime.fromISO(this.item.eta).toJSDate(),
              end: DateTime.fromISO(this.item.etd).toJSDate(),
            },
          };
        }
      },
    },
    methods: {
      updateCoords() {
        if (this.vesselCall.end_mooring_bow || this.vesselCall.end_mooring_stern) {
          this.vesselCall.hdg = "";
          this.vesselCall.lat = "";
          this.vesselCall.lon = "";
          this.disabledCoords = true;
        } else {
          this.disabledCoords = false;
        }
      },
      nameAndCodeFilter(item, queryText) {
        return (item.code && item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) || (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())) > -1;
      },
      shipSelected(val) {
        if (val) {
          this.vesselCall.name = val.name;
          this.vesselCall.imo = val.imo;
          this.vesselCall.beam = val.breadth_moulded;
          this.vesselCall.loa = val.loa;
          this.vesselCall.entry_bow_draught = val.maximum_draught;
          this.vesselCall.exit_bow_draught = val.maximum_draught;
          this.vesselCall.entry_stern_draught = val.maximum_draught;
          this.vesselCall.exit_stern_draught = val.maximum_draught;
        } else {
          //DEFAULT
          this.vesselCall = {
            ...this.item,
            stay: {
              start: DateTime.fromISO(this.item.eta).toJSDate(),
              end: DateTime.fromISO(this.item.etd).toJSDate(),
            },
          };
        }

        this.forceUpdate();
      },

      close() {
        this.isOpen = false;
        this.vesselCall = null;
      },
      save() {
        this.vesselCall.eta = this.vesselCall.stay.start;
        this.vesselCall.etd = this.vesselCall.stay.end;

        //reset lat / lon database to recalc position
        if (this.vesselCall.upcoming_berth_location != this.vesselCall.berth_location) {
          this.vesselCall.lat = null;
          this.vesselCall.lon = null;
          this.vesselCall.upcoming_berth_location = this.vesselCall.berth_location;
        }

        this.vesselCall.updated_at = new Date();
        let updatedVesselCall = JSON.parse(JSON.stringify(this.vesselCall));
        this.$emit("updated", updatedVesselCall);
        this.close();
      },
      forceUpdate() {
        this.componentKey += 1;
      },
    },
  };
</script>
