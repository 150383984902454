<template>
  <fragment>
    <!-- Button to toggle the sheet -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="vesselCalls.length > 0"
          @click="toggle"
          small
          class="py-5"
          :color="sheet ? 'primary' : 'white'"
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :color="sheet ? 'white' : 'primary'">mdi-chart-gantt</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.ganttView") }} </span>
    </v-tooltip>

    <!-- Dialog containing the Gantt chart -->
    <v-dialog
      v-model="sheet"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :key="componentKey"
    >
      <v-card tile>
        <!-- MapToolBar -->
        <v-toolbar dark flat dense color="blue darken-4">
          <v-toolbar-title>
            {{ $t("global.ganttView") }} :: {{ locode.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <!-- Buttons for actions like copy and print -->
          <v-btn
            depressed
            icon
            dark
            dense
            @click="copy"
            :loading="copying"
            class="mr-2"
            :title="$t('gantt_view.copy')"
            v-if="groupsGanttSelected.length > 0"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn
            depressed
            icon
            dark
            dense
            @click="printer"
            :loading="printing"
            class="mr-2"
            :title="$t('gantt_view.print')"
            v-if="groupsGanttSelected.length > 0"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <!-- Button to close the sheet -->
          <v-btn depressed icon dark dense @click="toggle">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Gantt chart content -->
        <v-card-text class="ma-0 pa-0">
          <div class="pa-5">
            <div
              style="position: relative; height: calc(100vh - 100px)"
              v-if="loading"
            >
              <Loading
                :value="loading"
                custom-message="A carregar dados"
              ></Loading>
            </div>

            <!-- Select for filtering -->
            <v-select
              v-if="!loading"
              v-model="groupsGanttSelected"
              label="Terminais e Cais"
              class="mt-5"
              :items="mooring_bitt_groups"
              multiple
              outlined
              clearable
              chips
              deletable-chips
              hide-details
              small-chips
              cache-items
            ></v-select>

            <!-- Gantt chart -->
            <div
              v-show="!loading"
              ref="gantt"
              class="mt-5 gantt-container"
              id="gantt"
              :class="[{ 'sg-hidden': groupsGanttSelected.length == 0 }]"
            ></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Gantt chart for printing -->
    <div
      ref="ganttPrinter"
      class="gantt-printer-container"
      id="ganttPrinter"
      :class="[{ 'sg-hidden': groupsGanttSelected.length == 0 }]"
    >
      <img
        src="@/assets/watermark.png"
        style="position: absolute; z-index: 1001"
      />
    </div>
  </fragment>
</template>

<script>
import configs from "@/helpers/configs";
import Loading from "@/components/Main/Loading";
import {
  SvelteGantt,
  SvelteGanttTable,
  SvelteGanttDependencies,
  MomentSvelteGanttDateAdapter,
} from "svelte-gantt";
import "moment/locale/pt-br";
import moment from "moment";
import { DateTime } from "luxon";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const STATUSES = configs.getVesselCallStatuses();

export default {
  components: {
    Loading,
  },
  data: () => ({
    records: [],
    loading: false,
    componentKey: 0,
    sheet: false,
    gantt: null,
    printing: false,
    copying: false,
    groupsGanttSelected: [],
    arrowLeft:
      '<div style="position: absolute; left: 4px; margin-top: 2px; margin-right: 30px;"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m16 20-8-8 8-8" stroke="#656567" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path></g></svg></div>',
    arrowRight:
      '<div style="position: absolute;right: 0px;margin-top: -35px;""><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m8 20 8-8-8-8" stroke="#656567" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path></g></svg></div>',
  }),
  computed: {
    locode() {
      return configs.getLocode();
    },

    vesselCalls() {
      return this.$store.state.vessel_calls.all;
    },

    mooring_bitt_groups() {
      return this.records
        .map((vesselCall) => {
          return vesselCall.mooring_bitt_group
            ? vesselCall.mooring_bitt_group.name
            : "N/D";
        })
        .sort();
    },
    resultsFiltered() {
      const currentStart = moment();
      const startDate = currentStart.clone().startOf("day");
      const endDate = currentStart
        .clone()
        .startOf("day")
        .add(8, "d")
        .endOf("day");

      return this.records
        .filter((vesselCall) => {
          //filter 7 days
          return (
            DateTime.fromISO(vesselCall.eta) <= endDate &&
            startDate <= DateTime.fromISO(vesselCall.etd)
          );
        })
        .map((vesselCall) => {
          //hack to display label of vessel call with long stay in chart
          vesselCall.from =
            DateTime.fromISO(vesselCall.eta) < startDate
              ? startDate
              : moment(vesselCall.eta);
          vesselCall.to =
            DateTime.fromISO(vesselCall.etd) > endDate
              ? endDate
              : moment(vesselCall.etd);
          vesselCall.arrowLeft =
            DateTime.fromISO(vesselCall.eta) < startDate ? this.arrowLeft : "";
          vesselCall.arrowRight =
            DateTime.fromISO(vesselCall.etd) > endDate ? this.arrowRight : "";
          //
          vesselCall.mooring_bitt_group = vesselCall.mooring_bitt_group
            ? vesselCall.mooring_bitt_group
            : { name: "N/D" };

          return vesselCall;
        })
        .filter((vesselCall) => {
          //filter groups filters
          return this.groupsGanttSelected.includes(
            vesselCall.mooring_bitt_group.name
          );
        })
        .sort();
    },

    //rows = berth location
    rows() {
      let locations = [];

      this.resultsFiltered.forEach((element) => {
        let id = this.getCharCodes(element.mooring_bitt_group.name);

        if (locations.some((x) => x.id === id) == false) {
          locations.push({
            id: id,
            class: "row-group",
            children: [],
            name: element.mooring_bitt_group
              ? element.mooring_bitt_group.name
              : "z", //force put n/d in last
            headerHtml:
              "<span class='berth_location'>" +
              element.mooring_bitt_group +
              "</span>",
          });
        }

        let childLabel =
          "<span class='process_number'><b>" +
          element.process_number +
          "</b></span>";
        childLabel +=
          "<span class='eta'><b>ETA:</b> " +
          this.$options.filters.formatDatetime(element.eta) +
          "</span>";
        childLabel +=
          "<span class='eta'><b>ETD:</b> " +
          this.$options.filters.formatDatetime(element.etd) +
          "</span>";
        childLabel +=
          "<span class='bitt'><b>Popa:</b> " +
          (element.end_mooring_stern || "N/D") +
          " <b>Proa:</b> " +
          (element.end_mooring_bow || "N/D") +
          "</span>";

        let location = locations.filter((x) => x.id === id)[0];
        location.children.push({
          id: element.process_number,
          headerHtml: childLabel,
        });
        location.headerHtml =
          element.mooring_bitt_group.name +
          " (" +
          location.children.length +
          ")";
      });

      return locations.slice().sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
      });
    },
    //task = vessel calls
    tasks() {
      return this.resultsFiltered.map((record) => {
        return {
          id: record.id,
          amountDone: 100,
          html:
            "<div style='width:100%'>" +
            record.arrowLeft +
            (record.arrowLeft != ""
              ? "<div style='margin-left: 30px'>"
              : "<div>") +
            "<span class='name'>" +
            record.name +
            "</span><br/><span class='meters'>" +
            record.loa +
            " m</span></div>" +
            record.arrowRight +
            "</div>",
          resourceId: record.process_number,
          from: record.from,
          to: record.to,
          enableDragging: false,
          showButton: false,
          classes: "vessel_call",
        };
      });
    },
    dependencies() {
      let dependencies = [];

      this.resultsFiltered.forEach((currentVesselCall, currentIndex) => {
        for (let i = 0; i < this.resultsFiltered.length; i++) {
          if (i !== currentIndex) {
            const otherVesselCall = this.resultsFiltered[i];

            if (this.areHeadsCollinear(currentVesselCall, otherVesselCall)) {
              dependencies.push({
                fromId: currentVesselCall.id,
                toId: otherVesselCall.id,
                stroke: "grey",
              });
            }
          }
        }
      });

      return dependencies;
    },
  },
  watch: {
    tasks(values) {
      if (this.gantt) {
        this.gantt.$set({ tasks: values });
      }
    },
    rows(values) {
      if (this.gantt) this.gantt.$set({ rows: values });
    },
    dependencies(values) {
      if (this.gantt) this.gantt.$set({ dependencies: values });
    },
    groupsGanttSelected(values) {
      localStorage.setItem(
        "groupsGanttSelected" + this.locode.code,
        JSON.stringify(values)
      );
    },
  },
  methods: {
    areHeadsCollinear(vessel1, vessel2) {
      const pattern = /[A-Za-z](\d+)/;

      const vessel1Heads = [vessel1.end_mooring_stern, vessel1.end_mooring_bow];
      const vessel2Heads = [vessel2.end_mooring_stern, vessel2.end_mooring_bow];

      const extractNumbers = (heads) =>
        heads.map((head) => {
          if (head === null || head === undefined) return null;
          const match = head.match(pattern);
          return match ? parseInt(match[1]) : null;
        });

      const vessel1HeadNumbers = extractNumbers(vessel1Heads);
      const vessel2HeadNumbers = extractNumbers(vessel2Heads);

      if (
        vessel1HeadNumbers.every(
          (head, index) => head && head === vessel2HeadNumbers[index] - 2
        ) ||
        vessel1HeadNumbers.every(
          (head, index) => head && head === vessel2HeadNumbers[index] + 2
        )
      ) {
        return true;
      }

      return false;
    },
    async toggle() {
      this.sheet = !this.sheet;
      if (this.sheet) {
        this.records = this.vesselCalls.map((vcall) => {
          return {
            ...vcall,
            visible:
              vcall.end_mooring_bow && vcall.end_mooring_stern ? true : false,
          };
        });

        this.loading = true;
        setTimeout(() => {
          this.loadGantt();
        }, 2000);
      } else {
        this.componentKey++;
      }
    },
    printer() {
      this.printing = true;
      setTimeout(() => {
        html2canvas(this.$refs.ganttPrinter, {
          allowTaint: false,
          logging: false,
          scrollY: -window.scrollY,
          scrollX: 0,
          letterRendering: true,
          scale: 2,
        }).then((canvas) => {
          const imgWidth = 297;
          const pageHeight = 208;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;
          heightLeft -= pageHeight;
          const doc = new jsPDF("l", "mm");
          doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "");
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "");
            heightLeft -= pageHeight;
          }
          doc.save(this.$t("global.gantt") + " - " + this.locode.code + ".pdf");
          this.printing = false;
        });
      }, 500);
    },
    copy() {
      this.copying = true;
      setTimeout(() => {
        html2canvas(this.$refs.ganttPrinter, {
          allowTaint: false,
          logging: false,
          scrollY: -window.scrollY,
          scrollX: 0,
          letterRendering: true,
          scale: 2,
        }).then((canvas) => {
          canvas.toBlob((blob) => {
            navigator.clipboard.write([
              new window.ClipboardItem({ "image/png": blob }),
            ]);
            this.copying = false;
          });
        });
      }, 500);
    },
    async loadGantt() {
      if ("groupsGanttSelected" + this.locode.code in localStorage) {
        let groupsGanttStorage = JSON.parse(
          localStorage.getItem("groupsGanttSelected" + this.locode.code)
        );

        if (groupsGanttStorage && groupsGanttStorage.length > 0) {
          this.groupsGanttSelected = JSON.parse(
            localStorage.getItem("groupsGanttSelected" + this.locode.code)
          );
        } else {
          this.records.forEach((record) => {
            let groupName = record.mooring_bitt_group
              ? record.mooring_bitt_group.name
              : "N/D";
            if (!this.groupsGanttSelected.includes(groupName))
              this.groupsGanttSelected.push(groupName);
          });
        }
      } else if (this.records.length > 0) {
        this.records.forEach((record) => {
          let groupName = record.mooring_bitt_group
            ? record.mooring_bitt_group.name
            : "N/D";
          if (!this.groupsGanttSelected.includes(groupName))
            this.groupsGanttSelected.push(groupName);
        });
      }
      this.groupsGanttSelected = [...new Set(this.groupsGanttSelected)].sort();

      const currentStart = moment();
      let options = {
        enabled: false,
        dateAdapter: new MomentSvelteGanttDateAdapter(moment),
        rows: this.rows,
        tasks: this.tasks,
        rowHeight: 70,
        fitWidth: true,
        columnUnit: "hour",
        columnOffset: 1,
        reflectOnParentRows: false,
        from: currentStart.clone().startOf("day"),
        to: currentStart.clone().startOf("day").add(6, "d").endOf("day"),
        headers: [
          { unit: "day", format: "DD/MM", offset: 1 },
          { unit: "hour", format: "HH", offset: 6 },
        ],
        tableHeaders: [
          { title: "", property: "label", width: 130, type: "tree" },
        ],
        tableWidth: 240,
        ganttTableModules: [SvelteGanttTable],
        ganttBodyModules: [SvelteGanttDependencies],
        dependencies: this.dependencies,
      };

      //gantt chart view
      this.gantt = new SvelteGantt({
        target: this.$refs.gantt,
        props: options,
      });

      this.gantt.api.gantt.on.dateSelected(() => {
        this.gantt.$set({
          from: currentStart.clone().startOf("day"),
          to: currentStart.clone().startOf("day").add(6, "d").endOf("day"),
        });
      });

      this.loading = false;

      //gant chart print view
      this.ganttPrinter = new SvelteGantt({
        target: this.$refs.ganttPrinter,
        props: options,
      });
      this.ganttPrinter.api.gantt.on.dateSelected(() => {
        this.ganttPrinter.$set({
          from: currentStart.clone().startOf("day"),
          to: currentStart.clone().startOf("day").add(6, "d").endOf("day"),
        });
      });

      document.body.appendChild(this.$refs.ganttPrinter);
    },
    getCharCodes(string) {
      let charCodeArr = [];
      for (let i = 0; i < string.length; i++) {
        let code = string.charCodeAt(i);
        charCodeArr.push(code);
      }
      return charCodeArr.join("");
    },
    getColor: function (code) {
      if (!code) return "";
      return STATUSES.find(function (data) {
        return data.code == code;
      }).color;
    },
    getStatus: function (code) {
      if (!code) return "";
      return STATUSES.find(function (data) {
        return data.code == code;
      }).text;
    },
    getCurrentTime() {
      const currentStart = moment();
      return [
        {
          id: 0,
          from: currentStart.clone().startOf("minute"),
          to: currentStart.clone().add(60, "minutes"),
          classes: null,
          label: "",
        },
      ];
    },
  },
};
</script>
<style>
#gantt {
  flex-grow: 1;
  overflow: auto;
  height: calc(100vh - 200px);
}

.vessel_call {
  background-color: #ccc !important;
  color: black !important;
  font-weight: bold !important;
  opacity: 0.8 !important;
  max-width: 100%;
}

.sg-time-range {
  background: rgb(226, 189, 191) !important;
}

.sg-time-range-label {
  background: transparent !important;
}

.sg-time-range-handle-right,
.sg-time-range-handle-left {
  display: none;
}

.sg-row-expanded .sg-cell-inner {
  display: grid !important;
  width: 200px !important;
  padding-left: 2em;
}

.berth_location {
  padding-left: 1em !important;
}

.sg-cell-inner span {
  width: 100%;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  line-height: 14px;
  letter-spacing: 0.0071428571em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.sg-task-content {
  width: 100%;
}

.sg-hidden,
.sg-resize,
.sg-task.dragging-enabled:hover::before,
.sg-task.dragging-enabled:hover::after {
  visibility: hidden;
}

.gantt-container {
  width: auto;
  max-width: 100%;
  height: calc(100% - 100px);
  min-height: 500px;
  border: 1px solid #e0e0e0;
}

.gantt-printer-container {
  position: absolute;
  z-index: -1000;
  width: 297mm;
}
</style>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
