<template>
  <v-row>
    <v-col cols="12" sm="12" class="pa-1 pt-3">
      <div class="blue-grey lighten-5 text-no-wrap rounded">
        <v-slider
          :value="sliderVal"
          track-color="grey"
          always-dirty
          :max="max"
          :min="min"
          hide-details
          @end="setSliderValue"
        >
          <template v-slot:prepend>
            <v-btn
              @click.stop="togglePlayPause"
              :color="isPlaying ? 'warning' : 'primary'"
              class="mx-2"
              fab
              dark
              small
              depressed
              :title="isPlaying ? $t('global.stop') : $t('global.start')"
            >
              <v-icon> {{ isPlaying ? "mdi-pause" : "mdi-play" }}</v-icon>
            </v-btn>

            <v-btn
              @click="decrease(1440)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.rollback_24h')"
              depressed
            >
              <v-icon> mdi-rewind </v-icon>
            </v-btn>

            <v-btn
              depressed
              @click="decrease(30)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.rollback_30m')"
            >
              <v-icon> mdi-rewind-30 </v-icon>
            </v-btn>

            <v-btn
              depressed
              @click="decrease(15)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.rollback_15m')"
            >
              <v-icon> mdi-rewind-15 </v-icon>
            </v-btn>

            <v-btn
              depressed
              @click="decrease(5)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.rollback_5m')"
            >
              <v-icon> mdi-rewind-5 </v-icon>
            </v-btn>
          </template>

          <template v-slot:append>
            <v-btn
              depressed
              @click="increase(5)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.forward_5m')"
            >
              <v-icon> mdi-fast-forward-5 </v-icon>
            </v-btn>

            <v-btn
              depressed
              @click="increase(15)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.forward_15m')"
            >
              <v-icon> mdi-fast-forward-15 </v-icon>
            </v-btn>

            <v-btn
              depressed
              @click="increase(30)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.forward_30m')"
            >
              <v-icon> mdi-fast-forward-30 </v-icon>
            </v-btn>

            <v-btn
              depressed
              @click="increase(1440)"
              class="mx-2"
              elevation="0"
              icon
              :title="$t('global.forward_24h')"
            >
              <v-icon> mdi-fast-forward </v-icon>
            </v-btn>
          </template>
        </v-slider>
      </div>
    </v-col>
  </v-row>
</template>
<script>
const DEFAULT_INCREMENT = "minutes";
import debounce from "lodash/debounce";
import { DateTime, Interval, Settings } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();
export default {
  props: ["startDate", "endDate", "duration", "increment", "value"],
  data() {
    return {
      isPlaying: false,
      val: 0,
      min: 0,
      max: 0,
      playTimeout: null,
      events: null,
      checkbox: false,
    };
  },
  computed: {
    sliderIncrement() {
      return this.increment || DEFAULT_INCREMENT;
    },
    sliderVal: {
      get() {
        return this.val;
      },
      set(val) {
        let initialDate = DateTime.fromJSDate(this.startDate);
        this.val = val;
        this.selectedDate = initialDate.plus({
          [this.sliderIncrement]: val,
        });
        this.$emit("input", this.selectedDate);
      },
    },
  },
  watch: {
    startDate() {
      this.reboot();
    },
    endDate() {
      this.reboot();
    },
  },
  mounted() {
    this.reboot();
  },
  beforeDestroy() {
    this.reboot();
  },
  methods: {
    setSliderValue(value) {
      this.sliderVal = value;
    },
    getThumbLabel(value) {
      let sd = DateTime.fromJSDate(this.startDate);
      sd = sd.plus({
        [this.sliderIncrement]: value,
      });
      return sd.toFormat("dd/LL/yyyy HH:mm");
    },
    togglePlayPause() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.play();
      } else {
        this.stop();
      }
    },
    decrease: debounce(function (val) {
      this.sliderVal =
        this.sliderVal - val > this.min ? this.sliderVal - val : this.min;
    }, 250),

    increase: debounce(function (val) {
      this.sliderVal =
        this.sliderVal + val < this.max ? this.sliderVal + val : this.max;
    }, 250),

    play() {
      clearTimeout(this.playTimeout);
      this.sliderVal++;
      this.playTimeout = setTimeout(() => {
        if (this.sliderVal >= this.max) {
          this.stop();
        } else {
          this.play();
        }
      }, this.duration || 1500);
    },
    stop() {
      clearTimeout(this.playTimeout);
      this.isPlaying = false;
    },
    getRangeMax() {
      let sd = DateTime.fromJSDate(this.startDate);
      let ed = DateTime.fromJSDate(this.endDate);
      let interval = Interval.fromDateTimes(sd, ed);
      return interval.length(this.sliderIncrement);
    },
    reboot() {
      this.val = 0;
      this.min = 0;
      this.max = this.getRangeMax();
      this.sliderVal = this.max;
      this.isPlaying = false;
      clearTimeout(this.playTimeout);
    },
  },
};
</script>
<style scoped>
.v-slider--horizontal {
  margin-top: 10px;
}

.v-application .pt-3 {
  padding-top: 25px !important;
}
</style>
