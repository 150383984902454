<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="toggleAnnotations"
          small
          class="py-5"
          :color="isEnabled ? 'primary' : 'white'"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon :color="isEnabled ? 'white' : 'primary'"> mdi-draw </v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.annotations") }} </span>
    </v-tooltip>

    <v-snackbar top v-model="isEnabled" width="60%" :timeout="-1">
      <v-icon left> mdi-draw </v-icon>{{ $t("annotations.explanation") }}
      <template v-slot:action="{ attrs }">
        <v-btn small color="red" v-bind="attrs" @click="clearMeasures">
          <v-icon small left>mdi-eraser</v-icon>{{ $t("annotations.clear") }}
        </v-btn>
      </template>
    </v-snackbar>
  </fragment>
</template>

<script>
import {
  AreaTool,
  VectorLayer,
  Marker,
  Label,
  Geometry,
  Util,
  Size,
} from "maptalks";
import { v4 as uuidv4 } from "uuid";
import * as turf from "@turf/turf";

/**
 * Annotations Tool extension to support Vue.js translations based on the locale
 *
 */
class AnnotationsTool extends AreaTool {
  constructor(options) {
    super(options);
    this._allMeasurements = [];
  }

  // Override @fuzhenn msOnDrawStart method
  _msOnDrawStart(param) {
    let map = this.getMap();
    const prjCoord = map._pointToPrj(param["point2d"]);

    const uid = uuidv4().replaceAll("-", "");
    const layerId = "Annotations_" + uid;
    const markerLayerId = "Annotations_markers_" + uid;
    const lengthLayerId = "Annotations_length_" + uid;
    if (!map.getLayer(layerId)) {
      this._measureLineLayer = new VectorLayer(layerId).addTo(map);
      this._measureMarkerLayer = new VectorLayer(markerLayerId).addTo(map);
      this._measureLengthLayer = new VectorLayer(lengthLayerId).addTo(map);
    } else {
      this._measureLineLayer = map.getLayer(layerId);
      this._measureMarkerLayer = map.getLayer(markerLayerId);
      this._measureLengthLayer = map.getLayer(lengthLayerId);
    }
    this._measureLayers.push(this._measureLineLayer);
    this._measureLayers.push(this._measureMarkerLayer);
    this._measureLayers.push(this._measureLengthLayer);

    const marker = new Marker(param["coordinate"], {
      symbol: this.options["vertexSymbol"],
    });

    marker._setPrjCoordinates(prjCoord);
    let coords = param["coordinate"];
    //let content =
      "Lat: " + coords.y.toFixed(8) + "\nLng: " + coords.x.toFixed(8);

   
    const content = this.translator.translate('distancetool.start');
        const startLabel = new Label(content, param['coordinate'], this.options['labelOptions']);
    startLabel._setPrjCoordinates(prjCoord);
    this._lastVertex = startLabel;
    this._addVertexMarker(marker, startLabel);
    this._allMeasurements.push(this._measureLayers);
  }

  _msOnMouseMove(param) {
    let coords = param["coordinate"];
    let content =
      "Lat: " + coords.y.toFixed(8) + "\nLng: " + coords.x.toFixed(8);
    if (!this._tailMarker) {
      const symbol = this.extendSymbol(this.options["vertexSymbol"]);
      symbol["markerWidth"] /= 2;
      symbol["markerHeight"] /= 2;
      this._tailMarker = new Marker(param["coordinate"], {
        symbol: symbol,
      }).addTo(this._measureMarkerLayer);
      this._tailLabel = new Label(
        content,
        param["coordinate"],
        this.options["labelOptions"]
      ).addTo(this._measureMarkerLayer);
    }
    const prjCoords = this._geometry._getPrjCoordinates();
    const lastCoord = prjCoords[prjCoords.length - 1];
    this._tailMarker.setCoordinates(param["coordinate"]);
    this._tailMarker._setPrjCoordinates(lastCoord);
    this._tailLabel.setContent(content);
    this._tailLabel.setCoordinates(param["coordinate"]);
    this._tailLabel._setPrjCoordinates(lastCoord);
  }

  _msOnDrawVertex(param) {
    const prjCoords = this._geometry._getPrjCoordinates();
    const lastCoord = prjCoords[prjCoords.length - 1];
    const geometry = param["geometry"];
    //vertex marker
    const marker = new Marker(param["coordinate"], {
      symbol: this.options["vertexSymbol"],
    });

    this.options["labelOptions"].textSymbol = {
      ...this.options["labelOptions"].textSymbol,
      textName: "XPTO",
    };

    const coords = this._measure(geometry);
    const vertexLabel = new Label(
      coords,
      param["coordinate"],
      this.options["labelOptions"]
    );
    this._addVertexMarker(marker, vertexLabel);
    vertexLabel._setPrjCoordinates(lastCoord);
    marker._setPrjCoordinates(lastCoord);
    this._lastVertex = vertexLabel;

    if (prjCoords.length > 1) {
      const projection = this.getMap().getProjection();

      const startcoord = projection.unproject(prjCoords[prjCoords.length - 2]);
      const endcoord = projection.unproject(prjCoords[prjCoords.length - 1]);

      var startpoint = turf.point(startcoord.toArray());
      var endpoint = turf.point(endcoord.toArray());

      var labelCoords = turf.midpoint(startpoint, endpoint).geometry
        .coordinates;
      var distanceCoords = turf.distance(startpoint, endpoint) * 1000;

      const length = this._convertLength(distanceCoords);

      if (distanceCoords > 0) {
        let label = new Label(length, labelCoords, {
          textSymbol: {
            textFaceName: "Nunito",
            textFill: "black",
            textHaloFill: "#fff",
            textHaloRadius: 2,
            textSize: 10,
            textWeight: "bold",
          },
        });

        label.addTo(this._measureLengthLayer);
      }
    }
  }

  _measure(toMeasure) {
    let coords = toMeasure._coordinates[toMeasure._coordinates.length - 1];
    let content =
      "Lat: " + coords.y.toFixed(8) + "\nLng: " + coords.x.toFixed(8);
    return content;
  }

  _measureLength(toMeasure) {
    let map = this.getMap();
    let length;
    if (toMeasure instanceof Geometry) {
      length = map.computeGeometryLength(toMeasure);
    } else if (Array.isArray(toMeasure)) {
      length = map.getProjection().measureLength(toMeasure);
    }
    let distance = this._convertLength(length);
    return distance;
  }

  _convertLength(length) {
    let distance = "";
    let units;
    if (this.options["language"] === "zh-CN") {
      units = [" 米", " 公里", " 英尺", " 英里"];
    } else {
      units = [" m", " km", " feet", " mile"];
    }
    if (this.options["metric"]) {
      distance +=
        length < 1000
          ? length.toFixed(0) + units[0]
          : (length / 1000).toFixed(2) + units[1];
    }
    if (this.options["imperial"]) {
      length *= 3.2808399;
      if (distance.length > 0) {
        distance += "\n";
      }
      distance +=
        length < 5280
          ? length.toFixed(0) + units[2]
          : (length / 5280).toFixed(2) + units[3];
    }

    return distance;
  }

  _addClearMarker(coordinates, prjCoord, dx) {
    let symbol = this.options["clearButtonSymbol"];
    let dxSymbol = {
      markerDx: (symbol["markerDx"] || 0) + dx,
      textDx: (symbol["textDx"] || 0) + dx,
    };
    if (Array.isArray(symbol)) {
      dxSymbol = symbol.map((s) => {
        if (s) {
          return {
            markerDx: (s["markerDx"] || 0) + dx,
            textDx: (s["textDx"] || 0) + dx,
          };
        }
        return null;
      });
    }
    symbol = Util.extendSymbol(symbol, dxSymbol);
    const endMarker = new Marker(coordinates, {
      symbol: symbol,
    });
    const measureLineLayer = this._measureLineLayer,
      measureMarkerLayer = this._measureMarkerLayer,
      measureLengthLayer = this._measureLengthLayer;

    endMarker.on(
      "click",
      function () {
        measureLineLayer.remove();
        measureMarkerLayer.remove();
        measureLengthLayer.remove();
        let annotations = this.getAnnotations();
        this._fireEvent("annotations", annotations);
        //return false to stop propagation of event.
        return false;
      },
      this
    );
    endMarker.addTo(this._measureMarkerLayer);
    endMarker._setPrjCoordinates(prjCoord);
  }

  _msOnDrawEnd(param) {
    this._clearTailMarker();
    if (param["geometry"]._getPrjCoordinates().length < 2) {
      this._lastMeasure = 0;
      this._clearMeasureLayers();
      return;
    }
    let size = this._lastVertex.getSize();
    if (!size) {
      size = new Size(10, 10);
    }
    this._addClearMarker(
      this._lastVertex.getCoordinates(),
      this._lastVertex._getPrjCoordinates(),
      size["width"]
    );
    const geo = param["geometry"].copy();
    geo._setPrjCoordinates(param["geometry"]._getPrjCoordinates());
    geo.addTo(this._measureLineLayer);

    const prjCoords = this._geometry._getPrjCoordinates();

    if (prjCoords.length > 1) {
      const projection = this.getMap().getProjection();

      const startcoord = projection.unproject(prjCoords[0]);
      const endcoord = projection.unproject(prjCoords[prjCoords.length - 1]);

      var startpoint = turf.point(startcoord.toArray());
      var endpoint = turf.point(endcoord.toArray());

      var labelCoords = turf.midpoint(startpoint, endpoint).geometry
        .coordinates;
      var distanceCoords = turf.distance(startpoint, endpoint) * 1000;

      const length = this._convertLength(distanceCoords);

      let label = new Label(length, labelCoords, {
        textSymbol: {
          textFaceName: "Nunito",
          textFill: "black",
          textHaloFill: "#fff",
          textHaloRadius: 2,
          textSize: 10,
          textWeight: "bold",
        },
      });

      label.addTo(this._measureLengthLayer);

      let annotations = this.getAnnotations();
      this._fireEvent("annotations", annotations);
    }
  }

  /**
   * override handle mouse first click handle
   * @param event
   * @private
   */
  _clickHandler(event) {
    let map = this.getMap();
    const registerMode = this._getRegisterMode();
    // const coordinate = event['coordinate'];
    //dbclick will trigger two click
    if (this._clickCoords && this._clickCoords.length) {
      const len = this._clickCoords.length;
      const prjCoord = map._pointToPrj(event["point2d"]);
      if (this._clickCoords[len - 1].equals(prjCoord)) {
        return;
      }
    }

    let stop = false;
    let layers = [];
    let mapLayers = map.getLayers();

    if (mapLayers && mapLayers.length > 0)
      mapLayers.forEach((layer) => {
        if (layer._id.includes("PreviousAnnotations_markers_"))
          layers.push(layer);
      });

    //if click in annotations layer marker (close) remove all friends with same layerId
    map.identify(
      {
        coordinate: event.coordinate,
        layers: layers,
      },
      (geos) => {
        if (geos) {
          geos.forEach((geo) => {
            if (geo._layer._id.includes("PreviousAnnotations_markers_")) {
              let layerId = geo._layer._id.replace(
                "PreviousAnnotations_markers_",
                ""
              );
              map.getLayers().forEach((layer) => {
                if (layer._id.includes(layerId)) layer.clear();
              });
              stop = true;
            }
          });
          let annotations = this.getAnnotations();
          this._fireEvent("annotations", annotations);
          return false;
        }
      }
    );

    if (stop) return;

    if (!this._geometry) {
      this._createGeometry(event);
    } else {
      let prjCoord = map._pointToPrj(event["point2d"]);
      if (!this.isNil(this._historyPointer)) {
        this._clickCoords = this._clickCoords.slice(0, this._historyPointer);
      }
      //for snap effect
      const snapTo = this._geometry.snapTo;
      if (snapTo && this.isFunction(snapTo)) {
        const snapResult = this._getSnapResult(snapTo, event.containerPoint);
        prjCoord = snapResult.prjCoord;
        this._clickCoords = this._clickCoords.concat(snapResult.effectedVertex);
        // ensure snap won't trigger again when dblclick
        if (this._clickCoords[this._clickCoords.length - 1].equals(prjCoord)) {
          return;
        }
      }
      this._clickCoords.push(prjCoord);
      this._historyPointer = this._clickCoords.length;
      event.drawTool = this;
      registerMode["update"](
        map.getProjection(),
        this._clickCoords,
        this._geometry,
        event
      );
      if (this.getMode() === "point") {
        this.endDraw(event);
        return;
      }
      /**
       * drawvertex event.
       *
       * @event DrawTool#drawvertex
       * @type {Object}
       * @property {String} type - drawvertex
       * @property {DrawTool} target - draw tool
       * @property {Geometry} geometry - geometry drawn
       * @property {Coordinate} coordinate - coordinate of the event
       * @property {Point} containerPoint  - container point of the event
       * @property {Point} viewPoint       - view point of the event
       * @property {Event} domEvent                 - dom event
       */
      if (this._clickCoords.length <= 1) {
        this._fireEvent("drawstart", event);
      } else {
        this._fireEvent("drawvertex", event);
      }

      if (
        registerMode["clickLimit"] &&
        registerMode["clickLimit"] === this._historyPointer
      ) {
        // registerMode['update']([coordinate], this._geometry, event);
        this.endDraw(event);
      }
    }
  }

  //Get Annotations
  getAnnotations() {
    let annotations = [];
    annotations["data"] = [];
    let map = this.getMap();

    map.getLayers().forEach((l) => {
      if (l._id.includes("Annotations_"))
        l.getGeometries().forEach((geometry) => {
          annotations["data"].push({
            id: l._id.replace("Previous", ""),
            json: geometry.toJSON(),
          });
        });
    });

    return annotations;
  }

  clear() {
    if (this._allMeasurements) {
      this._allMeasurements.forEach((mgroup) => {
        if (mgroup) {
          mgroup.forEach((measurementLayer) => {
            if (measurementLayer) {
              measurementLayer.clear();
              measurementLayer.remove();
            }
          });
        }
      });
      this.endDraw();
    }
    delete this._lastMeasure;
    delete this._lastVertex;
    this._measureLayers = [];
    this._measureLengthLayer = null;
    this._measureLineLayer = null;
    this._measureMarkerLayer = null;
    this._allMeasurements = [];
    this._fireEvent("annotations", []);
    return this;
  }

  extendSymbol(symbol) {
    var sources = Array.prototype.slice.call(arguments, 1);

    if (!sources || !sources.length) {
      sources = [{}];
    }

    if (Array.isArray(symbol)) {
      var s, dest;
      var result = [];

      for (var i = 0, l = symbol.length; i < l; i++) {
        s = symbol[i];
        dest = {};

        for (var ii = 0, ll = sources.length; ii < ll; ii++) {
          if (!Array.isArray(sources[ii])) {
            this.extend(dest, s, sources[ii] ? sources[ii] : {});
          } else if (!this.isNil(sources[ii][i])) {
            this.extend(dest, s, sources[ii][i]);
          } else {
            this.extend(dest, s ? s : {});
          }
        }

        result.push(dest);
      }

      return result;
    } else {
      var args = [{}, symbol];
      args.push.apply(args, sources);
      return this.extend.apply(this, args);
    }
  }

  extend(dest) {
    for (var i = 1; i < arguments.length; i++) {
      var src = arguments[i];

      for (var k in src) {
        dest[k] = src[k];
      }
    }

    return dest;
  }

  isNil(obj) {
    return obj == null;
  }
}

export default {
  props: ["map", "disabled", "annotations"],
  data() {
    return {
      annotationTool: null,
    };
  },
  watch: {
    map(val) {
      if (val) {
        this.register();
      } else {
        this.unregister();
      }
    },
    isEnabled(val) {
      if (val) {
        this.annotationTool.enable();

        this.loadPreviousAnnotations();

        this.reorderZindexLayers();
      } else {
        this.annotationTool.disable();

        //hide previous annotations
        this.map.getLayers().forEach((layer) => {
          if (layer._id.includes("Annotations_"))
            this.map.getLayer(layer._id).hide();
        });
      }
    },
  },
  computed: {
    isEnabled: {
      get() {
        return this.$store.state.plans.annotationsEnabled;
      },
      set(val) {
        this.$store.dispatch("plans/SET_ANNOTATIONS_ENABLED", val);
      },
    },
    annotationsFeatures: {
      get() {
        return this.annotations;
      },
      set(val) {
        if (!val) val = [];
        this.$store.dispatch("screenshots/SET_ANNOTATIONS", val);
        this.$emit("update:annotations", []);
      },
    },
  },
  mounted() {
    this.register();
  },
  beforeDestroy() {
    this.unregister();
  },
  methods: {
    //load previous annotations saved in dab
    loadPreviousAnnotations() {
      this.map.getLayers().forEach((l) => {
        if (l._id.includes("Previous")) l.remove();
      });

      if (this.annotationsFeatures) {
        this.annotationsFeatures.forEach((annotation) => {
          if (!!annotation && !!annotation.id && !!annotation.json) {
            let previousAnnotationsLayer = null;
            let layerId = "Previous" + annotation.id.replace("Previous", ""); //purge previous tag

            if (!this.map.getLayer(layerId)) {
              previousAnnotationsLayer = new VectorLayer(layerId).addTo(
                this.map
              );
            } else {
              previousAnnotationsLayer = this.map.getLayer(layerId);
            }

            let annotationGeom = Geometry.fromJSON(annotation.json);
            annotationGeom.addTo(previousAnnotationsLayer);
            previousAnnotationsLayer.show();
          }
        });
      }
    },
    unregister() {
      if (this.annotationTool) {
        this.annotationTool.remove();
      }
    },
    register() {
      this.unregister();
      if (this.map) {
        this.annotationTool = new AnnotationsTool({
          symbol: {
            lineColor: "#F44336",
            lineWidth: 2,
            lineDasharray: [10, 10],
            polygonFill: "#F44336",
            polygonOpacity: 0.3,
          },
          vertexSymbol: {
            markerType: "ellipse",
            markerFill: "black",
            markerLineColor: "black",
            markerLineWidth: 1,
            markerWidth: 5,
            markerHeight: 5,
          },
          labelOptions: {
            textSymbol: {
              textFaceName: "Nunito",
              textFill: "black",
              textHaloFill: "#fff",
              textHaloRadius: 2,
              textLineSpacing: 1,
              textHorizontalAlignment: "right",
              textDx: 15,
              textWeight: "bold",
              markerLineColor: "#F44336",
              markerFill: "#F44336",
            },
            boxStyle: {
              padding: [6, 2],
              symbol: {
                markerType: "square",
                markerFill: "red",
                markerFillOpacity: 0,
                markerLineOpacity: 0,
              },
            },
          },
          clearButtonSymbol: [
            {
              markerType: "square",
              markerFill: "#F44336",
              markerLineColor: "#F44336",
              markerLineWidth: 2,
              markerWidth: 15,
              markerHeight: 15,
              markerDx: 20,
            },
            {
              markerType: "x",
              markerWidth: 10,
              markerHeight: 10,
              markerLineColor: "#fff",
              markerDx: 20,
            },
          ],
          language: "es-MX",
        }).addTo(this.map);

        this.annotationTool.on("drawstart", () => {
          this.reorderZindexLayers();
        });

        this.annotationTool.on("drawend", () => {
          this.reorderZindexLayers();
        });

        this.annotationTool.on("annotations", (annotations) => {
          this.annotationsFeatures = annotations["data"];
        });

        this.reorderZindexLayers();

        this.annotationTool.disable();
      }
    },
    toggleAnnotations() {
      this.isEnabled = !this.isEnabled;
    },
    clearMeasures() {
      if (this.annotationTool) {
        this.annotationTool.clear();
        this.map.getLayers().forEach((l) => {
          if (l._id.includes("Previous")) l.clear();
        });
      }
    },

    reorderZindexLayers() {
      setTimeout(() => {
        this.map.getLayers().forEach((l) => {
          if (l._id.includes("Annotations_")) l.setZIndex(1000);
          if (l._id.includes("Annotations_markers_")) l.setZIndex(1500);
          if (l._id.includes("Annotations_length_")) l.setZIndex(2000);
          if (l._id.includes("#656567_")) l.setZIndex(1001);
          if (l._id.includes("PreviousAnnotations_markers_")) l.setZIndex(1501);
          if (l._id.includes("PreviousAnnotations_length_")) l.setZIndex(2002);
        });
      }, 100);
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
