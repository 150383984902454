<template>
  <v-alert outlined transition="scale-transition" v-if="!!result">
    <p v-if="loading">
      {{ $t("vessel_call.filled_auto") }}
      <span class="font-weight-medium indigo--text text--darken-2">{{
        $t("global.name").toLowerCase()
      }}</span
      >,
      <span class="font-weight-medium indigo--text text--darken-2">imo</span>,
      <span class="font-weight-medium indigo--text text--darken-2">loa</span>,
      <span class="font-weight-medium indigo--text text--darken-2">beam</span>,
      <span class="font-weight-medium indigo--text text--darken-2">{{
        $t("vessel_call.entry_draught").toLowerCase()
      }}</span>
      {{ $t("global.and") }}
      <span class="font-weight-medium indigo--text text--darken-2">{{
        $t("vessel_call.exit_draught").toLowerCase()
      }}</span
      >. {{ $t("vessel_call.verify_vessel_call") }}.
    </p>
    <p v-if="loading">
      {{ $t("vessel_call.analyse_part_1") }}
      <span class="font-weight-medium">{{ item.name }}</span>
      {{ $t("vessel_call.analyse_part_2") }}
      <span class="font-weight-medium">{{ port_locode.name }}</span>
      {{ $t("vessel_call.analyse_part_3") }}
      <i>{{ $t("vessel_call.berth_location").toLowerCase() }}</i>
      {{ $t("global.and") }}
      <i>{{ $t("configurations.mooring_bitts").toLowerCase() }}</i
      >. {{ $t("vessel_call.analyse_part_4") }}!
    </p>
    <v-progress-linear
      color="red"
      indeterminate
      v-if="loading"
      class="mt-2"
    ></v-progress-linear>
    <v-row v-if="!!result && Object.keys(result).length > 0">
      <v-col cols="6">
        <p class="text-center font-weight-bold">
          {{ $t("vessel_call.last_vessel_call") }}
        </p>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.process_number") }}:
                </td>
                <td>{{ result.last_process_number }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.end_mooring_bow") }}:
                </td>
                <td>{{ result.last_bow_code }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.end_mooring_stern") }}:
                </td>
                <td>{{ result.last_stern_code }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.berth_location") }}:
                </td>
                <td>{{ result.last_berth_location }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.mooring_edge") }}:
                </td>
                <td>{{ result.last_manoeuvre_starboard }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <p class="text-center font-weight-bold">
          {{ $t("vessel_call.other_indicators") }}
        </p>
        <v-simple-table dense>
          <template>
            <tbody>
              <tr>
                <td class="font-weight-medium">Estadia média:</td>
                <td>{{ result.avg_time_in_port | formatAge }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.end_mooring_bow_more_used") }}:
                </td>
                <td>{{ result.more_used_end_mooring_bow }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.end_mooring_stern_more_used") }}:
                </td>
                <td>{{ result.more_used_end_mooring_stern }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">
                  {{ $t("vessel_call.berth_location_more_used") }}:
                </td>
                <td>{{ result.more_used_berth_location }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">Bordo de atracação:</td>
                <td>{{ result.more_used_manoeuvre_starboard }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="!loading && !!result && Object.keys(result).length === 0">
      <v-col cols="12">
        {{
          "Lamentamos mas não foi possível encontrar dados para o navio com o IMO: " +
          this.imo
        }}
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  data() {
    return {
      result: null,
      loading: false,
    };
  },
  props: ["item", "port_locode"],
  computed: {
    imo() {
      return this.item.imo;
    },
  },
  methods: {
    analyseVesselCall: _.debounce(function () {
      this.loading = true;
      this.result = [];
      this.$store
        .dispatch("vessel_calls/GET_BERTHING_PLANNER", this.imo)
        .then((data) => {
          this.result = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }, 300),
  },
  watch: {
    imo() {
      this.analyseVesselCall();
    },
  },
};
</script>
