<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          class="py-5"
          @click.stop="isTimelineEnabled = true"
          :color="isTimelineEnabled ? 'primary' : 'white'"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :color="isTimelineEnabled ? 'white' : 'primary'"
            >mdi-history</v-icon
          >
        </v-btn>
      </template>
      <span> {{ $t("global.timeline") }} </span>
    </v-tooltip>

    <v-dialog
      v-model="isTimelineEnabled"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar dark flat dense color="blue darken-4">
          <v-toolbar-title>Histórico de movimentações</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed icon dark dense @click="isTimelineEnabled = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="ma-0 pa-0"
          style="height: calc(100vh - 48px - 65px)"
        >
          <splitpanes
            class="default-theme"
            horizontal
            style="height: calc(100vh - 48px - 65px)"
          >
            <pane :size="60">
              <Map :records.sync="this.ships" :item.sync="selectedVesselCall" />
            </pane>
            <pane :size="40" class="proeminent-pane">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="ships"
                item-key="report_number"
                class="reportPointsList"
                fixed-header
                must-sort
                sort-by="report_datetime"
                sort-desc
                dense
                :custom-sort="customSort"
                hide-default-footer
                disable-pagination
                height="calc(100% - 50px)"
              >
                <template v-slot:top>
                  <v-row
                    no-gutters
                    style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"
                    class="mt-0"
                  >
                    <v-col cols="6">
                      <v-list-item class="ma-0 pa-0 ml-1">
                        <v-list-item-content class="ma-0 pa-0">
                          <v-list-item-title class="font-weight-bold"
                            >{{ currentDate | formatDatetime }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="font-weight-regular"
                            style="font-size: 11px"
                          >
                            Escalas do Intervalo:
                            {{ ships.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <vc-date-picker
                        v-model="range"
                        mode="dateTime"
                        :masks="masks"
                        is24hr
                        is-range
                        :max-date="new Date()"
                        class="text-right float-right mr-2 pt-1"
                        :popover="popoverOptions"
                      >
                        <template v-slot="{ inputEvents }">
                          <v-chip label v-on="inputEvents.start" outlined>
                            <v-icon small left>mdi-calendar</v-icon
                            >{{ startVal }}
                          </v-chip>
                          <v-icon small>mdi-arrow-right</v-icon>
                          <v-chip label v-on="inputEvents.end" outlined>
                            <v-icon small left>mdi-calendar</v-icon>{{ endVal }}
                          </v-chip>
                        </template>
                      </vc-date-picker>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.process_number="{ item }">
                  <div class="ma-0 pa-0">
                    <b>{{ item.process_number }}</b>
                  </div>
                </template>

                <template v-slot:item.report_datetime="{ item }">
                  {{ item.report_datetime | formatDatetime }}
                </template>
                <template v-slot:item.eta="{ item }">
                  {{ item.eta | formatDatetime }}
                </template>
                <template v-slot:item.etd="{ item }">
                  {{ item.etd | formatDatetime }}
                </template>
                <template v-slot:item.manoeuvre_starboard="{ item }">
                  {{ item.manoeuvre_starboard ? "Estibordo" : "Bombordo" }}
                </template>
                <template v-slot:item.report_type_code="{ item }">
                  {{ item.report_type_code }} -
                  {{ item.report_type_description }}
                </template>

                <template v-slot:item.mooring_bitts="{ item }">
                  <table
                    v-if="!!item.end_mooring_stern && !!item.end_mooring_bow"
                  >
                    <tr>
                      <td width="30px">
                        {{ item.end_mooring_stern.toString() }}
                      </td>
                      <td width="35px">
                        <img src="img/ship_mooring_bitts.png" color="blue" />
                      </td>
                      <td width="30px">
                        {{ item.end_mooring_bow.toString() }}
                      </td>
                    </tr>
                  </table>
                </template>
              </v-data-table>
            </pane>
          </splitpanes>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions color="blue darken-4">
          <TimeSliderPast
            v-model="currentDate"
            :startDate="range.start"
            :endDate="range.end"
            :items="data"
            dateProperty="report_datetime"
          >
          </TimeSliderPast>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
const DEFAULT_MAX_DAYS_TO_CONSIDER_FOR_SCENARIO = 7;
import Map from "@/components/Historic/Map";
import TimeSliderPast from "@/components/Historic/TimeSliderPast";
import { Splitpanes, Pane } from "splitpanes";
import { Settings, DateTime } from "luxon";
import "splitpanes/dist/splitpanes.css";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

export default {
  components: {
    Splitpanes,
    Pane,
    TimeSliderPast,
    Map,
  },
  data() {
    return {
      vesselCallPaneSize: 0,
      selectedVesselCall: null,
      isTimelineEnabled: false,
      loading: false,
      currentDate: null,
      headers: [
        {
          text: "ESCALA",
          value: "process_number",
          align: "center",
          sortable: false,
          groupable: false,
        },
        {
          text: "NOME",
          value: "name",
          sortable: false,
          groupable: false,
        },
        {
          text: "ÚLT. PONTO DE RELATO",
          value: "report_type_code",
          sortable: false,
          groupable: false,
        },
        {
          text: "DATA DO PONTO DE RELATO",
          value: "report_datetime",
          groupable: false,
          sortable: false,
        },
        {
          text: "LOCAL",
          value: "berth_location",
          sortable: false,
          groupable: false,
        },
        {
          text: "CABEÇOS",
          value: "mooring_bitts",
          sortable: false,
          groupable: false,
          width: "120px",
          align: "center",
        },
        {
          text: "BORDO DE ATRACAÇÃO",
          value: "manoeuvre_starboard",
          sortable: false,
          groupable: false,
        },
        {
          text: "ETA",
          value: "eta",
          sortable: false,
          groupable: false,
        },
        {
          text: "ETD",
          value: "etd",
          sortable: false,
          groupable: false,
        },
      ],
      data: [],
      range: {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - DEFAULT_MAX_DAYS_TO_CONSIDER_FOR_SCENARIO
        ),
        end: new Date(),
      },
      popoverOptions: {
        placement: "top",
      },
      masks: {
        input: "YYYY-MM-DD HH:mm",
      },
    };
  },
  computed: {
    ships() {
      //filter ships by dates
      let results = this.data
        .filter((item) => {
          let report_datetime = DateTime.fromISO(item.report_datetime);
          let eta = DateTime.fromISO(item.eta);
          let etd = DateTime.fromISO(item.etd); //@todo: um dia usar o atd (para ja nao conseguimos obter essa informação)

          return (
            eta <= this.currentDate &&
            etd >= this.currentDate &&
            report_datetime <= this.currentDate
          );
        })
        .sort((a, b) => {
          return new Date(b.report_datetime) - new Date(a.report_datetime);
        })
        .map((item) => ({
          ...item,
          visible: item.end_mooring_bow && item.end_mooring_stern,
          upcoming_berth_location: item.berth_location,
          status: this.formatReportPointStatus(item.report_type_code),
        }));

      //get unique vessel_calls by process_number
      return [...new Set(results.map((obj) => obj.process_number))].map(
        (process_number) => {
          return results.find((obj) => obj.process_number === process_number);
        }
      );
    },
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    startVal() {
      return DateTime.fromJSDate(this.range.start).toFormat("dd/LL/yyyy HH:mm");
    },
    endVal() {
      return DateTime.fromJSDate(this.range.end).toFormat("dd/LL/yyyy HH:mm");
    },
  },
  watch: {
    currentDate() {
      this.$emit("update:records", this.ships);
    },
    isTimelineEnabled() {
      this.refresh();
    },
    range() {
      this.refresh();
    },
  },
  methods: {
    formatReportPointStatus(code) {
      let status = "";
      switch (code) {
        case "ATR":
          status = "BERTHED_VESSEL_CALLS";
          break;
        case "FUN":
          status = "ANCHORED_VESSEL_CALLS";
          break;
        case "LAR":
          status = "EXITED_VESSEL_CALLS";
          break;
        default:
          status = "DEFAULT_STATUS";
          break;
      }

      return status;
    },
    focusOnVesselCall(item) {
      this.selectedVesselCall = item;
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "report_datetime") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else if (index[0] == "loa" || index[0] == "beam") {
          if (!isDesc[0]) {
            if (a[index] == null) return -1;
            if (b[index] == null) return 1;
            return parseFloat(a[index]) - parseFloat(b[index]);
          } else {
            if (a[index] == null) return 1;
            if (b[index] == null) return -1;
            return parseFloat(b[index]) - parseFloat(a[index]);
          }
        } else {
          let valueA = a[index];
          let valueB = b[index];
          if (typeof valueA == "string") {
            valueA = valueA.trim();
          }
          if (typeof valueB == "string") {
            valueB = valueB.trim();
          }
          if (!isDesc[0]) {
            if (valueA == null) return -1;
            if (valueB == null) return 1;
            return valueA < valueB ? -1 : 1;
          } else {
            if (valueA == null) return 1;
            if (valueB == null) return -1;
            return valueB < valueA ? -1 : 1;
          }
        }
      });
      return items;
    },
    refresh() {
      this.data = [];
      this.loading = true;
      this.$store
        .dispatch("vessel_calls/HISTORY", this.range)
        .then((serverData) => {
          this.loading = false;
          this.data = serverData;
        })
        .catch((ex) => {
          this.loading = false;
          console.error("Error is: ", ex);
        });
    },
  },
};
</script>
<style>
.reportPointsList {
  display: inline;
}

.reportPointsList .v-data-table__wrapper > table > tbody > tr > th,
.reportPointsList .v-data-table__wrapper > table > tbody > tr > th,
.reportPointsList .v-data-table__wrapper > table > thead > tr > th,
.reportPointsList .v-data-table__wrapper > table > tfoot > tr > td,
.reportPointsList .v-data-table__wrapper > table > thead > tr > td,
.reportPointsList .v-data-table__wrapper > table > thead > tr > td,
.reportPointsList tbody tr td {
  font-size: 11px !important;
  padding-left: 2px !important;
}
</style>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
