<template>
  <div>
    <LoadingLightHouse :open.sync="loading" :text="$t('vessel_call.validate_vessel_call')" />
    <v-dialog v-model="isOpen" fullscreen hide-overlay transition="dialog-bottom-transition" :loading="loadingPlan" persistent>
      <v-card tile>
        <v-toolbar dark flat dense color="blue darken-4">
          <v-toolbar-title>{{ $t("global.simulator") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed icon dark @click="closeSimulator">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="ma-0 pa-0" style="height: calc(100vh - 48px - 74px)">
          <splitpanes class="default-theme" style="height: calc(100vh - 48px - 74px)">
            <pane :max-size="vesselCallPaneSize" :size="vesselCallPaneSize" v-if="vesselCallPaneSize != 0">
              <VesselCall :item.sync="detailVesselCall" style="height: calc(100vh - 48px - 74px)" :key="componentKey" />
            </pane>
            <pane :size="100 - vesselCallPaneSize">
              <splitpanes class="default-theme" horizontal>
                <pane :size="60">
                  <Map :records.sync="simulationVesselCallsWithValidation" :item.sync="selectedVesselCall" @updateMooringBitts="handleMooringBittsUpdate" :annotations.sync="annotations" :snapshot.sync="snapshotEnabled" :name="plan_version.name ?? ''" :archived="archived" />

                  <div style="position: absolute; top: 60px; float: right; width: 300px; background-color: white; right: 10px" elevation="2" v-if="!!plan_version.id && !snapshotEnabled">
                    <vc-date-picker v-model="filterDatesRange" mode="dateTime" :mask="masksFilter" is24hr is-range is-expanded :available-dates="range" :popover="popoverOptions"
                      ><template v-slot="{ inputValue, inputEvents }">
                        <v-text-field color="black" v-on="inputEvents.start" :value="inputValue.start + '-' + inputValue.end" outlined dense hide-details> </v-text-field>
                      </template>
                    </vc-date-picker>
                  </div>
                </pane>
                <pane :size="40" class="proeminent-pane">
                  <v-progress-linear v-if="initialLoading" color="blue darken-4" indeterminate rounded height="6"></v-progress-linear>
                  <Scheduler v-if="plan_version" :plan="plan_version" :records.sync="simulationVesselCallsWithValidation" :item.sync="selectedVesselCall" :detail.sync="detailVesselCall" :snapshotEnabled="snapshotEnabled" :currentDate="currentDate" :stayRange.sync="range" :filterRange.sync="filterRange" :archived="archived" />
                  <!-- </div> -->
                </pane>
              </splitpanes>
            </pane>
          </splitpanes>
        </v-card-text>

        <v-divider v-if="snapshotEnabled"></v-divider>
        <v-card-text v-if="snapshotEnabled">
          <PreviewSnapshotSlider v-if="!initialLoading" v-model="currentDate" :startDate="range.start" :endDate="range.end" :items="simulationVesselCallsWithValidation"> </PreviewSnapshotSlider>
        </v-card-text>

        <v-divider v-if="!snapshotEnabled"></v-divider>

        <v-card-actions v-if="!snapshotEnabled" class="pt-4" color="blue darken-4">
          <span v-if="!loadingPlan && plan_version" style="vertical-align: bottom">
            {{ $t("version.number") }}: <b>{{ plan_version ? plan_version.number : 0 }}</b> | {{ $t("simulator.number_vessel_calls") }}:
            <b>{{ simulationVesselCallsWithValidation ? simulationVesselCallsWithValidation.length : 0 }}</b>
            | {{ $t("simulator.number_of_days") }}: <b> {{ number_of_days }}</b>
          </span>

          <v-spacer></v-spacer>

          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed outlined color="blue darken-4" dark :disabled="snapshotEnabled || loadingPlan" @click.stop="syncVesselCalls" v-bind="attrs" v-on="on" class="mr-1 font-weight-bold" v-if="hasUserPermissionToViewEditOrManage('PLANS') && !archived">
                <v-icon dark left> mdi-cloud-sync-outline </v-icon>
                {{ $t("vessel_call.update_vessel_calls") }}
              </v-btn>
            </template>
            <span>{{ $t("vessel_call.update_vessel_calls") }}</span>
          </v-tooltip>

          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed outlined color="blue darken-4" dark :disabled="snapshotEnabled || loadingPlan" @click.stop="addVesselCall" v-bind="attrs" v-on="on" class="mr-1 font-weight-bold" v-if="hasUserPermissionToViewEditOrManage('VESSEL_CALLS') && !archived">
                <v-icon dark left> mdi-ferry </v-icon>
                {{ $t("vessel_call.add_temporary_vessel_call") }}
              </v-btn>
            </template>
            <span>{{ $t("vessel_call.create_temporary_vessel_call") }}</span>
          </v-tooltip>

          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed outlined color="blue darken-4" dark :disabled="snapshotEnabled || loadingPlan" @click.stop="validatePlan" v-bind="attrs" v-on="on" class="mr-1 font-weight-bold" v-if="hasUserPermissionToViewEditOrManage('PLANS') && !archived">
                <v-icon dark left> mdi-lighthouse-on </v-icon>
                {{ $t("global.validate") }}
              </v-btn>
            </template>
            <span>{{ $t("simulator.validate_plane") }}</span>
          </v-tooltip>

          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed outlined color="blue darken-4" dark :disabled="snapshotEnabled || loadingPlan" @click.stop="savePlan" v-bind="attrs" v-on="on" class="font-weight-bold" v-if="hasUserPermissionToViewEditOrManage('PLANS') && !archived">
                <v-icon dark left> mdi-content-save-all-outline </v-icon>
                {{ $t("plan.save_plan") }}
              </v-btn>
            </template>
            <span>{{ $t("plan.save_plan") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>

      <CreatePlanDialog :open.sync="openAddPlanDialog" :item.sync="plan_version" @undoCreation="undoCreation" />
      <CreateVesselCallDialog :open.sync="openAddVesselCallDialog" :stayRange="dates" :key="compKey" @created="handleVesselCallCreation" :availableRange="availableRange" />
      <CloseSimulatorDialog :open.sync="openCloseSimulatorDialog" :simulatorOpen.sync="isOpen" />
    </v-dialog>
  </div>
</template>
<script>
  import { Splitpanes, Pane } from "splitpanes";
  import "splitpanes/dist/splitpanes.css";
  import Map from "@/components/Simulator/Map";
  import PreviewSnapshotSlider from "@/components/Simulator/PreviewSnapshotSlider";
  import Scheduler from "@/components/Simulator/Scheduler";
  import CreatePlanDialog from "@/components/Plans/CreatePlanDialog";
  import VesselCall from "@/components/VesselCalls/DetailVesselCall";
  import CreateVesselCallDialog from "@/components/Simulator/CreateVesselCallDialog";
  import CloseSimulatorDialog from "@/components/Simulator/CloseSimulatorDialog";
  import LoadingLightHouse from "@/components/Main/LoadingLightHouse";

  import { Settings, DateTime } from "luxon";
  import configs from "@/helpers/configs";
  Settings.defaultZone = configs.getTimezone();
  Settings.defaultLocale = configs.getLocale();

  const DEFAULT_MAX_DAYS_TO_CONSIDER_FOR_SCENARIO = 7;
  export default {
    props: ["open", "item", "version"],
    components: {
      Splitpanes,
      Pane,
      Map,
      Scheduler,
      CreatePlanDialog,
      LoadingLightHouse,
      VesselCall,
      CreateVesselCallDialog,
      CloseSimulatorDialog,
      PreviewSnapshotSlider,
    },
    computed: {
      filterDatesRangeText() {
        if (this.filterDatesRange) {
          let start = this.filterDatesRange.start;
          let end = this.filterDatesRange.end;
          return `${start} - ${end}`;
        }
        return "";
      },
      availableRange() {
        if (this.filterDatesRange && this.filterDatesRange.start && this.filterDatesRange.end) {
          return [
            {
              start: new Date(this.filterDatesRange.start),
              end: new Date(this.filterDatesRange.end),
            },
            {
              start: new Date(this.filterDatesRange.start),
              end: null,
            },
          ];
        } else {
          return [];
        }
      },
      loadingPlan() {
        return this.$store.state.plans.loading;
      },
      isOpen: {
        get() {
          return this.open;
        },
        set(val) {
          this.$emit("update:open", val);
        },
      },
      number_of_days() {
        if (this.dates) return Math.ceil(this.dates.end.endOf("day").diff(this.dates.start.startOf("day"), "days").days);
        else return 0;
      },
      archived() {
        return this.item ? this.item.archived : false;
      },
    },
    data() {
      return {
        masksFilter: {
          input: "DD/MM/YYYY HH:mm",
        },
        popoverOptions: {
          placement: "bottom",
          offset: 0,
        },
        filterDatesRange: null,
        vesselCallPaneSize: 0,
        loading: false,
        openAddPlanDialog: false,
        selectedVesselCall: null,
        detailVesselCall: null,
        validations: [],
        simulationVesselCalls: null,
        simulationVesselCallsWithValidation: null,
        dates: null,
        currentDate: null,
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - DEFAULT_MAX_DAYS_TO_CONSIDER_FOR_SCENARIO, 0, 0, 0),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59),
        },
        filterRange: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - DEFAULT_MAX_DAYS_TO_CONSIDER_FOR_SCENARIO, 0, 0, 0),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59),
        },
        plan_version: {},
        openAddVesselCallDialog: false,
        snapshotEnabled: false,
        annotations: [],
        compKey: 0,
        openCloseSimulatorDialog: false,
        componentKey: 0,
        initialLoading: false,
      };
    },
    watch: {
      filterDatesRange() {
        this.recreateSimulationVesselCalls();
      },

      isOpen(val) {
        this.initialLoading = true;
        if (val) {
          this.loadPlanVersion().then((data) => {
            this.range = {
              start: new Date(data.start_date.getFullYear(), data.start_date.getMonth(), data.start_date.getDate(), 0, 0, 0),
              end: new Date(data.end_date.getFullYear(), data.end_date.getMonth(), data.end_date.getDate(), 23, 59, 59),
            };
            this.filterRange = {
              start: new Date(data.start_date.getFullYear(), data.start_date.getMonth(), data.start_date.getDate(), 0, 0, 0),
              end: new Date(data.end_date.getFullYear(), data.end_date.getMonth(), data.end_date.getDate(), 23, 59, 59),
            };
            //field annotations
            this.annotations = data.annotations;

            this.recreateSimulationVesselCalls();
            this.initialLoading = false;
          });
        } else {
          this.plan_version = {
            scheduler: JSON.stringify([]),
          };

          this.recreateSimulationVesselCalls();
          this.initialLoading = false;
        }
      },

      currentDate(val) {
        if (val) this.recreateSimulationVesselCalls();
      },

      range(val) {
        this.filterDatesRange = val;

        this.dates = {
          start: DateTime.fromJSDate(val.start),
          end: DateTime.fromJSDate(val.end),
        };
        // Update vessel calls
        this.recreateSimulationVesselCalls();
      },

      simulationVesselCalls() {
        this.simulationVesselCallsWithValidation = this.simulationVesselCalls.map(this.addValidations);
        this.validations = [];
      },

      validations() {
        this.simulationVesselCallsWithValidation = this.simulationVesselCallsWithValidation.map(this.addValidations);
      },

      snapshotEnabled() {
        this.recreateSimulationVesselCalls();
      },

      detailVesselCall(val) {
        this.componentKey++;
        this.vesselCallPaneSize = val ? 25 : 0;
      },

      simulationVesselCallsWithValidation(val) {
        this.$store.dispatch("screenshots/SET_VESSEL_CALLS", val);
      },
    },
    methods: {
      loadPlanVersion() {
        return new Promise((resolve) => {
          // Open/edit an existing version plan
          if (this.version && this.item) {
            this.$store.dispatch("plans/LOAD_VERSION", this.version.id).then((version_detail) => {
              this.plan_version = {
                id: this.item.id,
                name: this.item.name,
                notes: version_detail.notes,
                start_date: new Date(version_detail.start_date),
                end_date: new Date(version_detail.end_date),
                scheduler: version_detail.data,
                annotations: JSON.parse(version_detail.annotations),
                number: version_detail.number,
              };
              resolve(this.plan_version);
            });
          } else {
            // Create a new, empty plan
            this.plan_version = {
              name: "REV-" + new Date().toISOString().replace(/[-T:.Z]/g, ""),
              notes: "",
              start_date: new Date(),
              end_date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + DEFAULT_MAX_DAYS_TO_CONSIDER_FOR_SCENARIO, 23, 59, 59),
              number: 1,
              annotations: [],
            };
            resolve(this.plan_version);
          }
        });
      },
      recreateSimulationVesselCalls() {
        let newSimulationVesselCalls = this.plan_version && this.plan_version.scheduler ? JSON.parse(this.plan_version.scheduler) : JSON.parse(JSON.stringify(this.$store.state.vessel_calls.all));

        newSimulationVesselCalls = newSimulationVesselCalls.filter((vcall) => {
          if (!this.dates) return false;

          let etaDateTime = DateTime.fromISO(vcall.eta);
          let etdDateTime = DateTime.fromISO(vcall.etd);

          if (this.snapshotEnabled && this.currentDate) {
            return etaDateTime <= this.currentDate && this.currentDate <= etdDateTime;
          }

          const startDateTime = DateTime.fromJSDate(this.filterDatesRange.start);
          const endDateTime = DateTime.fromJSDate(this.filterDatesRange.end);

          const etaWithinRange = startDateTime <= etaDateTime && etaDateTime <= endDateTime;
          const etdWithinRange = startDateTime <= etdDateTime && etdDateTime <= endDateTime;

          const etaBeforeStart = etaDateTime < startDateTime;
          const etdAfterEnd = etdDateTime > endDateTime;

          const overlap = etaWithinRange || etdWithinRange || (etaBeforeStart && etdAfterEnd);
          return overlap;
        });

        newSimulationVesselCalls = newSimulationVesselCalls
          .sort(function (a, b) {
            return DateTime.fromISO(a.eta) - DateTime.fromISO(b.eta);
          })
          .map((vcall) => {
            return {
              ...vcall,
              visible: vcall.visible != null ? vcall.visible : vcall.end_mooring_bow && vcall.end_mooring_stern ? true : false,
              disabled: vcall.status != "EXPECTED_ARRIVAL_VESSEL_CALLS" && vcall.end_mooring_bow && vcall.end_mooring_stern ? true : false,
              status: this.snapshotEnabled && this.currentDate ? "BERTHED_VESSEL_CALLS" : vcall.status,
            };
          });
        this.simulationVesselCalls = this.cleanDuplicatesByProcessNumber(newSimulationVesselCalls);
      },
      savePlan() {
        this.updatePlan().then((plan) => {
          this.plan_version = plan;
          this.openAddPlanDialog = true;
        });
      },

      validatePlan() {
        this.loading = true;
        this.updatePlan().then((plan) => {
          this.$store.dispatch("plans/VALIDATE_PLAN", plan).then((validations) => {
            this.validations = validations;
            this.loading = false;
          });
        });
      },
      updatePlan() {
        return new Promise((resolve) => {
          //Create a copy of this, and remove all unnecessary properties
          let scheduler = JSON.parse(JSON.stringify(this.simulationVesselCallsWithValidation));

          if (scheduler) {
            scheduler.forEach((obj) => {
              delete obj._drawnObject;
            });
          }

          resolve({
            id: this.plan_version.id,
            name: this.plan_version.name,
            notes: this.plan_version.notes,
            start_date: this.range.start,
            end_date: this.range.end,
            scheduler: JSON.stringify(scheduler),
            annotations: JSON.stringify(this.annotations),
            number: this.plan_version.number,
          });
        });
      },
      addValidations(vc) {
        let validationFound = this.validations.find((validation) => {
          return validation.process_number == vc.process_number;
        });
        let location_status = null;
        let dates_status = null;
        let draught_status = null;
        if (validationFound) {
          location_status = validationFound.location_status;
          dates_status = validationFound.dates_status;
          draught_status = validationFound.draught_status;
        }
        return {
          ...vc,
          location_status: location_status,
          dates_status: dates_status,
          draught_status: draught_status,
        };
      },

      syncVesselCalls() {
        if (this.$store.state.vessel_calls.all) {
          if (this.simulationVesselCallsWithValidation) {
            let temporary_vessel_calls = JSON.parse(JSON.stringify(this.simulationVesselCallsWithValidation)).filter((vsCall) => {
              return vsCall.temporary;
            });

            let current_vessel_calls = JSON.parse(JSON.stringify(this.$store.state.vessel_calls.all));

            let refresh_list = current_vessel_calls.concat(temporary_vessel_calls);
            this.plan_version.scheduler = JSON.stringify(refresh_list);
          }

          //recreate list table
          this.recreateSimulationVesselCalls();
        }
      },
      addVesselCall() {
        this.compKey++;
        this.openAddVesselCallDialog = true;
      },
      handleVesselCallCreation(vesselCall) {
        this.simulationVesselCallsWithValidation.push(vesselCall);
      },
      handleMooringBittsUpdate(vesselCall) {
        let idx = this.simulationVesselCallsWithValidation.findIndex((vc) => vc.process_number == vesselCall.process_number);
        if (idx != -1) {
          this.simulationVesselCallsWithValidation.splice(idx, 1, vesselCall);
        }
        this.selectedVesselCall = null;
      },
      closeSimulator() {
        this.openCloseSimulatorDialog = true;
      },
      cleanDuplicatesByProcessNumber(vesselCalls) {
        let seenProcessNumbers = {};
        let uniqueVesselCalls = [];

        for (let vesselCall of vesselCalls) {
          if (!seenProcessNumbers[vesselCall.process_number]) {
            seenProcessNumbers[vesselCall.process_number] = true;
            uniqueVesselCalls.push(vesselCall);
          }
        }

        return uniqueVesselCalls;
      },

      undoCreation() {
        if (this.plan_version.id === undefined)
          this.plan_version = {
            name: this.plan_version.name,
            notes: this.plan_version.notes,
            start_date: this.plan_version.start_date,
            end_date: this.plan_version.end_date,
            annotations: this.plan_version.annotations,
          };
      },
    },
    mounted() {
      this.filterDatesRange = this.range;
    },
  };
</script>
<style>
  .textField {
    font-size: 12px;
  }

  .textField .v-text-field__details {
    display: none;
  }
</style>

<style scoped>
  .v-dialog > .v-card > .v-card__text {
    padding: 0 12px 8px;
  }
</style>
