<template>
  <div class="text-center" v-if="isEnabled">
    <v-dialog v-model="dialog" width="80%" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          dark
          v-bind="attrs"
          v-on="on"
          style="
            background-color: rgba(255, 255, 255, 0.12) !important;
            box-shadow: none;
          "
        >
          <status-indicator
            :status="!!updateDate ? 'active' : 'negative'"
            pulse
            class="mr-3"
          /><span v-if="!!updateDate" style="color: black !important"
            >{{ updateDate | formatJSFullDatetime }} | {{ labelAIS }}</span
          ><span v-else style="color: black !important">AIS OFFLINE</span>
        </v-btn>
      </template>

      <v-card tile>
        <v-toolbar dark flat color="blue darken-4">
          <v-toolbar-title>
            <v-list-item class="ma-0 pa-0 ml-1">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title class="font-weight-bold">
                  {{ labelTitleAIS }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-regular"
                  style="font-size: 11px"
                  >{{ updateDate | formatJSFullDatetime }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.stop="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-5">
          <v-data-table
            :headers="headers"
            :items="ships"
            item-key="mmsi"
            :search="search"
            sort-by="shipname"
            sort-desc
            :items-per-page="5"
            class="aisShipList"
            must-sort
            @current-items="updateFiltered"
            ref="aisShipList"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                single-line
                :label="$t('global.search')"
                prepend-inner-icon="mdi-magnify"
                outlined
                dense
              />
            </template>
            <template v-slot:item.countryCode="{ item }">
              <v-layout row justify align-center>
                <span left v-if="!!item.countryCode">
                  <country-flag :country="item.countryCode" rounded />
                </span>
                <div class="pt-1 ml-1">{{ item.countryName }}</div>
              </v-layout>
            </template>

            <template v-slot:item.shipname="{ item }">
              {{ item.shipname }}
            </template>

            <template v-slot:item.immsi="{ item }">
              <v-btn
                :ref="item.immsi"
                class="ma-0 pa-1"
                outlined
                small
                @click.stop="focusOnAisShip(item)"
              >
                {{ item.immsi }}
              </v-btn>
            </template>
            <template v-slot:item.cargo="{ item }">
              <v-chip
                small
                label
                :color="item.color"
                text-color="white"
                class="ma-0 pa-1"
                dark
                >{{ item.type }}</v-chip
              >
            </template>
            <template v-slot:item.updatedAt="{ item }">
              {{ item.updatedAt | formatFullDatetime }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//Access vue instance to reach global constants
import * as maptalks from "maptalks";
import configs from "@/helpers/configs";
import * as turf from "@turf/turf";

const AISSHIPS_LAYERNAME = "ais_ships";
const DEFAULT_SHIP_LENGTH = 20;
const DEFAULT_SHIP_WIDTH = 5;

import AISShipsTracker from "@/helpers/realtime/aisshipstracker";
import AISShip from "@/helpers/ships/aisship";

import { StatusIndicator } from "vue-status-indicator";

export default {
  props: {
    refMap: Object,
    ship: Object,
  },
  components: {
    StatusIndicator,
  },
  data() {
    return {
      dialog: false,
      drawnShips: new Map(),
      search: "",
      updateDate: null,
      locode: configs.getLocode(),
      filteredLength: 0,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "MMSI",
          align: "start",
          sortable: true,
          value: "immsi",
          width: "100px",
        },
        {
          text: "NOME",
          align: "start",
          sortable: true,
          value: "shipname",
        },
        {
          text: "PAÍS",
          align: "start",
          sortable: true,
          value: "countryCode",
        },
        {
          text: "TIPO",
          align: "start",
          sortable: true,
          value: "cargo",
          width: "120px",
        },
        {
          text: "INDICATIVO DE CHAMADA",
          align: "start",
          sortable: true,
          value: "callsign",
          width: "120px",
        },
        {
          text: "IMO",
          align: "start",
          sortable: true,
          value: "imo",
          width: "100px",
        },
        {
          text: "ÚLT. ATUALIZAÇÃO",
          align: "start",
          sortable: true,
          value: "updatedAt",
          width: "160px",
        },
        {
          text: "",
          value: "data_search",
          align: " d-none",
        },
      ];
    },
    isEnabled() {
      return this.$store.state.settings.aisEnabled;
    },
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    labelAIS() {
      return "AIS (" + this.$store.state.ais_ships.all.length + " Navios)";
    },
    labelTitleAIS() {
      return "AIS (" + this.filteredLength + " / " + this.$store.state.ais_ships.all.length + " Navios)";
    },
    ships() {
      return this.$store.state.ais_ships.all.map((ship) => {
        let type = configs.getShipType(ship.cargo);
        return {
          ...ship,
          type: type.name,
          color: type.color,
          data_search: JSON.stringify({
            immsi: ship.immsi,
            shipname: ship.shipname,
            countryCode: ship.countryCode,
            countryName: ship.countryName,
            typeName: type.name,
            imo: ship.imo,
            updatedAt: this.$options.filters.formatFullDatetime(ship.updatedAt),
          }),
        };
      });
    },
    aisShip: {
      get() {
        return this.ship;
      },
      set(val) {
        this.$emit("update:ship", val);
      },
    },
  },
  mounted() {
    this.enableTracking();
  },
  watch: {
    isEnabled(val) {
      if (val) {
        this.enableTracking();
      } else {
        this.aisShip = null;
        this.disableTracking();
      }
    },
    aisShip(val) {
      //clear all previous selected
      this.drawnShips.forEach((ship) => {
        ship._drawnObject.removeHighlight();
      });
      if (val && this.drawnShips.has(val.id)) {
        //highlight this ship
        this.drawnShips.get(val.id)._drawnObject.addHighlight();

        //center map in this ship
        this.refMap.setCenter(
          this.drawnShips.get(val.id)._drawnObject.getShipCenterOrigin()
        );

        //fix zoom
        this.refMap.setZoom(18);
      }
    },
  },
  beforeDestroy() {
    this.disableTracking();
  },
  methods: {
    updateFiltered(items) {
      if (this.$refs.aisShipList)
        this.filteredLength = this.$refs.aisShipList.$children[0].itemsLength;
      else this.filteredLength = items.length;
    },
    focusOnAisShip(ship) {
      this.dialog = false;
      this.aisShip = ship;
    },
    clearLayer() {
      if (this.map) {
        if (this.map.getLayer(AISSHIPS_LAYERNAME))
          this.map.getLayer(AISSHIPS_LAYERNAME).clear();
      }
    },
    recreateLayer() {
      if (this.map) {
        if (!this.map.getLayer(AISSHIPS_LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(AISSHIPS_LAYERNAME));
        else this.map.getLayer(AISSHIPS_LAYERNAME).clear();
      }
    },
    enableTracking() {
      this.recreateLayer();
      AISShipsTracker.on("add", this.handleShipAdded);
      AISShipsTracker.on("update", this.handleShipUpdated);
      AISShipsTracker.on("remove", this.handleShipRemoved);
    },
    disableTracking() {
      this.clearLayer();
      AISShipsTracker.off("add", this.handleShipAdded);
      AISShipsTracker.off("update", this.handleShipUpdated);
      AISShipsTracker.off("remove", this.handleShipRemoved);
    },
    handleShipAdded(ship) {
      if (ship && ship.lat && ship.lon) {
        this.updateDate = new Date();

        var from = turf.point([ship.lon, ship.lat]);
        var to = turf.point([
          this.locode.coordinates[0],
          this.locode.coordinates[1],
        ]);
        var options = { units: "meters" };

        var distance = turf.distance(from, to, options);

        if (distance < 100000) {
          if (!ship._drawnObject) {
            let shipSpec = this.mapShipToSpec(ship);
            try {
              let drawnShip = new AISShip(
                [],
                {
                  properties: {
                    id: shipSpec.Id,
                    name: shipSpec.Name,
                    lineDasharray: ship.updating ? null : [5],
                    lineColor: configs.getShipType(shipSpec.Cargo).color,
                    fillColor: configs.getShipType(shipSpec.Cargo).color,
                    width: shipSpec.Width,
                    length: shipSpec.Length,
                    flag: shipSpec.Flag,
                  },
                  draggable: false,
                },
                shipSpec
              );
              if (drawnShip) {
                drawnShip.addTo(this.map.getLayer(AISSHIPS_LAYERNAME));
                ship._drawnObject = drawnShip;
                this.$store.dispatch("ais_ships/ADD_OR_UPDATE", ship);

                drawnShip.on("ship_selected", (e) => {
                  let ship = this.ships.find((element) => {
                    return element.id == e.id;
                  });
                  this.aisShip = ship;
                });

                this.drawnShips.set(shipSpec.Id, ship);
              }
            } catch (e) {
              console.error(e, shipSpec);
            }
          } else {
            this.handleShipUpdated(ship);
          }
        }
      }
    },
    handleShipUpdated(ship) {
      this.updateDate = new Date();
      if (ship) {
        if (ship._drawnObject) {
          let shipSpec = this.mapShipToSpec(ship);
          ship._drawnObject.properties = {
            id: shipSpec.Id,
            name: shipSpec.Name,
            lineDasharray: ship.updating ? null : [5],
            lineColor: configs.getShipType(shipSpec.Cargo).color,
            fillColor: configs.getShipType(shipSpec.Cargo).color,
            width: shipSpec.Width,
            length: shipSpec.Length,
            flag: shipSpec.Flag,
          };
          ship._drawnObject.updateShipSpec(shipSpec);
          this.$store.dispatch("ais_ships/ADD_OR_UPDATE", ship);
          this.drawnShips.set(shipSpec.Id, ship);
        } else {
          //this was wrongly assumed as an update, but we dont have it yet
          this.handleShipAdded(ship);
        }
      }
    },
    handleShipRemoved(ship) {
      this.updateDate = new Date();
      if (ship && ship._drawnObject) {
        this.drawnShips.delete(ship.Id);
        ship._drawnObject.remove();
        this.$store.dispatch("ais_ships/REMOVE", ship);
      }
    },
    mapShipToSpec(ship) {
      return {
        Id: ship.id,
        Name: ship.shipname,
        IMO: ship.imo,
        Draggable: true,
        Length: Math.min(parseFloat(ship.length || DEFAULT_SHIP_LENGTH), 150),
        Width: Math.min(parseFloat(ship.width || DEFAULT_SHIP_WIDTH), 20),
        MapGeom: "POINT(" + ship.lon + " " + ship.lat + ")",
        Heading: parseFloat(ship.hdg || 0),
        Flag: ship.countryCode,
        Color: "black",
        Cargo: ship.cargo,
        shipDimensions: {
          dimA: ship.dimA,
          dimB: ship.dimB,
          dimC: ship.dimC,
          dimD: ship.dimD,
        },
      };
    },
  },
};
</script>
<style>
.aisShipList .v-data-table__wrapper > table > tbody > tr > th,
.aisShipList .v-data-table__wrapper > table > tbody > tr > th,
.aisShipList .v-data-table__wrapper > table > thead > tr > th,
.aisShipList .v-data-table__wrapper > table > tfoot > tr > td,
.aisShipList .v-data-table__wrapper > table > thead > tr > td,
.aisShipList .v-data-table__wrapper > table > thead > tr > td,
.aisShipList tbody tr td {
  font-size: 11px !important;
  padding-left: 2px !important;
}

.aisShipList tr.v-data-table__expanded__content {
  box-shadow: inset 1px 0px 2px rgba(0, 0, 0, 0.39) !important;
  background-color: rgba(174, 221, 243, 0.541);
}
</style>
