<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="toggle"
          small
          class="py-5"
          :title="$t('global.tools')"
          :color="show ? 'primary' : 'white'"
          :disabled="isDistanceEnabled || isAreaEnabled || isAngleEnabled"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon :color="show ? 'white' : 'primary'">mdi-tape-measure</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.tools") }} </span>
    </v-tooltip>

    <Distance
      v-if="show"
      :map="this.map"
      class="mb-2"
      :isDistanceEnabled.sync="isDistanceEnabled"
      :disabled="isAreaEnabled || isAngleEnabled"
    />
    <Area
      v-if="show"
      :map="this.map"
      class="mb-2"
      :isAreaEnabled.sync="isAreaEnabled"
      :disabled="isDistanceEnabled || isAngleEnabled"
    />
    <Angle
      v-if="show"
      :map="this.map"
      class="mb-2"
      :isAngleEnabled.sync="isAngleEnabled"
      :disabled="isDistanceEnabled || isAreaEnabled"
    />
  </fragment>
</template>

<script>
import Distance from "@/components/Tools/Distance";
import Area from "@/components/Tools/Area";
import Angle from "@/components/Tools/Angle";

export default {
  props: ["map"],
  components: {
    Distance,
    Area,
    Angle,
  },
  data() {
    return {
      show: false,
      isDistanceEnabled: null,
      isAreaEnabled: null,
      isAngleEnabled: null,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
