<template>
  <v-dialog v-model="isOpen" persistent width="600" scrollable>
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          {{
            plan_version && plan_version.id
              ? plan_version.name + " :: " + $t("plan.save_version")
              : $t("plan.save_plan")
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="undoCreation">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="255"
            maxlength="255"
            v-model="plan_version.name"
            required
            label="Nome"
            :rules="nameRules"
            outlined
          />
          <v-textarea
            :label="$t('global.observations')"
            counter="255"
            maxlength="255"
            v-model="plan_version.notes"
            outlined
          >
          </v-textarea>

          <v-text-field
            :value="plan_version.start_date | formatJSDate"
            label="Data de Início"
            disabled
            outlined
          />
          <v-text-field
            :value="plan_version.end_date | formatJSDate"
            label="Data de Fim"
            disabled
            outlined
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Settings, DateTime } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("global.name_rules"),
        (v) =>
          (v && v.length <= 255) || this.$t("global.character_limit_rules"),
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    plan_version: {
      get() {
        return { ...this.item, notes: "" };
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
  },
  methods: {
    convertDateObjToStr(date) {
      return DateTime.fromJSDate(date).toFormat("dd/LL/yyyy");
    },
    undoCreation() {
      this.close();
      this.$emit("undoCreation");
    },
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        let plan_version = { ...this.plan_version };
        plan_version.start_date = this.convertDateObjToStr(
          plan_version.start_date
        );
        plan_version.end_date = this.convertDateObjToStr(plan_version.end_date);
        if (!this.plan_version.id) {
          this.$store.dispatch("plans/CREATE_PLAN", plan_version).then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.plan_version = {
                ...this.plan_version,
                id: res.plan_id,
                number: res.number,
              };
              this.close();
            }
          });
        } else {
          this.$store.dispatch("plans/UPDATE_PLAN", plan_version).then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.plan_version = {
                ...this.plan_version,
                id: res.plan_id,
                number: res.number,
              };
              this.close();
            }
          });
        }
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
