
<template>
  <div v-if="plan">
    <v-dialog v-model="dialogOpen" transition="dialog-bottom-transition" width="1000">
      <v-card tile>
        <v-toolbar dark flat color="blue darken-4">
          <v-toolbar-title><b>Versões</b> :: {{ plan.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed icon dark @click="dialogOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <Versions :item="plan" @remove_version="remove_version" @edit_version="edit_version"
            @xlsx_version="xlsx_version" :key="componentKey" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <Simulation :open.sync="openSimulationDialog" :item.sync="selectedPlan" :version.sync="selectedVersion" />
    <DeleteVersionDialog :open.sync="openDeleteVersionDialog" :item.sync="selectedPlan" :version.sync="selectedVersion" />
    <ExportPlanDialog :open.sync="openExportPlanDialog" :item.sync="selectedPlan" :version.sync="selectedVersion" />
  </div>
</template>

<script>
import { Settings } from "luxon";
import configs from "@/helpers/configs";
import Versions from "@/components/Plans/Versions";
import DeleteVersionDialog from "@/components/Plans/DeleteVersionDialog";
import ExportPlanDialog from "@/components/Plans/ExportPlanDialog";
import Simulation from "@/components/Simulator/Simulation";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

export default {
  props: ["item", "open"],
  components: {
    Versions,
    DeleteVersionDialog,
    ExportPlanDialog,
    Simulation
  },
  data() {
    return {
      componentKey: 0,
      isSimulatorEnabled: false,
      openSimulationDialog: false,
      openEditVersionDialog: false,
      openDeleteVersionDialog: false,
      openExportPlanDialog: false,
      selectedPlan: null,
      selectedVersion: null
    };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
    plan: {
      // getter
      get: function () {
        return this.item;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:item", newValue);
      },
    },
  },
  watch: {
    isSimulatorEnabled() {
      if (this.isSimulatorEnabled) {
        this.componentKey++;
      }
    },
    openSimulationDialog(val) {
      if (val == false) this.forceRerender();
    },
    openEditVersionDialog(val) {
      if (val == false) this.forceRerender();
    },
    openDeleteVersionDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  methods: {
    edit_version(version) {
      this.selectedPlan = this.plan;
      this.selectedVersion = version;
      this.openSimulationDialog = true;
    },
    remove_version(version) {
      this.selectedPlan = this.plan;
      this.selectedVersion = version;
      this.openDeleteVersionDialog = true;
    },
    xlsx_version(version) {
      this.selectedPlan = this.plan;
      this.selectedVersion = version;
      this.openExportPlanDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
