<template>
  <fragment>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="toggleMeasurement"
          small
          class="py-5"
          :color="isEnabled ? 'primary' : 'white'"
          :disabled="isDisabled"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon :color="isEnabled ? 'white' : 'primary'">
            mdi-ruler-square-compass
          </v-icon>
        </v-btn>
      </template>
      <span> {{ $t("global.angles") }} </span>
    </v-tooltip>

    <v-snackbar top v-model="showAngleMessage" width="60%" :timeout="-1">
      <v-icon left> mdi-ruler-square-compass </v-icon
      >{{ $t("angles.explanation") }}
      <template v-slot:action="{ attrs }">
        <v-btn small color="primary" v-bind="attrs" @click="clearMeasures">
          <v-icon small left>mdi-eraser</v-icon>{{ $t("angles.clear") }}
        </v-btn>
      </template>
    </v-snackbar>
  </fragment>
</template>

<script>
import {
  DistanceTool,
  VectorLayer,
  Marker,
  Label,
  Sector,
  Util,
} from "maptalks";

import { v4 as uuidv4 } from "uuid";
import * as turf from "@turf/turf";
/**
 * Angles Tool extension to support Vue.js angles measures
 *
 */
class AngleTool extends DistanceTool {
  constructor(options) {
    super(options);
    this._allMeasurements = [];
  }

  // Override @fuzhenn msOnDrawStart method
  _msOnDrawStart(param) {
    const map = this.getMap();
    const prjCoord = map._pointToPrj(param["point2d"]);
    const uid = uuidv4().replaceAll("-", "");
    const layerId = "angletool_" + uid;
    const markerLayerId = "angletool_markers_" + uid;
    const markerSectorId = "angletool_sector_" + uid;
    this._measureLayers = [];
    if (!map.getLayer(layerId)) {
      this._measureLineLayer = new VectorLayer(layerId).addTo(map);
      this._measureMarkerLayer = new VectorLayer(markerLayerId).addTo(map);
      this._measureSectorLayer = new VectorLayer(markerSectorId).addTo(map);
    } else {
      this._measureLineLayer = map.getLayer(layerId);
      this._measureMarkerLayer = map.getLayer(markerLayerId);
      this._measureSectorLayer = map.getLayer(markerSectorId);
    }
    this._measureLayers.push(this._measureLineLayer);
    this._measureLayers.push(this._measureMarkerLayer);
    this._measureLayers.push(this._measureSectorLayer);
    //start marker
    const marker = new Marker(param["coordinate"], {
      symbol: this.options["vertexSymbol"],
    });

    //调用_setPrjCoordinates主要是为了解决repeatworld下，让它能标注在其他世界的问题
    marker._setPrjCoordinates(prjCoord);
    let content = "";
    switch (this.options["language"]) {
      case "zh-CN":
        content = "起点";
        break;
      case "es-MX":
        content = "início";
        break;
      case "en-US":
      default:
        content = "start";
    }
    const startLabel = new Label(
      content,
      param["coordinate"],
      this.options["labelOptions"]
    );
    startLabel._setPrjCoordinates(prjCoord);
    this._lastVertex = startLabel;
    this._addVertexMarker(marker, startLabel);
    this._allMeasurements.push(this._measureLayers);
  }

  _msOnDrawVertex(param) {
    const prjCoords = this._geometry._getPrjCoordinates();
    const lastCoord = prjCoords[prjCoords.length - 1];
    const geometry = param["geometry"];
    //vertex marker
    const marker = new Marker(param["coordinate"], {
      symbol: this.options["vertexSymbol"],
    });

    const length = this._measure(geometry);
    const vertexLabel = new Label(
      length,
      param["coordinate"],
      this.options["labelOptions"]
    );
    this._addVertexMarker(marker, vertexLabel);
    vertexLabel._setPrjCoordinates(lastCoord);
    marker._setPrjCoordinates(lastCoord);
    this._lastVertex = vertexLabel;

    //add sector to represent angle between two lines

    if (
      prjCoords[prjCoords.length - 2] && //previous vertex
      prjCoords.length - 2 > 0 && //exclude start point
      !lastCoord.equals(prjCoords[prjCoords.length - 2]) //exclude end point
    ) {
      const projection = this.getMap().getProjection();

      //calc 3 points to calculate angle
      const midcoord = projection.unproject(prjCoords[prjCoords.length - 2]);
      const startcoord = projection.unproject(prjCoords[prjCoords.length - 3]);
      const endcoord = projection.unproject(prjCoords[prjCoords.length - 1]);

      var midpoint = turf.point(midcoord.toArray());
      var startpoint = turf.point(startcoord.toArray());
      var endpoint = turf.point(endcoord.toArray());

      //calculate azimuth vector1 (startpoint and midpoint)
      var bearing = turf.bearing(startpoint, midpoint);
      let startAngle = turf.bearingToAzimuth(bearing); //devia ser 360 - qq coisa, mas os eixos do maptalks estao rodados 90. logo 270 = 360 - 90

      //calculate azimuth vector2 (midpoint and endpoint)
      var bearing2 = turf.bearing(midpoint, endpoint);
      let endAngle = turf.bearingToAzimuth(bearing2); //devia ser 360 - qq coisa, mas os eixos do maptalks estao rodados 90. logo 270 = 360 - 90

      //calulate angle between 3 points
      var angle = this._calcAngle(startpoint, midpoint, endpoint);
      let startSector = 270 - startAngle;
      let endSector = startSector + angle;

      let diff = (startAngle - endAngle) % 360;
      if ((diff > 0 && diff < 180) || (diff > -360 && diff < -180)) {
        //invert
        startSector = startSector - angle;
        endSector = 270 - startAngle;
      }

      //get distance between start point and mid point
      var distanceVector1 = turf.distance(startpoint, midpoint) * 1000; //meters
      var distanceVector2 = turf.distance(endpoint, midpoint) * 1000; //meters

      //radius of sector is one third of min between vector1 and vector2
      let lengthSector = Math.min(distanceVector1, distanceVector2) / 3;

      //draw sector
      let symbol = this.options["sectorOptions"].symbol;
      var sector = new Sector(midcoord, lengthSector, startSector, endSector, {
        antiMeridian: "split",
        symbol,
      });

      //calculate position to put label of sector angle
      let units;

      switch (this.options["language"]) {
        case "es-MX":
          units = " °";
          break;
        case "en-US":
        default:
          units = " deg";
      }

      //calculates the point where the sector starts (vector1)
      let line1 = turf.lineString([midcoord.toArray(), startcoord.toArray()]);
      var startPointSector = turf.along(
        line1,
        distanceVector1 / 1000 / 3,
        { units: "kilometers" } //kms
      );

      //calculates the point where the sector starts (vector2)
      let line2 = turf.lineString([midcoord.toArray(), endcoord.toArray()]);
      var endPointSector = turf.along(
        line2,
        distanceVector1 / 1000 / 3,
        { units: "kilometers" } //kms
      );

      //calculates center between startPointSector, midPoint, and endPointSector
      let labelCoords = turf.center(
        turf.points([
          startPointSector.geometry.coordinates,
          midcoord.toArray(),
          endPointSector.geometry.coordinates,
        ])
      ).geometry.coordinates;

      //draw label angle
      let label = new Label(angle.toFixed(0) + units, labelCoords, {
        textSymbol: {
          textFaceName: "monospace",
          textFill: "#34495e",
          textHaloFill: "#fff",
          textHaloRadius: 4,
          textSize: 10,
          textWeight: "bold",
        },
      });

      sector.addTo(this._measureSectorLayer);
      label.addTo(this._measureSectorLayer);
    }
  }

  _addClearMarker(coordinates, prjCoord, dx) {
    let symbol = this.options["clearButtonSymbol"];
    let dxSymbol = {
      markerDx: (symbol["markerDx"] || 0) + dx,
      textDx: (symbol["textDx"] || 0) + dx,
    };
    if (Array.isArray(symbol)) {
      dxSymbol = symbol.map((s) => {
        if (s) {
          return {
            markerDx: (s["markerDx"] || 0) + dx,
            textDx: (s["textDx"] || 0) + dx,
          };
        }
        return null;
      });
    }
    symbol = Util.extendSymbol(symbol, dxSymbol);
    const endMarker = new Marker(coordinates, {
      symbol: symbol,
    });
    const measureLineLayer = this._measureLineLayer,
      measureMarkerLayer = this._measureMarkerLayer,
      measureSectorLayer = this._measureSectorLayer;
    endMarker.on(
      "click",
      function () {
        measureLineLayer.remove();
        measureMarkerLayer.remove();
        measureSectorLayer.remove();
        //return false to stop propagation of event.
        return false;
      },
      this
    );
    endMarker.addTo(this._measureMarkerLayer);
    endMarker._setPrjCoordinates(prjCoord);
  }

  _measure(toMeasure) {
    let units;

    switch (this.options["language"]) {
      case "es-MX":
        units = " °";
        break;
      case "en-US":
      default:
        units = " deg";
    }

    let measures = Array.isArray(toMeasure)
      ? toMeasure.filter(
          (measure, index, self) =>
            index ===
            self.findIndex((t) => t.x === measure.x && t.y === measure.y)
        )
      : [];

    this._lastMeasure = this._calcAzimuth(measures);
    return "AZM: " + this._lastMeasure + units;
  }

  _calcAzimuth(measures) {
    if (measures.length >= 2) {
      let end = measures[measures.length - 1];
      let start = measures[measures.length - 2];
      var startpoint = turf.point([start.x, start.y]);
      var endpoint = turf.point([end.x, end.y]);
      var bearing = turf.bearing(startpoint, endpoint);
      let deg = turf.bearingToAzimuth(bearing);

      return deg.toFixed(0);
    }

    return this._lastMeasure;
  }

  _calcAngle(coord1, coords2, coord3) {
    let angle = turf.angle(coord1, coords2, coord3);
    return angle > 180 ? 360 - angle : angle;
  }

  clear() {
    if (this._allMeasurements) {
      this._allMeasurements.forEach((mgroup) => {
        if (mgroup) {
          mgroup.forEach((measurementLayer) => {
            if (measurementLayer) {
              measurementLayer.clear();
              measurementLayer.remove();
            }
          });
        }
      });
      this.endDraw();
    }
    delete this._lastMeasure;
    delete this._lastVertex;
    this._measureLayers = [];
    this._measureSectorLayer = null;
    this._measureLineLayer = null;
    this._measureMarkerLayer = null;
    this._allMeasurements = [];
    return this;
  }
}

export default {
  props: ["map", "isAngleEnabled", "disabled"],
  data() {
    return {
      showAngleMessage: false,
      areaTool: null,
    };
  },
  watch: {
    map(val) {
      if (val) {
        this.register();
      } else {
        this.unregister();
      }
    },
    isEnabled(val) {
      if (val) {
        this.showAngleMessage = true;
        this.areaTool.enable();
      } else {
        this.showAngleMessage = false;
        this.areaTool.disable();
      }
    },
  },
  computed: {
    isEnabled: {
      get() {
        return this.isAngleEnabled;
      },
      set(val) {
        this.$emit("update:isAngleEnabled", val);
      },
    },
    isDisabled: {
      get() {
        return this.disabled;
      },
      set(val) {
        this.$emit("update:disabled", val);
      },
    },
  },
  mounted() {
    this.register();
  },
  beforeDestroy() {
    this.unregister();
  },
  methods: {
    unregister() {
      if (this.areaTool) {
        this.areaTool.remove();
      }
    },
    register() {
      this.unregister();
      if (this.map) {
        this.areaTool = new AngleTool({
          symbol: {
            lineColor: "#34495e",
            lineWidth: 2,
          },
          vertexSymbol: {
            markerType: "ellipse",
            markerFill: "#34495e",
            markerLineColor: "#000",
            markerLineWidth: 1,
            markerWidth: 5,
            markerHeight: 5,
          },
          labelOptions: {
            textSymbol: {
              textFaceName: "monospace",
              textFill: "#fff",
              textLineSpacing: 1,
              textHorizontalAlignment: "right",
              textDx: 15,
              markerLineColor: "#34495e",
              markerFill: "#000",
            },
            boxStyle: {
              padding: [6, 2],
              symbol: {
                markerType: "square",
                markerFill: "#34495e",
                markerFillOpacity: 0.9,
                markerLineColor: "#34495e",
              },
            },
          },
          sectorOptions: {
            symbol: {
              lineColor: "#34495e",
              lineWidth: 2,
              polygonFill: "#34495e",
              polygonOpacity: 0.4,
            },
          },
          clearButtonSymbol: [
            {
              markerType: "square",
              markerFill: "#34495e",
              markerLineColor: "#34495e",
              markerLineWidth: 2,
              markerWidth: 15,
              markerHeight: 15,
              markerDx: 20,
            },
            {
              markerType: "x",
              markerWidth: 10,
              markerHeight: 10,
              markerLineColor: "#fff",
              markerDx: 20,
            },
          ],
          language: "es-MX",
        }).addTo(this.map);
        this.areaTool.on("drawstart", function (param) {
          setTimeout(() => {
            param.drawTool._drawToolLayer.setZIndex(1000);
            if (param.drawTool._measureMarkerLayer)
              param.drawTool._measureMarkerLayer.setZIndex(1000);
          }, 100);
        });
        var map = this.map;
        this.areaTool.on("drawend", function () {
          map.getLayers().forEach((l) => {
            if (l._id.includes("angletool_")) l.setZIndex(1000);
          });
        });
        this.areaTool.disable();
      }
    },
    toggleMeasurement() {
      this.isEnabled = !this.isEnabled;
    },
    clearMeasures() {
      this.map.getLayers().forEach((l) => {
        if (l._id.includes("angletool_")) l.clear();
      });
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
