<template>
  <div>
    <v-dialog v-model="dialogOpen" v-if="item" persistent max-width="500">
      <v-card tile :loading="loading">
        <v-card-title>{{ $t("plan.delete") }}</v-card-title>
        <v-card-text
          v-html="
            $t('plan.delete_confirmation', {
              name: item.name,
            })
          "
        >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="darken-1" text @click="cancelDelete">{{
            $t("global.no")
          }}</v-btn>
          <v-btn depressed color="red darken-1" text @click="confirmDelete">{{
            $t("global.yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  props: ["item", "open"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  methods: {
    cancelDelete() {
      this.dialogOpen = false;
    },
    confirmDelete() {
      this.loading = true;
      this.$store
        .dispatch("plans/DELETE_PLAN", this.item.id)
        .then(() => {
          this.loading = false;
          this.dialogOpen = false;
        })
        .catch(() => {
          this.loading = false;
          this.dialogOpen = false;
        });
    },
  },
};
</script>
