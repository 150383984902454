<template>
  <v-card tile>
    <v-toolbar dark color="primary" flat>
      <v-toolbar-title>
        <span>
          <v-icon left>mdi-ferry</v-icon>
          {{ isEdit ? $t("vessel_call.edit_vessel_call") : $t("vessel_call.create_vessel_call") }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn depressed icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0">
      <v-form ref="form" class="pt-10">
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete v-model="shipSelected" :items.sync="ships" :loading="isLoading" :search-input.sync="search" filled item-text="name" return-object label="Pesquisar um Navio" required clearable hide-selected hide-details>
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title class="h6">
                      <b>{{ item.name }}</b>
                    </v-list-item-title>

                    <v-list-item-subtitle class="caption">
                      <b>IMO:</b> {{ item.imo }} <br />
                      <b>MMSI:</b> {{ item.mmsi }} <br />
                      <b>LOA:</b>
                      {{ item.loa ? item.loa + " " + $t("global.meters") : "N/D" }}<br />
                      <b>BEAM:</b>
                      {{ item.breadth_moulded ? item.breadth_moulded + " " + $t("global.meters") : "N/D" }}<br />
                      <b>{{ $t("vessel_call.maximum_draught").toUpperCase() }}:</b>
                      {{ item.maximum_draught ? item.maximum_draught + " " + $t("global.meters") : "N/D" }}<br />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <country-flag v-if="!!item.ship_flag_code" :country="item.ship_flag_code" rounded />
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <VesselCallBerthingPlanner :item.sync="item" :port_locode="locode" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.process_number" required :label="$t('vessel_call.process_number')" outlined disabled dense />

            <v-text-field v-model="item.name" required :label="$t('vessel_call.ship_name')" outlined dense />

            <v-text-field v-model="item.imo" required label="IMO" :rules="imoRules" outlined dense />

            <v-text-field v-model="item.loa" required :label="$t('vessel_call.loa')" outlined type="number" :rules="loaRules" onkeydown="return event.keyCode !== 69" dense min="0" />

            <v-text-field v-model="item.beam" required :label="$t('vessel_call.beam')" outlined type="number" :rules="beamRules" onkeydown="return event.keyCode !== 69" dense min="0" />

            <v-checkbox v-model="item.has_dangerous_good" :label="$t('vessel_call.has_dangerous_good')" class="mt-0"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="8">
            <v-row>
              <v-col cols="6" sm="6">
                <v-menu v-model="etaPickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="etaText" readonly :label="$t('vessel_call.eta')" outlined hide-details color="deep-purple accent-4" dense v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <vc-date-picker v-model="etaDate" mode="dateTime" is24hr is-expanded :model-config="modelConfig" :available-dates="availableDatesEta"></vc-date-picker>
                </v-menu> </v-col
              ><v-col cols="6" sm="6">
                <v-menu v-model="etdPickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="etdText" readonly :label="$t('vessel_call.etd')" outlined hide-details color="deep-purple accent-4" dense v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <vc-date-picker v-model="etdDate" mode="dateTime" is24hr is-expanded :model-config="modelConfig" :available-dates="availableDatesEtd"></vc-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6">
                <v-autocomplete v-model="item.berth_location" style="padding-top: 5px" :items="zones" :label="$t('vessel_call.berth_location')" :filter="nameAndCodeFilter" item-text="name" item-value="code" outlined clearable dense>
                  <template v-slot:selection="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:item="data">
                    <v-chip small outlined label class="mr-1">{{ data.item.code }}</v-chip>
                    {{ data.item.name }}
                  </template>
                </v-autocomplete>

                <v-autocomplete v-model="item.end_mooring_bow" :items="mooringBitts" :label="$t('vessel_call.end_mooring_bow')" item-text="name" item-value="code" :filter="nameAndCodeFilter" outlined clearable dense @change="updateCoords">
                  <template v-slot:selection="data">
                    {{ data.item.code }}
                  </template>
                  <template v-slot:item="data">
                    <v-row>
                      <v-col cols="6">
                        <v-chip small dark class="mr-1">{{ data.item.code }}</v-chip>
                        {{ data.item.name }}
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-chip small outlined label class="mr-1">{{ data.item.mooring_bitt_groups.map((group) => group.code).join(", ") }}</v-chip>
                      </v-col>
                    </v-row>
                  </template>
                </v-autocomplete>

                <v-text-field v-model="item.lat" required :label="$t('vessel_call.lat')" outlined type="number" onkeydown="return event.keyCode !== 69" dense :disabled="disabledInfo" />

                <v-text-field v-model="item.entry_bow_draught" required :label="$t('vessel_call.entry_bow_draught')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="entryBowDraughtRules" dense min="0" />

                <v-text-field v-model="item.exit_bow_draught" required :label="$t('vessel_call.exit_bow_draught')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="exitBowDraughtRules" dense min="0" />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="item._heading_adjustment" required :label="$t('vessel_call.hdg')" outlined type="number" :rules="hdgRules" onkeydown="return event.keyCode !== 69" dense style="padding-top: 5px" :disabled="disabledInfo" min="0" />

                <v-autocomplete v-model="item.end_mooring_stern" :items="mooringBitts" :label="$t('vessel_call.end_mooring_stern')" item-text="name" item-value="code" :filter="nameAndCodeFilter" outlined clearable dense @change="updateCoords">
                  <template v-slot:selection="data">
                    {{ data.item.code }}
                  </template>
                  <template v-slot:item="data">
                    <v-row>
                      <v-col cols="6">
                        <v-chip small dark class="mr-1">{{ data.item.code }}</v-chip>
                        {{ data.item.name }}
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-chip small outlined label class="mr-1">{{ data.item.mooring_bitt_groups.map((group) => group.code).join(", ") }}</v-chip>
                      </v-col>
                    </v-row>
                  </template>
                </v-autocomplete>

                <v-text-field v-model="item.lon" required :label="$t('vessel_call.lon')" outlined type="number" onkeydown="return event.keyCode !== 69" dense :disabled="disabledInfo" />

                <v-text-field v-model="item.entry_stern_draught" required :label="$t('vessel_call.entry_stern_draught')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="entrySternDraughtRules" dense min="0" />

                <v-text-field v-model="item.exit_stern_draught" required :label="$t('vessel_call.exit_stern_draught')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="exitSternDraughtRules" dense min="0" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <v-textarea rows="4" row-height="8" v-model="item.observations" :label="$t('vessel_call.observations')" outlined dense> </v-textarea>
          </v-col>
          <v-col cols="12" sm="8">
            <v-row>
              <v-col cols="6" sm="6">
                <v-text-field v-model="item.buffer_stern" required :label="$t('vessel_call.buffer_stern')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="bufferSternRules" dense min="0" />

                <v-text-field v-model="item.buffer_bow" required :label="$t('vessel_call.buffer_bow')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="bufferBowRules" dense min="0" />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="item.buffer_starboard" required :label="$t('vessel_call.buffer_starboard')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="bufferStarboardRules" dense min="0" />

                <v-text-field v-model="item.buffer_portside" required :label="$t('vessel_call.buffer_portside')" outlined type="number" onkeydown="return event.keyCode !== 69" :rules="bufferPortsideRules" dense min="0" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn depressed color="primary" text @click="save">
        {{ $t("global.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import _ from "lodash";
  import configs from "@/helpers/configs";
  import VesselCallBerthingPlanner from "@/components/VesselCalls/VesselCallBerthingPlanner";
  const SHIP_ADITIONAL_HEADING_ADJUSTMENT = -90;

  export default {
    props: ["item", "isEdit", "disabledInfo", "stayRange", "availableRange"],
    data() {
      return {
        isLoading: false,
        etaPickerMenu: false,
        etdPickerMenu: false,
        modelConfig: {
          type: "string",
          mask: "iso",
        },
        nameRules: [(v) => !!v || this.$t("global.name_rules")],
        imoRules: [
          (v) => {
            if (!v) return this.$t("global.imo_rules");

            // Check if the string contains only alphanumeric characters
            let regex = /^[a-zA-Z0-9]+$/;

            if (!regex.test(v)) {
              return this.$t("global.invalid_value_rule");
            }

            return true;
          },
        ],
        loaRules: [(v) => !!v || this.$t("vessel_call.loa_rule"), (v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        beamRules: [(v) => !!v || this.$t("vessel_call.beam_rule"), (v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        hdgRules: [(v) => (v ? v >= 0 || this.$t("vessel_call.hdg_min_rule") : true), (v) => (v ? v <= 360 || this.$t("vessel_call.hdg_max_rule") : true)],
        entryBowDraughtRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        exitBowDraughtRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        entrySternDraughtRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        exitSternDraughtRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        bufferSternRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        bufferBowRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        bufferStarboardRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        bufferPortsideRules: [(v) => (v ? v >= 0 || this.$t("global.negative_rule") : true)],
        shipSelected: null,
        search: null,
      };
    },
    components: {
      VesselCallBerthingPlanner,
    },
    computed: {
      ships() {
        return this.$store.state.ships.list;
      },
      zones() {
        return this.$store.state.zones.zonesList;
      },
      locode() {
        return configs.getLocode();
      },
      mooringBitts() {
        return this.$store.state.moorings.mooringsList;
      },
      etaText: {
        get() {
          if (this.item.stay) {
            return this.$options.filters.formatDatetime(this.item.stay.start);
          }

          return "";
        },
      },
      etdText: {
        get() {
          if (this.item.stay) {
            return this.$options.filters.formatDatetime(this.item.stay.end);
          }

          return "";
        },
      },
      etdDate: {
        get() {
          if (this.item.stay) {
            return new Date(this.item.stay.end);
          }

          return null;
        },
        set(val) {
          this.item.stay.end = val;
        },
      },
      etaDate: {
        get() {
          if (this.item.stay) {
            return new Date(this.item.stay.start);
          }

          return null;
        },
        set(val) {
          this.item.stay.start = val;
        },
      },
      availableDatesEta() {
        return {
          start: this.availableRange[0].start,
          end: this.availableRange[0].end,
        };
      },

      availableDatesEtd() {
        return {
          start: Math.max(this.availableRange[1].start, this.etaDate),
          end: null
        };
      },
    },
    mounted() {
      this.item.stay = {
        start: new Date(this.stayRange.start).toISOString(),
        end: new Date(this.stayRange.end).toISOString(),
      };

      this.item._heading_adjustment = this.item.hdg ? this.reduceAngle(parseFloat(this.item.hdg) - SHIP_ADITIONAL_HEADING_ADJUSTMENT) : null;
    },
    watch: {
      search(val) {
        this.searchShips(val);
      },
      shipSelected(val) {
        this.$emit("shipSelected", val);
      },
    },
    methods: {
      searchShips: _.debounce(function () {
        this.isLoading = true;
        this.$store.dispatch("ships/GET_COMPLETE_LIST_OF_SHIPS", this.search).finally(() => (this.isLoading = false));
      }, 300),
      reduceAngle(hdg) {
        //Reducing Angle Measures
        if (hdg < 0) {
          hdg = this.reduceAngle(360 + hdg);
        } else if (hdg >= 360) {
          let division = Math.trunc(hdg / 360);
          let cycles = 360 * division;
          hdg = hdg - cycles;
        }

        return hdg;
      },
      updateCoords() {
        this.$emit("updateCoords");
      },
      nameAndCodeFilter(item, queryText) {
        return (item.code && item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) || (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())) > -1;
      },
      close() {
        this.$emit("close");
      },
      save() {
        if (this.$refs.form.validate()) {
          this.item.hdg = this.item._heading_adjustment ? this.reduceAngle(parseFloat(this.item._heading_adjustment) + SHIP_ADITIONAL_HEADING_ADJUSTMENT) : null;
          this.$emit("save");
        }
      },
    },
  };
</script>
